<div class="error-container">
    <p-dialog [(visible)]="popupSeverity.error" [style]="{ width: '30vw' }" modal="true" [closeOnEscape]="false" [focusTrap]="true">
    <div class="theme-container">
        <img src="assets/error-round-icon.svg" alt="">
        <p class="title-row">{{title}}</p>
        <p class="message-row">{{message}}</p>
        <button (click)="closeDialog('error', 'cancel')">{{ctaLabel}}</button>
    </div>
    </p-dialog>
</div>


<div class="warning-container">
    <p-dialog [(visible)]="popupSeverity.warning" [style]="{ width: '30vw' }" modal="true" [closeOnEscape]="false" [focusTrap]="true">
    <div class="theme-container">
        <img src="assets/warning-round-icon.svg" alt="">
        <p class="title-row">{{title}}</p>
        <p class="message-row">{{message}}</p>
        <button (click)="closeDialog('warning', 'cancel')">{{ctaLabel}}</button>
    </div>
    </p-dialog>
</div>


<div class="info-container">
    <p-dialog [(visible)]="popupSeverity.info" [style]="{ width: '30vw' }" modal="true" [closeOnEscape]="false" [focusTrap]="true">
    <div class="theme-container">
        <img src="assets/info-round-icon.svg" alt="">
        <p class="title-row">{{title}}</p>
        <p class="message-row">{{message}}</p>
        <div class="info-cta-container">
            <button (click)="closeDialog('info', 'proceed')">{{ctaLabel}}</button>
            <button (click)="closeDialog('info', 'cancel')" *ngIf="cancelCta" class="outlined-button">{{cancelCtaLabel}}</button>
        </div>
        
    </div>
    </p-dialog>
</div>


<div class="success-container">
    <p-dialog [(visible)]="popupSeverity.success" [style]="{ width: '30vw' }" modal="true" [closeOnEscape]="false" [focusTrap]="true">
    <div class="theme-container">
        <img src="assets/info-round-icon.svg" alt="">
        <p class="title-row">{{title}}</p>
        <p class="message-row">{{message}}</p>
        <button (click)="closeDialog('success', 'proceed')">{{ctaLabel}}</button>
    </div>
    </p-dialog>
</div>


<div class="custom-container">
    <p-dialog [(visible)]="popupSeverity.custom" [style]="{ width: '40vw' }" modal="true" closable="true" [closeOnEscape]="false" [focusTrap]="true">
        <div class="theme-container">
            <div class="close-container">
                <img src='assets/clear-input-icon.svg' (click)="closeDialog('custom', 'cancel')">
            </div>
            
            <div #customTemplate></div>

            <div class="cta-container">
                <button (click)="closeDialog('custom', 'proceed')" class="active-button">{{ctaLabel}}</button>
                <button (click)="closeDialog('custom', 'cancel')" *ngIf="cancelCta" class="outlined-button">{{cancelCtaLabel}}</button>
            </div>
        
        </div>
    </p-dialog>
</div>