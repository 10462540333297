import { environment } from "../../../environments/environment";

export const BASE_URL: string = environment.baseUrl;
export const COOKIE_URL: string = environment.cookieURL;
export const COOKIE_SUFFIX: string = environment.cookieSuffix;
export const API_UPLOAD_KML_FILE: string = `${BASE_URL}/api/v1/region/upload_kml`;

export const API_URL_LIST_DRIVERS: string = `${BASE_URL}/api/v1/driver/all/drivers`;
export const API_URL_GET_DRIVER_LOCATION: string = `${BASE_URL}/api/v1/driver`;
export const API_URL_GET_ALL_CONFIGURE_RULES: string = `${BASE_URL}/api/v1/rules/fetch_all`;
export const API_URL_GET_RULES_ID_WISE: string = `${BASE_URL}/api/v1/rules/fetch_one?`;
export const API_URL_INSERT_CONFIGURATION_RULE: string = `${BASE_URL}/api/v1/rules/insert`;
export const API_URL_UPDATE_CONFIGURATION_RULE: string = `${BASE_URL}/api/v1/rules/update`;

export const API_URL_ADD_DRIVER: string = `${BASE_URL}/api/v1/driver/save`;
export const API_URL_EDIT_DRIVER: string = `${BASE_URL}/api/v1/driver/edit`;
export const API_URL_GET_PUNCH_ID: string = `${BASE_URL}/api/v1/driver/get/data`;

export const API_URL_RESET_PASSWORD_DRIVER: string = `${BASE_URL}/api/v1/driver/reset-password`;

export const API_URL_LIST_VEHICLE: string = `${BASE_URL}/api/v1/vehicle/all/vehicles`;
export const API_URL_LIVE_MAP: string = `${BASE_URL}/api/v1/driver/live/map`;
// export const API_URL_LIVE_MAP: string = `https://run.mocky.io/v3/9af1166e-e992-42c3-befd-18a1294cb8e7`;

export const API_URL_ADD_VEHICLE: string = `${BASE_URL}/api/v1/vehicle/save`;
export const API_URL_EDIT_VEHICLE: string = `${BASE_URL}/api/v1/vehicle/edit`;

export const API_URL_GET_VEHICLE_REGISTRATION = `${BASE_URL}/api/v1/vehicle/get/data?`;
export const API_URL_PASTRIDE_CSV_DOWNLOAD = `${BASE_URL}/api/v1/ride/past/rides/csv`;
export const API_URL_REPORT_RIDER_FEEDBACK_CSV_DOWNLOAD = `${BASE_URL}/api/v1/report/rider/rating/feedback/csv`;

export const API_URL_LOGIN: string = `${BASE_URL}/api/v1/auth/login`;
export const API_URL_LOGIN_V2: string = `${BASE_URL}/api/v2/auth/login`;
export const API_URL_LOGOUT: string = `${BASE_URL}/api/v1/auth/logout`;
export const API_URL_CHECK_TOKEN: string = `${BASE_URL}/api/v1/auth/check/token`;

export const API_URL_LIST_LIVE_RIDE = `${BASE_URL}/api/v2/ride/all/rides/live`;
//export const API_URL_LIST_LIVE_RIDE = `https://run.mocky.io/v3/7147edc2-6ae8-4afe-a1b3-a09672322b2f`;
export const API_URL_LIST_SCHEDULED_RIDE = `${BASE_URL}/api/v2/ride/all/rides/scheduled`;
export const API_URL_COMMISSION_PAST_RIDES = `${BASE_URL}/api/v1/ride/rides/stats`

export const API_URL_ACTION_LIVE_RIDE = `${BASE_URL}/api/v1/ride`;

export const API_URL_PICKUP_NOTES_HISTORY = `${BASE_URL}/api/v1/ride/pickup/notes`;
export const API_URL_PAST_RIDE_LIST = `${BASE_URL}/api/v1/ride/all/rides`;
export const API_URL_PAST_RIDE_LIST_CSV = `${BASE_URL}/api/v1/ride/all/rides/CSV/pastride`;
export const API_URL_PAST_RIDE_RECEIPT_ZIP = `${BASE_URL}/api/v1/ride/receipt/download/zip`;

export const API_URL_STATS_LIVE = `${BASE_URL}/api/v1/stats/live`;
export const API_URL_RIDE_DETAILS = `${BASE_URL}/api/v1/ride/details`;
export const API_URL_GET_RIDER_DELAY_ALERT: string = `${BASE_URL}/api/v1/rider/delay`;
export const API_URL_RIDE_DETAILS_FOR_OTP = `${BASE_URL}/api/v1/:otp/details`;
export const API_URL_CONFIRM_RIDE_FOR_OTP = `${BASE_URL}/api/v1/:otp/confirm`;
export const API_URL_PROVISIONAL_AMT = `${BASE_URL}/api/v1/provisional/amount`;

export const API_URL_DRIVER_ON_DUTY = `${BASE_URL}/api/v1/driver/on/duty`;

// export const API_URL_DRIVER_ON_DUTY = `https://run.mocky.io/v3/20f7a4b2-bc94-4e13-aaa3-ab6df10bce63`;
export const API_URL_DRIVER_OFFLINE = `${BASE_URL}/api/v1`;
export const API_URL_CREATE_LIVE_RIDE = `${BASE_URL}/api/v1/ride/request`;
export const API_URL_DUPLICATE_RIDE = `${BASE_URL}/api/v1/ride/duplicate`;
export const API_URL_CREATE_SHARED_LIVE_RIDE = `${BASE_URL}/api/v1/shared/ride/request`;
export const API_URL_CONFIRM_SHARED_LIVE_RIDE = `${BASE_URL}/api/v1/shared/ride/confirm`;
export const API_URL_FETCH_ALL_SHARED_RIDES= `${BASE_URL}/api/v2/shared/ride/fetch/all`;
export const API_URL_UPDATE_SINGLE_SHARED_RIDE= `${BASE_URL}/api/v1/shared/ride/update`;
export const API_URL_UPDATE_BULK_SHARED_RIDE= `${BASE_URL}/api/v1/shared/ride/bulk/update`;
export const API_URL_SHARED_PRICING = `${BASE_URL}/api/v1/shared/ride/pricing?id=`;
export const API_BULK_SHARED_UPDATE_ESTIMATE = `${BASE_URL}/api/v1/shared/ride/bulk/config/update/estimate?id=`;
export const API_CONFIRM_BULK_SHARED_UPDATE_RIDE = `${BASE_URL}/api/v1/shared/ride/bulk/config/update/confirm?id=`;
export const API_BULK_SINGLE_UPDATE_ESTIMATE = `${BASE_URL}/api/v1/shared/ride/update/estimate?id=`;
export const API_CONFIRM_SINGLE_SHARED_UPDATE_RIDE = `${BASE_URL}/api/v1/shared/ride/update/estimate/confirm?id=`;
export const API_URL_FETCH_ALL_STOPS = `${BASE_URL}/api/v1/shared/ride/fetch/stops?id=`;

export const API_URL_BLOCK_RIDER = `${BASE_URL}/api/v1/rider/block/user/status`;
export const API_URL_SEND_OTP_RIDER = `${BASE_URL}/api/v1/rider/generate/otp`;
export const API_URL_LIST_RIDER = `${BASE_URL}/api/v1/rider/all/riders`;
export const API_URL_RIDER_DEVICE_INFORMATION = `${BASE_URL}/api/v1/rider/device/information`;
export const API_URL_DRIVER_STATES_AUDIT = `${BASE_URL}/api/v1/driver/states/audit`;
export const API_URL_RIDER_FEEDBACK = `${BASE_URL}/api/v1/report/rider/rating/feedback`;
export const API_URL_DRIVER_TEMPERATURE = `${BASE_URL}/api/v1/driver/temp`;
export const API_URL_VEHICLE_SANITIZATION_TIME = `${BASE_URL}/api/v1/vehicle/vehicle/sanitization/time`;
export const API_URL_ADD_RIDER_PROMO = `${BASE_URL}/api/v1/promo/apply`;
export const API_URL_RIDER_DETAILS = `${BASE_URL}/api/v1/rider/details`;
export const API_URL_ALL_PACKAGES = `${BASE_URL}/api/v1/ride/packages/find/all`;

export const API_URL_GET_ROLES_FOR_USERS = `${BASE_URL}/api/v1/user/`;
export const API_URL_GET_ALL_USER_FOR_BUSINESS_ACCOUNT =`${BASE_URL}/api/v1/client/get/users/`
export const API_URL_UPLOAD_USER_CSV = `${BASE_URL}/api/v1/user/upload-csv-file`
export const API_URL_GET_GST_DETAILS= `${BASE_URL}/api/v1/ride/gst/registrations/get`
export const API_URL_ACTION_ON_GST_DETAILS= `${BASE_URL}/api/v1/gst/registrations/action`
export const API_URL_UPDATE_GST_DETAILS= `${BASE_URL}/api/v1/ride/gst/registrations/save`
export const API_URL_STATES_CONFIG = `${BASE_URL}/api/v1/ride/states/get`
export const API_URL_UPLOAD_IMAGE =`${BASE_URL}/api/v1/client/upload`



//Lease Configuration API's

export const API_URL_CREATE_LEASE_CONFIG = `${BASE_URL}/api/v1/generate/lease/config`;
export const API_URL_UPDATE_LEASE_CONFIG = `${BASE_URL}/api/v1/update/lease/config`;
export const API_URL_DELETE_LEASE_CONFIG = `${BASE_URL}/api/v1/delete/lease/scheduler?`;
export const API_URL_GET_ALL_LEASE_CONFIGURATION = `${BASE_URL}/api/v1/lease/config`;

//Promo Creation Screen
//export const API_URL_CREATE_PROMO_CONFIG = `${BASE_URL}/api/v1/promo/create`
export const API_URL_GET_PROMO_CONFIG = `${BASE_URL}/api/v1/promo/get/all`;
export const API_URL_ACTIVATE_PROMO_CONFIG = `${BASE_URL}/api/v1/promo/activate`;
export const API_URL_UPDATE_PROMO_CONFIG = `${BASE_URL}/api/v1/promo/update/configuration`;
export const API_URL_CREATE_PROMO_CODE = `${BASE_URL}/api/v1/promo/create`;

// Payments API
export const API_URL_ALL_PAYMENTS_TRANSACTIONS = `${BASE_URL}/api/v1/payments/transactions/other`;
export const API_URL_ALL_RIDER_BALANCE = `${BASE_URL}/api/v1/all/rider/payment`;
export const API_URL_PAYMENT_ORDER = `${BASE_URL}/api/v1/payment/order`;
export const API_URL_BLUWALLET_PANEL = `${BASE_URL}/api/v1/rider/bluwallet/paytm/balance`;
export const API_URL_ALL_BLUWALLET_TRANSACTIONS = `${BASE_URL}/api/v1/payments/transactions/wallet`;
export const API_URL_CREDIT_HISTORY = `${BASE_URL}/api/v1/payments/credit/history`;
export const API_URL_ADD_CREDIT = `${BASE_URL}/api/v1/payments/wallet/credit/transaction`;
export const API_URL_CURRENT_BALANCE = `${BASE_URL}/api/v1/payments/current/balance/`;
export const API_URL_PREPAID_TRANSACTIONS = `${BASE_URL}/api/v1/payments/transaction/history`;
export const API_URL_PERFORM_CREDIT_SUCCESS_ACTION = `${BASE_URL}/api/v1/payments/transactions/`

//receipt
export const API_URL_DOWNLOAD_RECEIPT = `${BASE_URL}/api/v1/ride/receipt/download`;

//Slots
export const API_URL_GET_ALL_SLOTS = `${BASE_URL}/api/v1/slots/get`;
export const API_URL_GENERATE_SLOTS = `${BASE_URL}/api/v1/slots/save`;
export const API_URL_FETCH_SLOTS = `${BASE_URL}/api/v1/slots/new`;

//slot's stats
export const API_URL_SLOTS_STATS = `${BASE_URL}/api/v1/stats/slots`;
export const API_RIDER_BLU_WALLET_REFUND = `${BASE_URL}/api/v1/rider/blu/wallet/refund`;

export const API_JOURNEY_VEHCILE = `${BASE_URL}/api/v1/rider/blu/wallet/refund`;
export const API_JOURNEY_DRIVER = `${BASE_URL}/api/v1/rider/blu/wallet/refund`;
export const API_DRIVER_JOURNEY = `${BASE_URL}/api/v1/driver/get/journey`;
export const API_VEHICLE_JOURNEY = `${BASE_URL}/api/v1/vehicle/journey/details`;

//customer Dashboard
export const API_RIDE_DETAILS = `${BASE_URL}/api/v1/ride/panel`;
export const API_RIDER_DETAILS = `${BASE_URL}/api/v1/rider/retention`;
export const API_UPDATE_AGENT_DATA = `${BASE_URL}/api/v1/rider/communication/agent/add`;

// overpeeding
export const API_OVERSPEEDING_DRIVER = `${BASE_URL}/api/v1/driver/overspeeding/stats`;
//overSpeeding report
export const API_OVERSPEEDING_REPORT = `${BASE_URL}/api/v1/driver/overspeeding/report`;
export const API_OVERSPEEDING_REPORT_PANEL = `${BASE_URL}/api/v1/driver/overspeeding/report/panel/`;

//add money to wallet
export const API_URL_ADD_MONEY_TO_WALLET = `${BASE_URL}/api/v1/payments/wallet/add`;
export const API_URL_REDIRECT_TO_RAZORPAY = `${BASE_URL}/api/v1/payments/initiate/razorpay/`;
export const API_URL_RAZORPAY_PAYMENT_STATUS = `${BASE_URL}/api/v1/payments/order/`
export const API_URL_RIDE_ACTION = `${BASE_URL}/api/v1/ride/`;
export const API_URL_CHECK_ADD_MONEY_STATUS = `${BASE_URL}/api/v1/payments/transactions`;
export const API_URL_INITITATE_PAYMENT = `${BASE_URL}/api/v1/payments/initiate`;
//teller Wallet integration
export const API_URL_ADD_MONEY_TO_WALLET_TELLER = `${BASE_URL}/api/v1/payments/initiate`;

//Users Screen API
export const API_URL_ADD_USER = `${BASE_URL}/api/v1/user/add/subuser`;
export const API_URL_UPDATE_USER = `${BASE_URL}/api/v1/user/update/subuser`;
export const API_URL_DELETE_USERS = `${BASE_URL}/api/v1/user/delete/sub-users`;
export const API_URL_FETCH_USERS = `${BASE_URL}/api/v1/user/fetch/subuser`;
export const API_URL_CREATE_USER_CONFIG = `${BASE_URL}/api/v1/admin/create/`;
export const API_URL_UPDATE_USER_CONFIG = `${BASE_URL}/api/v1/client/update/`;
export const API_URL_GET_ALL_ROLE = `${BASE_URL}/api/v1/client/get/user/roles`;

//Update Business Ride Reason
export const API_URL_UPDATE_BUSINESS_RIDE_REASON = `${BASE_URL}/api/v1/ride/update/business/reason`;
//Update PickupNotes
export const API_URL_UPDATE_PICKUP_NOTES = `${BASE_URL}/api/v1/ride/update/pickup/note`;

//Multistop add stops API

export const API_URL_ADD_STOP_ESTIMATE = `${BASE_URL}/api/v1/ride/destinations/estimate`;
export const API_URL_ADD_STOP_CONFIRM = `${BASE_URL}/api/v1/ride/confirm/destinations`;
export const API_URL_GET_CATEGORY = `${BASE_URL}/api/v1/package/`;
export const API_GET_SLOTS = `${BASE_URL}/api/v1/slots`;

//Live Mamp Tab API
export const API_URL_FETCH_LIVE_MAP_DATA = `${BASE_URL}/api/v1/ride/live/map`;

export const API_URL_FETCH_INVOICE_DATA = `${BASE_URL}/api/v1/ride/invoice/details`;
export const API_URL_DOWNLOAD_INVOICE = `${BASE_URL}/api/v1/ride/invoice/download`;

export const API_URL_INVOICE_SUMMARY_ZIP = `${BASE_URL}/api/v1/ride/invoice/download/zip`;
export const API_URL_INVOICE_SUMMARY_DOWNLOAD = `${BASE_URL}/api/v1/ride/invoice/summary/download`;

export const API_URL_FETCH_ACCOUNT_DETAILS = `${BASE_URL}/api/v1/client`;
export const API_URL_CREATE_BUSSINESS_ACCOUNT = `${BASE_URL}/api/v1/client/create`;
export const API_URL_UPDATE_BUSINESS_ACCOUNT = `${BASE_URL}/api/v1/client/`;
export const API_URL_GET_BUSINESS_ACCOUNTS = `${BASE_URL}/api/v1/client/get/all`;

//Package
export const API_URL_CREATE_PACKAGE = `${BASE_URL}/api/v1/package/create`;
export const API_URL_UPDATE_PACKAGE = `${BASE_URL}/api/v1/package/update/configuration`;
export const API_URL_FETCH_PACKAGES = `${BASE_URL}/api/v1/package/fetch/all`;
export const API_URL_FETCH_PACKAGES_REGION_WISE =`${BASE_URL}/api/v1/package/get/all/service-region`;

//Pricing
export const API_URL_CREATE_PRICING = `${BASE_URL}/api/v1/client/price/model/create`;
export const API_URL_DEACTIVATE_PRICING = `${BASE_URL}/api/v1/client/price/model/`;
export const API_URL_FETCH_ACTIVE_PRICING_MODEL = `${BASE_URL}/api/v1/client/price/model/get/all`;
export const API_URL_GET_NEW_ESTIMATED_PRICE = `${BASE_URL}/api/v1/ride/reschedule/estimate`;

//reports
export const API_URL_FETCH_DATA = `${BASE_URL}/api/v1/reports/`;
export const API_URL_CUSTOM_REPORT = `${BASE_URL}/api/v1/reports/custom`;
export const API_URL_LAST_CUSTOM_REPORT = `${BASE_URL}/api/v1/reports/custom/last/status`;

export const API_URL_DOWNLOAD_PAYMENT_RECEIPTS_ZIP = `${BASE_URL}/api/v1/reports/download/payment/receipts/zip/`;
export const API_URL_DOWNLOAD_GST_INVOICES_ZIP = `${BASE_URL}/api/v1/reports/download/gst/invoices/zip/`;
export const API_URL_DOWNLOAD_CO2_SAVINGS = `${BASE_URL}/api/v1/reports/download/co2/`;
export const API_URL_DONWLOAD_CO2_SAVINGS_LIFETIME = `${BASE_URL}/api/v1/reports/download/co2/lifetime/`;
export const API_URL_DOWNLOAD_TRANSACTION_CSV = `${BASE_URL}/api/v1/reports/download/transaction/csv/`;

//Invoicing
export const API_INVOICE_UPLOAD = `${BASE_URL}/api/v1/reports/upload/invoice/excel`;

//Users
export const API_URL_USER_ADD_WINDOW = `${BASE_URL}/api/v1/view?view=USER_ADD_WINDOW`;


//Sub-Places
export const API_URL_ADD_SUB_PLACES = `${BASE_URL}/api/v1/location/sub/places`;

//Reset Password
export const API_URL_VERIFY_USER =  `${BASE_URL}/api/v2/auth/verify/user`;
export const API_URL_SEND_OTP = `${BASE_URL}/api/v2/auth/send/otp`;
export const API_URL_VERIFY_OTP = `${BASE_URL}/api/v2/auth/verify/otp`;
//tnc
export const API_URL_TNC = `${BASE_URL}/api/v1/view/web/strings?type=CREATE_RIDE`;
export const API_URL_TNC_INFO = `${BASE_URL}/api/v1/view/web/strings?type=INFO`;
export const API_URL_FETCH_HOMEPAGE_DATA = `${BASE_URL}/api/v1/view/web/strings?type=HOME_SCREEN`;

//metadata 
export const API_URL_LOCATION_METADATA = `${BASE_URL}/api/v1/location/location/metadata`;
export const API_URL_RIDE_METADATA = `${BASE_URL}/api/v1/ride/metadata`


//onboard business
export const API_URL_GET_COUNTRY_CODES = `${BASE_URL}/api/v1/country/list`;

//update account setup
export const API_URL_UPDATE_ACCOUNT_DETAILS = `${BASE_URL}/api/v1/client`

//recurring rentals
export const API_URL_RECURRING_RIDES_COUNT = `${BASE_URL}/api/v1/recurring/stats`;
export const API_URL_RECURRING_ONGOING_RIDES = `${BASE_URL}/api/v1/recurring/fetch/ongoing`;
export const API_URL_RECURRING_PAST_RIDES = `${BASE_URL}/api/v1/recurring/fetch/past`;
export const API_URL_PERFORM_RECURRING_ACTION = `${BASE_URL}/api/v1/recurring/action`;
export const API_URL_REQUEST_RECURRING_RIDE = `${BASE_URL}/api/v1/recurring/request`;
export const API_URL_FETCH_ALL_RECURRING_USERS = `${BASE_URL}/api/v1/rider/search`;
export const API_URL_FETCH_RECURRING_PACKAGE_DETAILS = `${BASE_URL}/api/v1/recurring/fetch/detail`;
export const API_URL_RESCHEDULE_RECURRING_PACKAGE = `${BASE_URL}/api/v1/recurring/initiate/update`;
export const API_URL_RECURRING_RIDE_ACTION = `${BASE_URL}/api/v1/ride/actions`;
export const API_URL_FETCH_DAILY_RECURRING_DETAILS = `${BASE_URL}/api/v1/recurring/fetch/daily/recurring/detail`;
export const API_URL_DUPLICATE_RECURRING_RIDE = `${BASE_URL}/api/v1/ride/duplicate`;
//country and city selection 
export const API_URL_SELECT_ZONE = `${BASE_URL}/api/v1/logged/in/user/metadata`
export const API_URL_CHECK_PROMO = `${BASE_URL}/api/v1/promo/apply`;

//mfa
export const API_URL_MFA_SEND_OTP = `${BASE_URL}/api/v2/auth/send/mfa/otp`;
export const API_URL_MFA_VERIFY_OTP = `${BASE_URL}/api/v2/auth/verify/mfa/otp`;

//pending payment
export const API_URL_FETCH_PENDING_PAYMENTS = `${BASE_URL}/api/v1/pending/payments/fetch`;
export const API_URL_GET_PAYMENT_FROM_WALLET = `${BASE_URL}/api/v1/pending/payments/pay/wallet`;
export const API_URL_GET_PAYMENT_FROM_PREPAID = `${BASE_URL}/api/v1/pending/payments/pay`;
//roster upload
export const API_URL_UPLOAD_ROSTER_CSV =  `${BASE_URL}/api/v1/shared/ride/bulk/request`;
export const API_URL_CONFIRM_ROSTER_CSV_SHARED_RIDES = `${BASE_URL}/api/v1/shared/ride/bulk/confirm`;

// expense code & business ride reason
export const API_URL_REASONS_CREATE_RIDE = `${BASE_URL}/api/v1/ride/get/purpose/list`;
export const API_URL_UPDATE_EXPENSE_CODE = `${BASE_URL}/api/v1/ride/update/expense/code`;

//sso
export const API_URL_LOGIN_WITH_SSO = `${BASE_URL}/api/v1/auth/sso`;
export const API_URL_CHECK_SSO_STATUS = `${BASE_URL}/api/v1/auth/okta/status` 

//lead time
export const API_URL_LEAD_TIME = `${BASE_URL}/api/v1/leadtime/get`;

//ticket save
export const API_URL_SAVE_TICKET = `${BASE_URL}/api/v1/support/ticket/save`;

//package estimator
export const API_URL_CALCULATE_DISTANCE = `${BASE_URL}/api/v1/package/distance/estimate`;