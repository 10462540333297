import { AfterViewInit, Component} from '@angular/core';
import { CommonService } from '../../getter-setter/common.service';
import { BreadCrumbAction, CustomButton, CustomDropDownInputType, CustomDropDownType, SlideCarouselData } from '../../types/components.type';
import { fadeAnimIn, fadeAnimOut, slideAnimIn, slideAnimOut } from '../../animations/containers/homepage-animations';
import { Router } from '@angular/router';
import { CountryFeatureListService } from '../../localization/country-feature-list/country-feature-list.service';
import { HomepageApiService } from '../../api-services/homepage/homepage-api.service';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateNewRide } from 'src/app/main/container/duty/component/create-new-ride.component';
import { BreadCrumbType, ButtonTypes } from '../../types/enums';


@Component({
  selector: 'homepage-container',
  templateUrl: './homepage-container.component.html',
  styleUrls: ['./homepage-container.component.scss'],
  animations:[slideAnimIn('right', 300), slideAnimOut('right', 300), fadeAnimIn(300), fadeAnimOut(300),
              slideAnimIn('right', 500), slideAnimOut('right', 500), fadeAnimIn(500), fadeAnimOut(500),
              slideAnimIn('right', 700), slideAnimOut('right', 700),
              slideAnimIn('up', 300), slideAnimOut('up', 300), 
              slideAnimIn('left', 300), slideAnimOut('left', 300),
              slideAnimIn('left', 500), slideAnimOut('left', 500),
              slideAnimIn('down', 300), slideAnimOut('down', 300),
              slideAnimIn('down', 500), slideAnimOut('down', 500),
              slideAnimIn('down', 700), slideAnimOut('down', 700)
            ]
})
export class HomepageContainerComponent implements AfterViewInit {
  ApiData = {
    notifications: [],
    heroCarousel: [],
    exploreMore: [],
    pricingDetails : {},
    serviceRegions: {},
    faqs :[],
    news: [],
    externalLinks: []
  }


  breadcrumbData: BreadCrumbAction; 
  createRidCtaDetails:CustomButton = {
    label: "Create Ride",
    left: false,
    right: false,
    leftUrl: '',
    rightUrl: '',
    disabled: false,
    type: ButtonTypes.FILLED
  } 


  pricingServiceRegions: CustomDropDownType ={
    selectedValue: {
      label: '',
      value: '',
    },
    options: [],
    validation: null,
    label: '',
    name: 'cities',
    placeholder: 'Select City',
    isDisabled: false,
    required: false,
    enableTooltip: false,
    tooltipContent: ''
  } 

  serviceRegionsDropDown: CustomDropDownType ={
    selectedValue: {
      label: '',
      value: '',
    },
    options: [],
    validation: null,
    label: '',
    name: 'cities',
    placeholder: 'Select City',
    isDisabled: false,
    required: false,
    enableTooltip: false,
    tooltipContent: ''
  }


  animations = {
    notification: {slideUpAnimation: ''},
    caption: {slideRightAnimation : '', slideLeftAnimation: ''},
    carousel: {fadeAnimation: ''},
    createRide: {fadeAnimation: ''},
    whatsNew: {slideRightAnimation: ''},
    usefulLinks: {slideRightAnimation: ''},
    pricing: {slideDownAnimation: ''},
    serviceRegion: {slideDownAnimation: ''},
    faq: {slideDownAnimation : ''}
  }

  animationInterval;
  newFlowUser = ['59', '1145', '1345'];
  customPricingBusinessAccounts = ['1865','1863','1197','1219','1150','1151','1155','1153','1734','1152','1907'];
  showNewCreateRide = false;
  paymentDialog = false;
  pendingPaymentDialogTransferDto = {
    'totalAmount': 0,
    'paymentOrderId' : [],
  }

  homepageFeature = this._countryFeature.getCountryFeatureList()['homepage'];

  constructor(private _common: CommonService, 
              private _router: Router,
              private _homepageApi: HomepageApiService,
              private paymentService: PaymentService,
              private modal: NgbModal,
              private _countryFeature: CountryFeatureListService )
      {
      this.setBreadCrumbLabel(BreadCrumbType.ADD, '')
      this.setServiceRegions();
      this._common.setAllowedRideTypesDetails();
      this.getHomepageData();
  }
  

  ngAfterViewInit() {
      this.animationInterval = setTimeout(()=>{
        this.animations.caption.slideRightAnimation = 'IN';
        this.animations.caption.slideLeftAnimation = 'IN';
        this.animations.notification.slideUpAnimation = 'IN';
        this.animations.whatsNew.slideRightAnimation = 'IN';
        this.animations.carousel.fadeAnimation = 'IN';
        this.animations.usefulLinks.slideRightAnimation = 'IN';
        this.animations.pricing.slideDownAnimation = 'IN';
        this.animations.serviceRegion.slideDownAnimation = 'IN';
        this.animations.faq.slideDownAnimation = 'IN';
        this.animations.createRide.fadeAnimation = 'IN';
      },300) 



      this.showNewCreateRide = this.newFlowUser.includes(localStorage.getItem('businessAccountId')) || localStorage.getItem('createRideV2') == 'true' ? true : false;
      this.homepageFeature['pricing'] =  this.homepageFeature['pricing'] && !this.customPricingBusinessAccounts.includes(localStorage.getItem('businessAccountId')) ? true : false;
  }

  createRide(){

    this.paymentDialog = false;
    let totalAmountPending = 0;
    let maxPendingLimit = 0;
    let paymentOrderIds = [];
    this.paymentService.fetchPendingPayment().subscribe((res)=>{
      totalAmountPending = res['response']['totalPendingAmount'] == null ? 0 : res['response']['totalPendingAmount'];
      maxPendingLimit = res['response']['maxPendingAmount'] == null ? 0 : res['response']['maxPendingAmount'];
      paymentOrderIds = res['response']["transactionsList"].length > 0 ?res['response']["transactionsList"].map(item=>item['orderId']) : [];

      if(totalAmountPending == 0 || totalAmountPending < maxPendingLimit){
        if(totalAmountPending == 0 || totalAmountPending < maxPendingLimit){
          if(this.newFlowUser.includes(localStorage.getItem('businessAccountId')) || localStorage.getItem('createRideV2') == 'true'){
            this.openNewCreateRideFlow();
          }
          else{
            const modal = this.modal.open(CreateNewRide, {
              ariaLabelledBy: "modal-basic-title",
              size: "lg"
            });
          }
        }
      }
    
      else{
        this.paymentDialog = true;
        this.pendingPaymentDialogTransferDto = {
          'totalAmount': totalAmountPending,
          'paymentOrderId' : paymentOrderIds,
        }
      }
    })
}


openNewCreateRideFlow(){
  this.toggleAnimation().then(res=>{
    setTimeout(()=>{
      this._router.navigate(['/select'])
    },300)
  })
}

setServiceRegions(){
  let regions = this._countryFeature.getCountryFeatureList()['serviceRegions'];
  if(!this._common.getIsMumbaiEnabled()){
    delete regions[4];
  }

  this.pricingServiceRegions.options = Object.keys(regions).map(region=>{return {label: regions[region] , value: region}});
  this.serviceRegionsDropDown.options = Object.keys(regions).map(region=>{return {label: regions[region] , value: region}});
  this.pricingServiceRegions.selectedValue = this.pricingServiceRegions.options[0];
  this.serviceRegionsDropDown.selectedValue = this.serviceRegionsDropDown.options[0]
}



  toggleAnimation(){
    let x  = new Promise((resolve, reject)=>{
      this.animations.caption.slideRightAnimation = this.animations.caption.slideRightAnimation == 'IN' ? 'OUT' : 'IN'
      this.animations.caption.slideLeftAnimation = this.animations.caption.slideLeftAnimation == 'IN' ? 'OUT' : 'IN';
      this.animations.notification.slideUpAnimation = this.animations.notification.slideUpAnimation == 'IN' ? 'OUT' : 'IN';
      this.animations.whatsNew.slideRightAnimation = this.animations.whatsNew.slideRightAnimation == 'IN' ? 'OUT' : 'IN';
      this.animations.usefulLinks.slideRightAnimation = this.animations.usefulLinks.slideRightAnimation == 'IN' ? 'OUT' : 'IN';
      this.animations.pricing.slideDownAnimation = this.animations.pricing.slideDownAnimation == 'IN' ? 'OUT' : 'IN';
      this.animations.serviceRegion.slideDownAnimation = this.animations.serviceRegion.slideDownAnimation == 'IN' ? 'OUT' : 'IN';
      this.animations.faq.slideDownAnimation = this.animations.faq.slideDownAnimation == 'IN' ? 'OUT' : 'IN';
      this.animations.carousel.fadeAnimation = this.animations.carousel.fadeAnimation == 'IN' ? 'OUT' : 'IN';
      this.animations.createRide.fadeAnimation = this.animations.createRide.fadeAnimation == 'IN' ? 'OUT' : 'IN';
      resolve(true)
    })
    return x;
  }

  setBreadCrumbLabel(type, item){
    setTimeout(()=>{
      this.breadcrumbData = { type: type ,item: item};
    },100);
    }


   async getHomepageData(){
        this._homepageApi.fetchHomescreenData().subscribe(async res=>{
        let newApiData = await res['response'];

        this.getDelayAlerts(newApiData.notifications.alert).then(async (res: string[])=>{
          this.ApiData.notifications = await [...res, ...newApiData.notifications.basic];  
        });
        
        this.ApiData.heroCarousel = await newApiData?.heroCarousel;
        this.ApiData.pricingDetails = await newApiData?.pricingDetails;
        this.ApiData.serviceRegions =  await newApiData?.serviceRegions;
        this.ApiData.exploreMore = await newApiData?.explore;
        this.ApiData.faqs = await newApiData?.faqs;
        this.ApiData.news = await newApiData?.news;
        this.ApiData.externalLinks = await newApiData?.externalLinks;
        })
    }

    getDelayAlerts(alerts){
      let delayAlerts = new Promise((resolve, reject)=>{
        let newArr = [];
        this._homepageApi.getDelayAlerts().subscribe((res)=>{
          res['response']['isAppOutageAlert'] ? newArr.push(alerts.appOutage) : null;
          res['response']['isHighDemandAlert'] ? newArr.push(alerts.highDemand) : null;
          res['response']['isRainDelayAlert'] ? newArr.push(alerts.rainDelay) : null;
          resolve(newArr);
        });
      })

      return delayAlerts;
    }


    closePendingPaymentDialog(){
      this.paymentDialog = false;
    }
}


