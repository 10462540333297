import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  ConfirmEventType,
  ConfirmationService,
  MessageService,
} from 'primeng/api';
import { Subject, Subscription } from 'rxjs';
import { CommonSubscriptionService } from '../../subscriptions/common/common-subscription.service';

@Component({
  selector: 'pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss'],
})
export class PopUpComponent implements OnInit, OnDestroy{
  popupSubscription: Subscription;
  @ViewChild('customTemplate') customTemplate: ElementRef; 
  
  popupSeverity = {
    error: false,
    warning: false,
    info: false,
    success: false,
    custom: false
  };

  title = '';
  message = '';
  ctaLabel = '';
  cancelCtaLabel = ''
  cancelCta = false;
  template = '';
  theme = '';

  constructor(private _subscription: CommonSubscriptionService) {}
  

  ngOnInit(): void {
    this.popupSubscription = this._subscription.fetchAlert().subscribe(
      (res) => {
        this.theme = res.theme;
        this.title = res.title;
        this.message = res.message;
        this.ctaLabel = res.ctaLabel;
        this.template = res?.template;
        this.cancelCtaLabel = res?.cancelCtaLabel;
        this.cancelCta = res?.cancelCta;

        Object.keys(this.popupSeverity).forEach(
          (theme) =>{
            return this.popupSeverity[theme] = false
          } 
        );

        switch (res.theme) {
          case 'ERROR':
            this.popupSeverity.error = true;
            break;
          case 'WARNING':
            this.popupSeverity.warning = true;
            break;
          case 'INFO':
            this.popupSeverity.info = true;
            break;
          case 'SUCCESS':
            this.popupSeverity.success = true;
            break;
          case 'CUSTOM':
            this.popupSeverity.custom = true;
            this.customTemplate.nativeElement['innerHTML'] = this.template;
            break;
        }
      }
    );
  }

  closeDialog(field, type = 'nan') {
      console.log(field, type);
      if(type == 'cancel'){
        this.popupSeverity[field] = false;
      }
  
      else{
        this._subscription.emitCloseAlert(true, this.theme);
        this.popupSeverity[field] = false;
      }
  }


  ngOnDestroy(): void {
    this.popupSubscription.unsubscribe();
  }
}
