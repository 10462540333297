import {
  API_BULK_SHARED_UPDATE_ESTIMATE,
  API_CONFIRM_BULK_SHARED_UPDATE_RIDE,
  API_BULK_SINGLE_UPDATE_ESTIMATE,
  API_CONFIRM_SINGLE_SHARED_UPDATE_RIDE,
  API_URL_FETCH_ALL_STOPS,
  API_URL_CREATE_LIVE_RIDE,
  API_URL_SHARED_PRICING,
  API_URL_CREATE_SHARED_LIVE_RIDE,
  API_URL_CONFIRM_SHARED_LIVE_RIDE,
  API_URL_LIST_SCHEDULED_RIDE,
  API_URL_STATS_LIVE,
  API_URL_RIDE_DETAILS,
  API_URL_SLOTS_STATS,
  API_URL_DOWNLOAD_RECEIPT,
  API_URL_RIDE_DETAILS_FOR_OTP,
  API_URL_CONFIRM_RIDE_FOR_OTP,
  API_URL_PROVISIONAL_AMT,
  API_URL_PICKUP_NOTES_HISTORY,
  API_URL_RIDE_ACTION,
  API_URL_UPDATE_BUSINESS_RIDE_REASON,
  API_URL_UPDATE_PICKUP_NOTES,
  API_URL_DOWNLOAD_INVOICE,
  API_URL_INVOICE_SUMMARY_DOWNLOAD,
  API_URL_INVOICE_SUMMARY_ZIP,
  API_URL_GET_CATEGORY,
  API_GET_SLOTS,
  API_URL_FETCH_ALL_SHARED_RIDES,
  API_URL_UPDATE_SINGLE_SHARED_RIDE,
  API_URL_UPDATE_BULK_SHARED_RIDE,
  API_URL_DUPLICATE_RIDE,
  API_URL_FETCH_PACKAGES_REGION_WISE,
  API_URL_RECURRING_RIDES_COUNT,
  API_URL_RECURRING_ONGOING_RIDES,
  API_URL_RECURRING_PAST_RIDES,
  API_URL_PERFORM_RECURRING_ACTION,
  API_URL_REQUEST_RECURRING_RIDE,
  API_URL_FETCH_RECURRING_PACKAGE_DETAILS,
  API_URL_RESCHEDULE_RECURRING_PACKAGE,
  API_URL_RECURRING_RIDE_ACTION,
  API_URL_FETCH_DAILY_RECURRING_DETAILS,
  API_URL_DUPLICATE_RECURRING_RIDE,
  API_URL_CHECK_PROMO,
  API_URL_GET_NEW_ESTIMATED_PRICE,
  API_URL_UPLOAD_ROSTER_CSV,
  API_URL_CONFIRM_ROSTER_CSV_SHARED_RIDES,
  API_URL_REASONS_CREATE_RIDE,
  API_URL_UPDATE_EXPENSE_CODE
} from "./../../config/apiRouteConfig/index";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import {
  API_URL_LIST_LIVE_RIDE,
  API_URL_PAST_RIDE_LIST,
  API_URL_ACTION_LIVE_RIDE,
  API_URL_PASTRIDE_CSV_DOWNLOAD,
  API_URL_COMMISSION_PAST_RIDES,
  API_URL_PAST_RIDE_LIST_CSV,
  API_URL_PAST_RIDE_RECEIPT_ZIP,
  API_URL_ADD_STOP_ESTIMATE,
  API_URL_ADD_STOP_CONFIRM,
} from "../../config/apiRouteConfig";

let rosterRideBasedError = [];
let rosterErrorMessages = [];
@Injectable({
  providedIn: "root",
})


export class RideService {


  getWeeklySummary(start: number, end: number) {
    return this._http.get(
      API_URL_INVOICE_SUMMARY_DOWNLOAD +
        "?startTime=" +
        start +
        "&endTime=" +
        end,
      { responseType: "blob" }
    );
  }

  

  constructor(private _http: HttpClient) {}
  downloadFile(start, end): Observable<Blob> {
    return this._http.get(
      API_URL_PASTRIDE_CSV_DOWNLOAD + "?startTime=" + start + "&endTime=" + end,
      { responseType: "blob" }
    );
  }

  actionOnThisRide(
    action,
    rideId,
    driverId = null,
    data = null,
    latitude = null,
    longitude = null,
    locationName = null
  ): Observable<any> {
    //console.log(action, rideId);
    var post: any = {
      action,
      rideId,
    };
    if (data != null) {
      Object.keys(data).forEach((key) => {
        post[key] = data[key];
      });
    }
    if (driverId) {
      post.driverId = driverId;
    }
    if (latitude && longitude && locationName) {
      post.latitude = latitude;
      post.longitude = longitude;
      post.locationName = locationName;
    }
    // console.log(post);
    return this._http.post(
      `${API_URL_ACTION_LIVE_RIDE}/${rideId}/actions`,
      post
    );
  }
  getLiveRides(type): Observable<any> {
    let url = `${API_URL_LIST_LIVE_RIDE}`;
    // if (type)
    //   url += '?ride_type=' + type
    return this._http.get(url);
  }

  getPastRides(start, end, filterValue: any, index): Observable<any> {
    index = index * 100;

    return this._http.post(
      `${API_URL_PAST_RIDE_LIST}` +
        "?startTime=" +
        start +
        "&endTime=" +
        end +
        "&count=100&record=" +
        index +
        "",
      JSON.parse(JSON.stringify(filterValue))
    );
  }

  getScheduledRides(seek, pageSize): Observable<any> {
    let url = API_URL_LIST_SCHEDULED_RIDE+"?count="+pageSize+"&record="+seek;
    
    return this._http.post(url,{});
  }
  getMapData(from, to) {
    // tslint:disable-next-line: max-line-length
    return this._http.get(
      `https://maps.googleapis.com/maps/api/directions/json?origin=${from.lat},${from.lng}&destination=${to.lat},${to.lng}&key=AIzaSyAHaaYJ39pVWpqbqsoq2Jh7Q7NSgbL0rsQ`
    );
  }

  getSharedRidePricingDetails(sharedId) {
    return this._http.get(API_URL_SHARED_PRICING + sharedId);
  }

  getBulkSharedRideUpdateEstimate(sharedRideId, data) {
    return this._http.post(
      API_BULK_SHARED_UPDATE_ESTIMATE + sharedRideId,
      data
    );
  }

  confirmBulkSharedRideUpdateRide(sharedRideId) {
    return this._http.post(
      API_CONFIRM_BULK_SHARED_UPDATE_RIDE + sharedRideId,
      {}
    );
  }

  getSingleSharedRideUpdateEstimate(rideRequestID, data) {
    return this._http.post(
      API_BULK_SINGLE_UPDATE_ESTIMATE + rideRequestID,
      data
    );
  }

  confirmSingleSharedRideUpdateRide(rideRequestID) {
    return this._http.post(
      API_CONFIRM_SINGLE_SHARED_UPDATE_RIDE + rideRequestID,
      {}
    );
  }

  getAllStopsForSharedRide(rideRequestID) {
    return this._http.get(API_URL_FETCH_ALL_STOPS + rideRequestID);
  }

  createLiveRide(data) {
    return this._http.post(API_URL_CREATE_LIVE_RIDE, data);
  }

  createSharedLiveRide(data) {
    return this._http.post(API_URL_CREATE_SHARED_LIVE_RIDE, data);
  }

  confirmSharedRideRequest(sharedRideRequestId) {
    return this._http.post(
      API_URL_CONFIRM_SHARED_LIVE_RIDE + "?id=" + sharedRideRequestId,
      {}
    );
  }

  getAllSharedRides(businessCode, limit, start) {
    return this._http.get(
      API_URL_FETCH_ALL_SHARED_RIDES +
        "?businessCode=" +
        businessCode +
        "&limit=" +
        limit +
        "&start=" +
        start
    );
  }

  updateSingleSharedRide(data) {
    return this._http.post(API_URL_UPDATE_SINGLE_SHARED_RIDE, data);
  }

  updateBulkSharedRide(data) {
    let url = API_URL_UPDATE_BULK_SHARED_RIDE;
    if (data.id) {
      url = url + "?id=" + data.id;
      delete data.id;
    }
    return this._http.post(url, data);
  }

  getOnlineDriverStats() {
    return this._http.get(API_URL_STATS_LIVE);
  }
  getSlotStats() {
    return this._http.get(API_URL_SLOTS_STATS);
  }

  getDetailsForRideRequestId(rideRequestId) {
    return this._http.get(API_URL_RIDE_DETAILS + "/" + rideRequestId);
  }

  getDetailsForOtp(otp) {
    return this._http.get(API_URL_RIDE_DETAILS_FOR_OTP.replace(":otp", otp));
  }

  confirmRideForOtp(otp) {
    return this._http.post(
      API_URL_CONFIRM_RIDE_FOR_OTP.replace(":otp", otp),
      {}
    );
  }

  downloadReceipt(rideId): Observable<Blob> {
    return this._http.post(
      API_URL_DOWNLOAD_RECEIPT + "?rideId=" + rideId,
      rideId,
      { responseType: "blob" }
    );
  }

  downloadInvoice(rideId): Observable<Blob> {
    return this._http.post(
      API_URL_DOWNLOAD_INVOICE + "?rideId=" + rideId,
      rideId,
      { responseType: "blob" }
    );
  }

  getProvisionalAmount(data) {
    var post: any = {};
    if (data != null) {
      Object.keys(data).forEach((key) => {
        post[key] = data[key];
      });
    }
    return this._http.post(`${API_URL_PROVISIONAL_AMT}`, post);
  }

  getPickupNotesHistory(rideRequestId: any): Observable<any> {
    return this._http.get(
      `${API_URL_PICKUP_NOTES_HISTORY}` + "?rideRequestId=" + rideRequestId
    );
  }

  performActionOnRide(rideid, data) {
    return this._http.post(
      `${API_URL_RIDE_ACTION}` + rideid + "/actions",
      data
    );
  }

  performActionOnRecurringRide(body){
    return this._http.post(API_URL_RECURRING_RIDE_ACTION, body);
  }

  getCommissionForPastRide(data): Observable<any> {
    return this._http.post(
      `${API_URL_COMMISSION_PAST_RIDES}` +
        "?endTime=" +
        data.endTime +
        "&startTime=" +
        data.startTime,
      data
    );
  }

  getPastRideCSV(start, end, commission): Observable<Blob> {
    return this._http.get(
      `${API_URL_PAST_RIDE_LIST_CSV}` +
        "?startTime=" +
        start +
        "&endTime=" +
        end +
        "&commission=" +
        commission,
      { responseType: "blob" }
    );
  }

  requestZip(start, end): Observable<Blob> {
    return this._http.post(
      `${API_URL_PAST_RIDE_RECEIPT_ZIP}` +
        "?startTime=" +
        start +
        "&endTime=" +
        end,
      {},
      { responseType: "blob" }
    );
  }

  requestInvoiceZip(start, end): Observable<Blob> {
    return this._http.post(
      `${API_URL_INVOICE_SUMMARY_ZIP}` +
        "?startTime=" +
        start +
        "&endTime=" +
        end,
      {},
      { responseType: "blob" }
    );
  }

  updateBusinessRideReason(id: any, data, isRecurringRide): Observable<any> {
    if(isRecurringRide == true){
      return this._http.post(`${API_URL_UPDATE_BUSINESS_RIDE_REASON}` + "?recurringDailyId=" + id, data);
    }
    else{
    return this._http.post(`${API_URL_UPDATE_BUSINESS_RIDE_REASON}` + "?rideRequestId=" + id, data);
    }
  }

  updatePickUpNotes(rideRequestId: any, data, isRecurring): Observable<any> {
    if(isRecurring == true){
    return this._http.post(`${API_URL_UPDATE_PICKUP_NOTES}` + "?recurringDailyId=" + rideRequestId, data);
    }
    else{
    return this._http.post(`${API_URL_UPDATE_PICKUP_NOTES}` + "?rideRequestId=" + rideRequestId, data);
    }
  }
  rentalAddStopEstimate(data): Observable<any> {
    return this._http.post(`${API_URL_ADD_STOP_ESTIMATE}`, data);
  }

  rentalAddStopCOnfirm(data): Observable<any> {
    return this._http.post(`${API_URL_ADD_STOP_CONFIRM}`, data);
  }

  getEstimatedNewPricing(data){
    return this._http.get(`${API_URL_GET_NEW_ESTIMATED_PRICE}?rideRequestId=${data.rideRequestId}&newScheduleTime=${data.newScheduleTime}`)
  }


  getCategoryByPackageCode(code) {
    return this._http.get(`${API_URL_GET_CATEGORY}` + code + "/category");
  }
  getSlots(id, zoneId, isScheduled) {
    return this._http.get(`${API_GET_SLOTS}` + "?priceMapId=" + id + '&zoneId=' + zoneId + '&isScheduled=' + isScheduled);
  }

  duplicateRide(data) {
    return this._http.post(API_URL_DUPLICATE_RIDE, data);
  }

  getPkgDetailsRegionWise(data){
    return this._http.get(`${API_URL_FETCH_PACKAGES_REGION_WISE}`+'?lat='+ data.lat + '&lng='+data.lng);
  }

  uploadRosterCsv(data){
    return this._http.post(API_URL_UPLOAD_ROSTER_CSV, data)
  }

  confirmCsvSharedRides(data){
    return this._http.post(API_URL_CONFIRM_ROSTER_CSV_SHARED_RIDES, data)
  }

  setRosterRideError(errorDetails){
    rosterRideBasedError = errorDetails;
  }

  getRosterRideError(){
    return rosterRideBasedError;
  }

  setRosterErrorMessages(errorMessages){
    rosterErrorMessages = errorMessages;
  }

  getRosterErrorMessages(){
    return rosterErrorMessages;
  }

  fetchReasonsList(){
    return this._http.get(API_URL_REASONS_CREATE_RIDE)
  }

  updateExpenseCode(rideRequestId, expenseCode){
    return this._http.post(`${API_URL_UPDATE_EXPENSE_CODE}?rideRequestId=${rideRequestId}`, expenseCode);
  }

}
