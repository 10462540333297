import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TimeInterval } from 'rxjs';
import { slideAnimIn, slideAnimOut } from 'src/app/app-v2/animations/containers/homepage-animations';

@Component({
  selector: 'whats-new-container',
  templateUrl: './whats-new-container.component.html',
  styleUrls: ['./whats-new-container.component.scss'],
  animations: [slideAnimOut('right', 300), slideAnimIn('left', 300)]
})
export class WhatsNewContainerComponent implements OnInit, OnChanges {
  
  
  @Input() newsDetails = [];
  tempArr = [];
  animationArr = [];
  shouldSlide = true;
  
  ngOnChanges(changes: SimpleChanges): void {
    this.animationArr = this.newsDetails.map(news=>{
      return ({slide: 'IN'});
     })
  }

  ngOnInit(): void {
   this.animationArr = this.newsDetails.map(news=>{
    return ({slide: 'IN'});
   })
  }


  slide(dir){
    if(this.shouldSlide){
      let tempData = {};
      if(dir == 'forward'){
        if(this.newsDetails.length > 1){
          this.animationArr[0].slide == 'OUT'
          tempData = this.newsDetails[0];
          this.animationArr.shift();
          this.newsDetails.shift();
          this.tempArr.push(tempData);
        }
        else{
          return ;
        }
      }
  
      else{
        if(this.tempArr.length > 0){
          tempData = this.tempArr[this.tempArr.length-1];
          this.newsDetails.unshift(tempData)
          this.tempArr.pop();
          this.animationArr.unshift({slide: ''});
          this.animationArr[0].slide = 'IN';
        }
        else{
          this.newsDetails = this.newsDetails;        
          return ;
        }
      }

      this.startClickInterval();
    }
  }


  startClickInterval(){
    this.shouldSlide = false;
    setTimeout((res)=>{
      this.shouldSlide = true;
    }, 300)
  }



  redirectTo(url){
    if(url == null || url == ''){
      return '';
    }
    else{
      window.open(url , "_blank");
    }
  }
}
