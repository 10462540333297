import { Pipe, PipeTransform } from '@angular/core';
import { CountryFeatureListService } from '../localization/country-feature-list/country-feature-list.service';
import { DaysOfWeek } from '../types/enums';

@Pipe({
  name: 'createridepipe'
})
export class CreateridepipePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return null;
  }

}


@Pipe({
  name: 'datetimepipe'
})
export class DateTimePipe implements PipeTransform{
    transform(value: any, showNow: boolean = true, ...args: any[]): any {
      if (!value) {
        return '';
      }

      
      let localDate = new Date(value);
      const formattedDate = localDate.toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric', // Short month name (e.g., Aug)
        day: '2-digit', // Two-digit day (e.g., 29)
        hour: '2-digit', // Two-digit hour (e.g., 11)
        minute: '2-digit', // Two-digit minute (e.g., 43)
        second: '2-digit', // Two-digit second (e.g., 43)
        hour12: true, // Use 12-hour clock (AM/PM)
      });
      value = formattedDate
      
      
      const datesVal = value.split(',');
      
      let date = datesVal[0].split("/")[1].length == 1 ? `0${datesVal[0].split("/")[1]}` : datesVal[0].split("/")[1];
      let month = datesVal[0].split("/")[0].length == 1 ? `0${datesVal[0].split("/")[0]}` : datesVal[0].split("/")[0];
      let year = datesVal[0].split("/")[2];
      let time = datesVal[1] ? datesVal[1] : datesVal[1].split("at")[1];  
      let splittedTime = time.split(':');
      splittedTime[2]= splittedTime[2].slice(-2);

      let isCurrTime = checkCurrTime(`${splittedTime[0]}:${splittedTime[1]}`.trim())
      
      if(isCurrTime && showNow){
        return 'now';
      }
      else{
        return `${date}/${month}/${year}` + ',' +splittedTime[0]+':' +splittedTime[1] +' ' +splittedTime[2];
      }
      
      
    }
  }


  function checkCurrTime(timeString){
    let now = new Date();
    let currHour = now.getHours() > 12 ? now.getHours()%12: now.getHours().toString();
    currHour = currHour.toString().length < 2 ? `0${currHour}` : currHour.toString();

    let currMin = now.getMinutes().toString();
    currMin = currMin.toString().length < 2 ? `0${currMin}` : currMin.toString();

    if(timeString == `${currHour}:${currMin}`){
      return timeString == `${currHour}:${currMin}`;
    }
    else{
      return false;
    }
    
  }

  @Pipe({
    name: 'currencypipe'
  })
  export class CurrencyPipe implements PipeTransform{
    constructor(private _countryFeature: CountryFeatureListService){}

    transform(value: any, ...args: any[]) {
      let selectedCurrency  = this._countryFeature.getCountryFeatureList()['defaultCurrency'];

      if(selectedCurrency['type'] == 'PREFIX'){
        return `${selectedCurrency['code']}${value}`;
      }
      else{
        return `${value}${selectedCurrency['code']}`;
      }
    } 
  }

  @Pipe({
    name: 'onlyTime'
  })
  export class OnlyTime implements PipeTransform{
    transform(value: any){
      let localDate = new Date(value);
      const formattedDate = localDate.toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric', // Short month name (e.g., Aug)
        day: '2-digit', // Two-digit day (e.g., 29)
        hour: '2-digit', // Two-digit hour (e.g., 11)
        minute: '2-digit', // Two-digit minute (e.g., 43)
        second: '2-digit', // Two-digit second (e.g., 43)
        hour12: true, // Use 12-hour clock (AM/PM)
      });
      value = formattedDate
        const datesVal = value.split(',');
        let time = datesVal[1] ? datesVal[1] : datesVal[1].split("at")[1];  
        let splittedTime = time.split(':');
        splittedTime[2]= splittedTime[2].slice(-2);

        return  splittedTime[0]+':' +splittedTime[1] +' ' +splittedTime[2];
    }
  }


  @Pipe({
    name: 'onlyDate'
  })
  export class OnlyDate implements PipeTransform{
    transform(value: any){
      let localDate = new Date(value);
      const formattedDate = localDate.toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric', // Short month name (e.g., Aug)
        day: '2-digit', // Two-digit day (e.g., 29)
        hour: '2-digit', // Two-digit hour (e.g., 11)
        minute: '2-digit', // Two-digit minute (e.g., 43)
        second: '2-digit', // Two-digit second (e.g., 43)
        hour12: true, // Use 12-hour clock (AM/PM)
      });
      value = formattedDate
        const datesVal = value.split(',');
        let date = datesVal[0].split("/")[1].length == 1 ? `0${datesVal[0].split("/")[1]}` : datesVal[0].split("/")[1];
        let month = datesVal[0].split("/")[0].length == 1 ? `0${datesVal[0].split("/")[0]}` : datesVal[0].split("/")[0];
        let year = datesVal[0].split("/")[2];

        return  `${date}/${month}/${year}`;
    }
  }

  @Pipe({
    name : 'daysOfWeekShort'
  })
  export class DaysOfWeekShort implements PipeTransform{
    transform(value: any, ...args: any[]) {
      if(typeof(value) == 'string'){
        return DaysOfWeek[value];
      }

      else{
        let newDaysOfWeek = value.map(days=>DaysOfWeek[days]);

        return newDaysOfWeek.toString();
      }
      
    }
  }
