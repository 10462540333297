import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { CreateRideApiService } from 'src/app/app-v2/api-services/create-ride/create-ride-api.service';
import { JourneyDetailsApiService } from 'src/app/app-v2/api-services/create-ride/journey-details-api.service';
import { CommonService } from 'src/app/app-v2/getter-setter/common.service';
import { CreateRideService } from 'src/app/app-v2/getter-setter/container/create-ride/create-ride-container.service';
import { CustomerDetailsContainerService } from 'src/app/app-v2/getter-setter/container/create-ride/customer-details-container.service';
import { JourneyDetailsContainerService } from 'src/app/app-v2/getter-setter/container/create-ride/journey-details-container.service';
import { SelectRideTypeContainerService } from 'src/app/app-v2/getter-setter/select-ride-type-container.service';
import { CommonSubscriptionService } from 'src/app/app-v2/subscriptions/common/common-subscription.service';
import { CreateRideSubscriptionService } from 'src/app/app-v2/subscriptions/create-ride/create-ride-subscription.service';
import { CreateRideRequestApiDto } from 'src/app/app-v2/types/apis.type';
import { CustomButton, DropdownDetails } from 'src/app/app-v2/types/components.type';
import { ButtonTypes, JourneyDetailsStep, Theme } from 'src/app/app-v2/types/enums';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'car-variant-container',
  templateUrl: './car-variant-container.component.html',
  styleUrls: ['./car-variant-container.component.scss']
})
export class CarVariantContainerComponent implements OnInit, OnDestroy{
fetchPackageSubscription: Subscription;
airportSubscription: Subscription;
carVariantValidationSubscription: Subscription;

@Output() prevAccEmiiter = new EventEmitter<string>();

carVariantCta: CustomButton = {
  label: 'Next',
  left: false,
  right: false,
  leftUrl: "",
  rightUrl: "",
  disabled: false,
  type: ButtonTypes.FILLED
}

packageDropDownDetails : DropdownDetails ={
  selectedValue: {
    label: '',
    value: ''
  },
  options: [],
  validation: null,
  label: 'Select Package',
  name: 'package',
  placeholder : 'Select Package',
  isDisabled: false,
  required: true
}

carVariantDetails = [];
packageData = {};
isPackageRequired = false;
isAirportRide = false;
isExpressRide = false;
rideMetaDataRequest: Omit<CreateRideRequestApiDto, 'dropLat' | 'dropLong' | 'dropLocation' | 'map' | 'otherFlags' | 'pickupNotes' | 'platform' | 'rideType' | 'stops' | 'rideSubCategory' | 'days' | 'recurringRideEndDate' | 'startTime'> & {startTime : number | null} ={
  callingCode: "",
  email: "",
  firstName: "",
  lastName: "",
  paymentMode: "",
  phoneNumber: "",
  pickupLat: null,
  pickupLocation: "",
  pickupLong: null,
  priceMapId: null,
  startTime: null,
  packageCode: ""
}

constructor(private _journeyApi: JourneyDetailsApiService, 
            private _createRideApi: CreateRideApiService,
            private _customer: CustomerDetailsContainerService,
            private _journey: JourneyDetailsContainerService,
            private _createRide: CreateRideService,
            private _subscription: CreateRideSubscriptionService,
            private _common: CommonService,
            private _commonSubscription: CommonSubscriptionService,
            private _rideType: SelectRideTypeContainerService,
            private _loader: NgxSpinnerService, private _appService:AppService){}

ngOnInit(): void {

  this.isPackageRequired = this._rideType.getIsPackageRequired();
  this.isAirportRide = this._rideType.getIsAirportRide();
  this.isExpressRide = this._rideType.getIsExpressRide();

  this.fetchPrevData();

  this.isPackageRequired ? this.startPackageSubscription() : null;
  this.isAirportRide ? this.startAirportSubscription() : null;
}


selectVariant(index){
    this.carVariantDetails.forEach(variant=>{
      variant.isActive = false;
    });

    this.carVariantDetails[index].isActive = true;
    this._journey.setSelectedCarVariantCategory(this.carVariantDetails[index].category);
    this.setDataAccordingToRideType(index);
    this.fetchRideMetadata()
}


setDataAccordingToRideType(index){
  let selectedRideType = this._rideType.getSelectedRideTypeDetails().code;
  this._rideType.setIsSlotRequired(this.carVariantDetails[index].slotsRequired);

  switch(selectedRideType){
    case 'SCHEDULED_RIDE':
      this._journey.setPriceMapId(null);
      this._journey.setSubCategory(this.isAirportRide ?  `${this.carVariantDetails[index].category}_AIRPORT` : null);
      return;
    
    case 'SCHEDULED_RENTALS':
      this._journey.setPriceMapId(this.carVariantDetails[index].priceMapId);
      this._journey.setSubCategory(this.isAirportRide ?  `${this.carVariantDetails[index].category}_AIRPORT` : null);
      return;
  }
}

calculateRentalDistance(){
  // if(this._appService.getCountry() == "AE"){
  //    let x = new Promise((resolve, reject)=>{
  //     return resolve(0);
  //    });
  //    return x;
  
  // }
  let x  = new Promise((resolve, reject)=>{
    let stopsList = this._journey.getStops().map(({ lat, lng, place }) => ({ lat, lng, place }));
        stopsList.unshift(this._journey.getPickupLocation());

    this._loader.show();
    this._journeyApi.calculateDistance(stopsList).subscribe(res=>{
      this._loader.hide();
      if(res['statusCode'] == 200){
        return resolve(res['response']);
      }

      else{
        this._commonSubscription.emitAlert({
          theme: Theme.ERROR,
          title: 'Technical Error!',
          message: res['message'],
          ctaLabel: 'OK!'
        });
        return reject(0);
      }
    })
  });

  return x;
}


fetchPackages(){
  
  let x = new Promise((resolve, reject)=>{
    if(this._journey.getPickupLocation()){
      this._journeyApi.fetchPackages(this._journey.getPickupLocation()).subscribe(res=>{
        this._loader.hide();
        if(res['statusCode'] == 200){
          this.packageData = res['response'];
          this.populatePackageInDropdown(res['response']);
          return resolve(true);
        }
        else{
          this._commonSubscription.emitAlert({
            theme: Theme.ERROR,
            title: 'Technical Error!',
            message: res['message'],
            ctaLabel: 'OK!'
          });
          return resolve(false);
        }
      });
    }

    else{
      return reject(new Error('Pickup location is not set'));
    }
  })
  
  return x;
}

fetchRideMetadata(){
  let customerDetails = Object.assign({}, this._customer.getCustomerDetailsToRequestRide());
  let journeyDetails = Object.assign({}, this._journey.getJourneyDetailsToRequestRide());
  this.rideMetaDataRequest = {
      callingCode: customerDetails.callingCode,
      email: customerDetails.email,
      firstName: customerDetails.firstName,
      lastName: customerDetails.lastName,
      paymentMode: "BUSINESS_ACCOUNT",
      phoneNumber: customerDetails.phoneNumber,
      pickupLat: journeyDetails.pickupLat,
      pickupLocation: journeyDetails.pickupLocation,
      pickupLong: journeyDetails.pickupLong,
      priceMapId: journeyDetails.priceMapId,
      startTime: new Date().getTime(),
      packageCode: journeyDetails.packageCode
  }

  let x = new Promise((resolve, reject)=>{
    this._loader.show()
    this._createRideApi.fetchRideMetadata(this.rideMetaDataRequest).subscribe(res=>{
      this._loader.hide();
      if(res['statusCode'] == 200){
        this._subscription.emitIsExpressRideObserver(res['response']['isExpressAvailable'])
        return resolve(true)
      }
    })
  })
}

startPackageSubscription(){
  this.fetchPackageSubscription = this._subscription.fetchPackageObserver().subscribe(res=>{
    if(res && this._rideType.getSelectedRideTypeDetails().packageRequired && this._journey.getPickupLocation().place != null){
      this._loader.show();
      this.fetchPackages()
      .then(res=>{
        if(!res){
          this._createRide.setSelectedJourneyDetailsStep(JourneyDetailsStep.LOCATION_DETAILS);
          this._subscription.emitJourneyDetailsActiveAcc(JourneyDetailsStep.LOCATION_DETAILS);
        }
        else{
          return ;
        }
        
      })
    }
    else{
      if(this._journey.getPickupLocation().place == null){
        this._commonSubscription.emitAlert({
          theme: Theme.WARNING,
          title: 'Select Location !',
          message: 'Please select pickup location to continue',
          ctaLabel: 'OK'
        })
        this.prevAccEmiiter.emit(JourneyDetailsStep.LOCATION_DETAILS);
      }
    }
  })
}


startAirportSubscription(){
  this.airportSubscription = this._subscription.fetchIsAirportRideObserver().subscribe(res=>{
    if(this._rideType.getIsAirportRide()){
      this.carVariantDetails = this._common.getZoneBasedCarVariants();
    }
    else{
      return ;
    }
  })
}

populatePackageInDropdown(packageList){
  this.packageDropDownDetails.options = [];
  let packageCodes = Object.keys(packageList);

  this.calculateRentalDistance().then(estimatedDistance=>{
    let packagesList = Object.values(packageList);
      packagesList.forEach((packageData,index)=>{
      let km = (packageData['baseDistance'] / 1000);
      let time = (packageData['baseTime'] / 3600000)
      let label = km + ' Km / ' + time + ' hr';
      let val = packageCodes[index]
      let buffer = index == packagesList.length - 1 ? km/2 : ((packagesList[index + 1]['baseDistance']/1000) - km)/2 ;
      
      if((km + buffer) >= +estimatedDistance){
        this.packageDropDownDetails.options.push({ "label": label, "value": val})
      }
      else{
        null;
      }
  });
  });
}


onPackageSelect(){
  let x = new Promise((resolve, reject)=>{
    this._journey.setPriceMapId(null);
    this.clearCarVariantData();
    this._journey.setPackageCode(this.packageDropDownDetails.selectedValue.value);
    this.carVariantDetails = [];
    let priceMapIds = {}
    
    let selectedPackageCategories = this.packageData[this.packageDropDownDetails?.selectedValue?.value]?.['categories'];
    let packageTypes = selectedPackageCategories ? selectedPackageCategories.map(category=>category.categoryCode) : [];
        priceMapIds = selectedPackageCategories ? selectedPackageCategories.map(categoryData=>{
          return {[categoryData.categoryCode]: categoryData.id};
        }) : [];
        
    this.carVariantDetails = this._common.getCarvariantDetails(packageTypes, priceMapIds);
    resolve(this.carVariantDetails);
  });

  return x;
 }


 startCarVariantValidationObserver(){
  this.carVariantValidationSubscription = this._subscription.fetchCarVariantValidationObserver().subscribe(res=>{
    this.submitCarVariantDetails();
  })
 }


 submitCarVariantDetails(){
  if(this._rideType.getSelectedRideTypeDetails().code == 'SCHEDULED_RIDE'){
    if(this._journey.getSubCategory() == null){
      this._commonSubscription.emitAlert({
        theme: Theme.WARNING,
        title: 'Select Car Variant',
        message: 'Please select car variant to continue',
        ctaLabel: 'Ok'
      });
      this._journey.setCarVariantValidation(false);
      return;
    }

    else{
      this._journey.setCarVariantValidation(true);
      this._subscription.emitTriggerCarVariantCtaObserver(true);
    }
  }

  else if(this._rideType.getSelectedRideTypeDetails().code == 'SCHEDULED_RENTALS'){
    if(this._journey.getPriceMapId() == null){
      this._commonSubscription.emitAlert({
        theme: Theme.WARNING,
        title: 'Select Car Variant',
        message: 'Please select car variant to continue',
        ctaLabel: 'Ok'
      });
      this._journey.setCarVariantValidation(false);
      return;
    }
    else{
      this._journey.setCarVariantValidation(true);
      this._subscription.emitTriggerCarVariantCtaObserver(true);
    }
  }
}


 clearCarVariantData(){
  this.carVariantDetails.forEach(variant=>{
    variant.isActive = false;
  });
 }

 fetchPrevData(){
  if(this.isPackageRequired && this._journey.getPickupLocation().place != null){
    this._loader.show();
    this.fetchPackages().then(res=>{
      if(res){
        this.packageDropDownDetails.options.forEach(rentalPackage=>{
          if(rentalPackage.value == this._journey.getPackageCode()){
            this.packageDropDownDetails.selectedValue = {label: rentalPackage.label, value: rentalPackage.value}
          }
          else{
            return;
          }
        });      
        let selectedPriceMapId = this._journey.getPriceMapId()
        this.onPackageSelect().then(res=>{
          let selectedPriceMapIdIndex = 0;
          this.carVariantDetails.forEach((carVariant, index)=>{
              if(carVariant.priceMapId == selectedPriceMapId){
                selectedPriceMapIdIndex = index;
              }
              else{
                return;
              }
          });
          
          this.carVariantDetails.length > 0 ? this.selectVariant(selectedPriceMapIdIndex) : null;
        });
      }


      else{
          this._createRide.setSelectedJourneyDetailsStep(JourneyDetailsStep.LOCATION_DETAILS);
          this._subscription.emitJourneyDetailsActiveAcc(JourneyDetailsStep.LOCATION_DETAILS);
      }
    });
  }

  else if(this.isAirportRide){
      this.carVariantDetails = this._common.getZoneBasedCarVariants();
      let subCategory = this._journey.getSubCategory() ?  this._journey.getSubCategory().split("_")[0] : null;
      this.carVariantDetails.forEach((carVariant,index)=>{
        if(carVariant.category == subCategory){
          this.selectVariant(index);
        }
        else{
          return;
        }
      })

  }
 }

 ngOnDestroy(): void {
  this.clearCarVariantData();
  this.fetchPackageSubscription ? this.fetchPackageSubscription.unsubscribe() : null;
 }

}
