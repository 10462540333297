<div class="main-container">
    <accordian [accordianDetails]="journeyDetails.locationDetails" *ngIf="journeyDetails.locationDetails.isVisible" (isActiveEvent)="toggleActiveAcc($event, 'locationDetails')" accordianType = 'STATIC'>
        <location-container accBody *ngIf="journeyDetails.locationDetails.isActive"></location-container>
    </accordian>

    <accordian [accordianDetails]="journeyDetails.carVariantDetails" *ngIf="journeyDetails.carVariantDetails.isVisible" (isActiveEvent)="toggleActiveAcc($event,'carVariantDetails')" accordianType = 'STATIC'>
        <car-variant-container accBody (prevAccEmiiter)="setActiveAccordian($event)" *ngIf="journeyDetails.carVariantDetails.isActive"></car-variant-container>
    </accordian>

    <accordian [accordianDetails]="journeyDetails.timestampDetails" [HTMLContent]="expressRideBanner" accordianType = 'STATIC'
    *ngIf="journeyDetails.timestampDetails.isVisible" (isActiveEvent)="toggleActiveAcc($event,'timestampDetails')">
    <div accBody class="radio-grp" *ngIf="isRecurringEnabled">
        <div accBody  class="radio-btn" (click)="selectIsRecurring(rideCategory?.value)" *ngFor="let rideCategory of timeStampRideCategory; index as i">
            <radio-btn [radioDetails]="rideCategory" (onChangeEmitter)="checkRadioValue($event)"></radio-btn>
            <div class="label">
                <span class="text">{{rideCategory.label}}</span>
                <span class="sub-text">{{rideCategory.subText}}</span>
            </div>
        </div>
    </div>
        <single-timestamp-container accBody *ngIf="journeyDetails.timestampDetails.isActive && selectedTimestampTemplate == 'SINGLE'"></single-timestamp-container>
        <recurring-timestamp-container accBody *ngIf="journeyDetails.timestampDetails.isActive && selectedTimestampTemplate == 'RECURRING'"></recurring-timestamp-container>
    </accordian>
</div>

