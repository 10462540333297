import { Injectable } from '@angular/core';
import {  RideType } from 'src/app/app-v2/types/container.type';
import { CreateRideRequestApiDto } from 'src/app/app-v2/types/apis.type';
import { CustomerDetailsContainerService } from './customer-details-container.service';
import { JourneyDetailsContainerService } from './journey-details-container.service';
import { SelectRideTypeContainerService } from '../../select-ride-type-container.service';

@Injectable({
  providedIn: 'root'
})
export class CreateRideService {

  constructor(private _customer: CustomerDetailsContainerService,
              private _journey: JourneyDetailsContainerService,
              private _selectRideTypeDetails: SelectRideTypeContainerService) { }

  createRideAllSteps = {
        CUSTOMER_DETAILS: {label: 'Customer Details', isComplete: false, isActive: false, caption: 'Enter the rider details', isDisabled: true},
        JOURNEY_DETAILS: {label: 'Journey Details', isComplete: false, isActive: false, caption: 'Enter pickup/drop and other details regarding your ride', isDisabled: true},
        WEEK_SCHEDULE: {label: 'Week Schedule', isComplete: false, isActive: false, caption: 'Set recurring week schedule', isDisabled: true},
        PAYMENT_DETAILS: {label: 'Payment Details', isComplete: false, isActive: false, caption: 'Review the details and proceed to pay', isDisabled: true}
        }


  // isAirportRide = false;   
  isExpressRide = false;  

  createRideApiDto: CreateRideRequestApiDto | null = {
    callingCode: '',
    dropLat: null,
    dropLocation: '',
    dropLong: null,
    email: '',
    firstName: '',
    lastName: '',
    map: '',
    otherFlags: null,
    paymentMode: '',
    phoneNumber: '',
    pickupLat: null,
    pickupLocation: '',
    pickupLong: null,
    pickupNotes: null,
    platform: 'WEB',
    priceMapId: null,
    rideType: '',
    startTime: null,
    stops: [],
    rideSubCategory: null,
    packageCode: null,
    days:[],
    recurringRideEndDate:null
  };

  selectedJourneyDetailsStep = "";
  
    setSelectedJourneyDetailsStep(step){
      this.selectedJourneyDetailsStep = step;
    }

    getSelectedJourneyDetailsStep(){
      return this.selectedJourneyDetailsStep;
    }

     getCreateRideNavigationSteps(selectedRideType:RideType){
      
      let stepTitles = Object.keys(this.createRideAllSteps);
      let createRideSteps = [];
      
      if(!selectedRideType.isRecurring){
        let newStepTitles = stepTitles.filter(title=>title != 'WEEK_SCHEDULE');
        createRideSteps = newStepTitles.map(steps=>this.createRideAllSteps[steps]);
      }
      else{
        createRideSteps = stepTitles.map(steps=>this.createRideAllSteps[steps]);
      }
      
      return createRideSteps;
     } 


     setSelectedRideType(rideType){
      this.createRideApiDto.rideType = rideType;
     }

     getSelectedRideType(){
      return this.createRideApiDto.rideType;
     }

     isRentalRide(){
      return this.createRideApiDto.rideType.split("_")[1] == 'RENTALS'  ? true : false;
     }

     setIsExpressRide(isExpress){
      this.isExpressRide = isExpress;
     }

     getIsExpressRide(){
      return this.isExpressRide;
     }

     setPaymentMode(paymentMode){
      this.createRideApiDto.paymentMode = paymentMode;
     }

     setCreateRideRequestApiDto(){
     let x  = new Promise((resolve, reject)=>{
      let customerDetails = Object.assign({}, this._customer.getCustomerDetailsToRequestRide());
      let journeyDetails = Object.assign({}, this._journey.getJourneyDetailsToRequestRide());
    
      this.setRideRequestDto(customerDetails, journeyDetails).then((res)=>{
        this.sendDropLocationToStop(); 
      });      
      return resolve(true);
     });

     return x;
     }


     setRideRequestDto(customerDetails, journeyDetails){
      let x = new Promise((resolve, reject)=>{
        this.createRideApiDto.callingCode = customerDetails.callingCode;
        this.createRideApiDto.dropLat = journeyDetails.dropLat;
        this.createRideApiDto.dropLocation = journeyDetails.dropLocation;
        this.createRideApiDto.dropLong = journeyDetails.dropLong;
        this.createRideApiDto.email = customerDetails.email;
        this.createRideApiDto.firstName = customerDetails.firstName;
        this.createRideApiDto.lastName = customerDetails.lastName;
        this.createRideApiDto.map = journeyDetails.map;
        this.createRideApiDto.otherFlags = journeyDetails.otherFlags;
        this.createRideApiDto.phoneNumber = customerDetails.phoneNumber;
        this.createRideApiDto.pickupLat = journeyDetails.pickupLat;
        this.createRideApiDto.pickupLocation = journeyDetails.pickupLocation;
        this.createRideApiDto.pickupLong = journeyDetails.pickupLong;
        this.createRideApiDto.pickupNotes = journeyDetails.pickupNotes;
        this.createRideApiDto.platform = journeyDetails.platform;
        this.createRideApiDto.priceMapId = journeyDetails.priceMapId;
        this.createRideApiDto.rideType = this.createRideApiDto.rideType;
        this.createRideApiDto.startTime = journeyDetails.startTime;
        this.createRideApiDto.stops = [];
        this.createRideApiDto.days=journeyDetails.days;
        this.createRideApiDto.recurringRideEndDate=journeyDetails.recurringRideEndDate;
        this.createRideApiDto.stops = this._selectRideTypeDetails.getIsMultiStopRide() ? [...journeyDetails.stops, this.sendDropLocationToStop()] : [...journeyDetails.stops];
        this.createRideApiDto.packageCode = journeyDetails.packageCode;
        this.createRideApiDto.rideSubCategory = this._journey.getSelectedCarVariantCategory() == '' ? null : journeyDetails.rideSubCategory;

        return resolve(true);
      });

      return x;
     }


     clearCreateRideAllSteps(){
      this.createRideAllSteps = {
        CUSTOMER_DETAILS: {label: 'Customer Details', isComplete: false, isActive: false, caption: 'Enter the rider details', isDisabled: true},
        JOURNEY_DETAILS: {label: 'Journey Details', isComplete: false, isActive: false, caption: 'Enter pickup/drop and other details regarding your ride', isDisabled: true},
        WEEK_SCHEDULE: {label: 'Week Schedule', isComplete: false, isActive: false, caption: 'Set recurring week schedule', isDisabled: true},
        PAYMENT_DETAILS: {label: 'Payment Details', isComplete: false, isActive: false, caption: 'Review the details and proceed to pay', isDisabled: true}
        };
     }

     clearRideRequestDto(){
      this.createRideApiDto= {
        callingCode: '',
        dropLat: null,
        dropLocation: '',
        dropLong: null,
        email: '',
        firstName: '',
        lastName: '',
        map: '',
        otherFlags: null,
        paymentMode: 'BUSINESS_ACCOUNT',
        phoneNumber: '',
        pickupLat: null,
        pickupLocation: '',
        pickupLong: null,
        pickupNotes: null,
        platform: 'WEB',
        priceMapId: null,
        rideType: '',
        startTime: null,
        stops: [],
        rideSubCategory: null,
        packageCode: null,
        days:[],
        recurringRideEndDate:null
      };

      // this.isAirportRide = false;
      this.isExpressRide = false;
      this.selectedJourneyDetailsStep = "";

      this._customer.clearCustomerDetails();
      this._journey.clearJourneyDetails();
     }

     sendDropLocationToStop(){
      let newDropLocationDto = {
        lat: this.createRideApiDto.dropLat,
        lng: this.createRideApiDto.dropLong,
        place: this.createRideApiDto.dropLocation,
        position: this.createRideApiDto.stops.length + 1
      };

      return newDropLocationDto;
      // this.createRideApiDto.stops.push(newDropLocationDto);
      // this._journey.setIsDropAddedInStop(true);
      // this._journey.setStops(this.createRideApiDto.stops);
     }

     getCreateRideRequestApiDto(){
      return this.createRideApiDto;
     }

     setSubCategory(category){
      this.createRideApiDto.rideSubCategory = category;
     }

     getSubCategory(){
      return this.createRideApiDto.rideSubCategory;
     }


     // ALTER VALUE OF createRideAllSteps
     alterCreateRideStepData(activeStep, field, value){
      Object.keys(this.createRideAllSteps).forEach(step=>{
        if(this.createRideAllSteps[step]['label'] == activeStep){
          this.createRideAllSteps[step][field] = value;
        }
        else{
          return 
        }
      })
     }

}
