<div class="modal-md" style="z-index: 1200 !important;" [ngStyle]="{display: triggerSharedRideFlow ? 'none' : 'block'}">
    <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true" style="color: #3E4958;"><i class="far fa-times-circle"></i></span>
      </button>
    </div>
    <div style="color: #3E4958; font-size: 24px;font-weight: bold;margin-left: auto !important;padding: 5px;text-align: center;align-items: center; ">
      <div>
        <span style="color: #3E4958; ">Create Ride</span>
      </div>
    </div>
    <div class="modal-body">
      <div class="container userDetails">
  
        <div style="margin: 5px;" (click)="toggleCustomerInfoForm()">
          <span>Customer Details</span>
  
          <span style="margin-left: auto; margin-right: 0; float: right;color: #2C66E3;" >
            <i class="fas fa-chevron-down" *ngIf="!showCustomerInfoForm" ></i>
              <i class="fas fa-chevron-up" *ngIf="showCustomerInfoForm" ></i>
            </span>
        </div>
        <div *ngIf="showCustomerInfoForm" style="display: flex;">
          <br />
  
          <form [formGroup]="registerFrom" (ngSubmit)="onSubmit()" style="width: 70%;">
  
            <div>
              <br/>
              <span class="p-float-label">
              <div class="phoneContainer" [ngClass]="{ 'is-invalid': submitted && h.phoneNo.errors }" >
              <p-dropdown [options]="countryCodes" [(ngModel)]="selectedCountryCode" filter="true" group="true" [ngModelOptions]="{standalone: true}"  
                            filterPlaceholder="Select Country" [disabled]="disabledPhone" (onChange)="clearCustomerDetails()">
                                <ng-template let-item pTemplate="selectedItem">
                                  <span style="font-size: 19.4px;">{{item.value.flag}}</span>
                                </ng-template>
                    
                                <ng-template let-countries pTemplate="items">
                                    <div class="p-helper-clearfix" style="position: relative;height: 25px; padding:1rem; display: flex; align-items: center;">
                                        <span style="position:absolute;top:2px;left:5px; font-size: 18px;">{{countries.value.flag}}</span>
                                        <div style="font-size:14px;margin:0rem 3rem">{{countries.label}} : {{countries.value.code}}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                      <span style="font-size: 1rem; color: #3E4958 !important; ">{{selectedCountryCode.code}}</span>
                
                
                <input pInputText type="tel" class="form-control customFormInput" [maxlength]="selectedCountryCode.limit" [minlength]="selectedCountryCode.limit"
                (keydown.enter)="$event.preventDefault()" id="phoneno"
                formControlName="phoneNo" [pattern]="selectedPhonePattern" (keyup)="getUserInfo()"/>
                <label for="phoneno" style="color: #2C66E3;">Phone No.</label>
            </div>
            </span>
              <div class="text-warning" *ngIf="isErrorPhone">
                <div>
                  Please add valid phone no.
                </div>
              </div>
            </div>
        
            <div class="continue-as-admin-container" *ngIf="this.businessDetails.getBaseLocationDetails()?.businessSpecs == 'HOTEL'">
              <p-checkbox [(ngModel)]="countinueAsAdmin" [ngModelOptions]="{standalone : true}" [binary]="true" inputId="binary" (click)="startAsAdmin()"></p-checkbox>
              <span>Continue As Admin</span>
            </div>

            <br/>
            <div>
              <span class="p-float-label">
              <!-- <sub style="color: #2C66E3;">First Name</sub> -->
              <input pInputText type="text" class="form-control customFormInput"
              formControlName="firstName" [ngClass]="{ 'is-invalid': submitted && h.firstName.errors }"
                (keydown.enter)="$event.preventDefault()" id="firstName" />
                <label for="firstName" style="color: #2C66E3;">First Name</label>
              </span>
                <div class="text-warning" *ngIf="h.firstName.errors && submitted">
                  <div>
                    First Name is required
                  </div>
                </div>
            </div>
  
            

            <br/>
            <div>
              <!-- <sub style="color: #2C66E3;">Last Name</sub> -->
              <span class="p-float-label">
              <input pInputText type="text" class="form-control customFormInput"
              (keydown.enter)="$event.preventDefault()" formControlName="lastName"
              [ngClass]="{ 'is-invalid': submitted && h.lastName.errors }"/>
              <label for="lastName" style="color: #2C66E3;">Last Name</label>
            </span>
            </div>
  
            <br/>
            <div>
              <!-- <sub style="color: #2C66E3;">Email Address</sub> -->
              <span class="p-float-label">
              <input pInputText type="email" class="form-control customFormInput"   formControlName="email"
              (keydown.enter)="$event.preventDefault()" [ngClass]="{ 'is-invalid': submitted && h.email.errors }" id="email"/>
              <label for="email" style="color: #2C66E3;">Email Address</label>
            </span>
                <div class="text-warning" *ngIf="submitted && h.email.errors">
                  <div *ngIf="submitted && h.email.errors.required">Email is required</div>
                  <div *ngIf="submitted && h.email.errors.email">
                    Email must be a valid email address
                  </div>
                </div>
              </div>
            <br/>
          </form>
  
            <div class="shared-btn-wrapper" style="width: 30%;align-items: center;text-align: center;" *ngIf="isShowSharedRide">
                <img *ngIf="isShowSharedNew" class="shared-btn" src="assets/new-tag.png">
                <button type="button" *ngIf="isShowShared"  class="btn btn-primary" (click)="openSharedRideModal()" style="background: #2C66E3;font-size: 14px;padding:3px 10px;border-radius: 7px;">Create shared ride</button>
            </div>
  
            

        </div>
  
      </div>
  
      <br/>
      <!-- Ride Details form starts  -->
      <div class="container userDetails">
  
        <div style="margin: 5px;" (click)="toggleRideInfoForm()">
          <span>Journey Details</span>
          <span style="margin-left: auto; margin-right: 0; float: right;color: #2C66E3;" >
            <i class="fas fa-chevron-down" *ngIf="!showRideInfoForm" ></i>
              <i class="fas fa-chevron-up" *ngIf="showRideInfoForm" ></i>
            </span><br/>
            <span class="note" *ngIf="passengerNote">Note: A max of 3 passengers can be comfortably seated in a BluSmart cab</span>
  
        </div>
        <!-- *ngIf="showRideInfoForm" -->
        <div style="width: 100%;" [ngClass]="{hideForm: !showRideInfoForm}" class="rideInforForm">
          <br />
          <form #f2="ngForm">
            
            <div style="width: 50%;" class="ridetype-container">
              <p-dropdown [options]="rideTypeList"  pInputText name="rideType" class="form-control customFormInputDropdown"  [(ngModel)]="rideType"  ngModel required #r="ngModel" (onChange)="rideTypeChange()"> </p-dropdown>
              <div class="text-warning" *ngIf="r.touched && r.invalid">
                Ride type is required
              </div>
            </div>
            <br/>
            <!-- <br *ngIf="rideType == 'SCHEDULED_RENTALS' "/> -->
            <div class="locationContainer" [ngStyle]="{height: showLandmark? '16vh' : '3vh'}">
              <span class="p-float-label">
              <input pInputText type="text" class="customFormInput" placeholder="" name="from" style="border-style: none none solid none;"
              [(ngModel)]="pickupLocation" autocorrect="off" autocapitalize="off" spellcheck="off" required #from
              (keydown.enter)="$event.preventDefault()"  (ngModelChange)="resetLatLong('from')" (click) = "resetScreenToDispalyMap()"/>
              <label for="from" style="color: #2C66E3;">From</label>
            </span>
  
            <span class="p-float-label" *ngIf="showLandmark">
              <p-dropdown [options]="landmarks" [(ngModel)]="selectedLandmark" [ngModelOptions]="{standalone:true}" placeholder="landmark" optionLabel="name"  class="landmarkDropDown"></p-dropdown>
            </span>
            
  
            <div class="text-warning" *ngIf="from.touched && from.invalid">
                Pickup Location is Required
            </div>
            </div>
            <span style="font-size: 13px;float:right;margin:2px;color: #2C66E3;" *ngIf="rideType == 'SCHEDULED_RIDE' && 2>multiStops.length" (click)="addNewMultiStop()">Add Stop <i
              style="margin:2px" class="fa fa-plus-circle" aria-hidden="true" ></i></span><br />
              <div style="width: 100%;">
  
                <div *ngFor="let items of multiStops; let i = index ">
                  <app-g-place-input [stopObject]="multiStops[i]" [index]="i+1" (resetForm)="resetScreenToDispalyMap($event)">
                  </app-g-place-input>
                  <span style="font-size: 13px;float:right;margin-top:-20px; float: right;color: red;" (click)="removeMultiStop(i)"
                    >Remove Stop <i class="fa fa-minus-circle" aria-hidden="true"
                      style="margin:2px;"></i></span> <br />
                </div>
              </div>
            <br/>
  
            <div style="width: 70%;" [ngClass]="{hidediv: rideType == 'SCHEDULED_RENTALS'}">
              <!-- <sub style="color: #2C66E3;">Dropoff Location</sub> -->
              <span class="p-float-label">
              <input pInputText  type="text" class="form-control customFormInput"
                [(ngModel)]="dropoffLocation" placeholder="" id="to" name="to" autocorrect="off" autocapitalize="off" style="border-style: none none solid none;"
                spellcheck="off" [required]="rideType == 'SCHEDULED_RIDE'" #to (keydown.enter)="$event.preventDefault()" (ngModelChange)="resetLatLong('to')"
                (click)= "resetScreenToDispalyMap()"/>
                <label for="from" style="color: #2C66E3;">To</label>
              </span>
                <div class="text-warning" *ngIf="to.touched && to.invalid">
                  Dropoff Location is Required
                </div>
            </div>
  
            <div style="width: 100%;" *ngIf="rideType == 'SCHEDULED_RENTALS'">
              <span style="font-size: 13px;float:right;margin:2px;color: #2C66E3;" (click)="addNewStop()">Add Stop <i
                  style="margin:2px" class="fa fa-plus-circle" aria-hidden="true"></i></span><br />
              <div *ngFor="let items of stops; let i = index ">
                <app-g-place-input [stopObject]="stops[i]" [index]="i+1" (resetForm)="resetScreenToDispalyMap($event)">
                </app-g-place-input>
                <span style="font-size: 13px;float:right;margin-top:-20px; float: right;color: red;" (click)="removeStop(i)"
                  *ngIf="stops.length>0">Remove Stop <i class="fa fa-minus-circle" aria-hidden="true"
                    style="margin:2px;"></i></span> <br />
              </div>
            </div>
            <br/>
  
            <div style="width: 50%;" *ngIf="rideType == 'SCHEDULED_RENTALS' && packageList.length > 0 ">
              Select Package <br/>
              <p-dropdown [options]="packageList" [(ngModel)]="rentalPackage" name="rentalPackage" class="form-control customFormInputDropdown" ngModel required #rp="ngModel" (onChange)="getCategoryByPackageCode(rentalPackage)" ></p-dropdown>
              <div class="text-warning" *ngIf="rp.touched && rp.invalid">
                Rental Package is required
              </div>
              </div>

              <br/>

            <div *ngIf="rideType == 'SCHEDULED_RENTALS' && packageList.length > 0">
              Select Type <br/>
              <div style="display: flex;">
                  <div *ngIf="classicMap" class="car-type-class" [ngClass]="{'car-type-class-active': selectedCategory==='CLASSIC'}" (click)="categoryChangeEvent('CLASSIC')" >
                    <div class="row">
                      <div class="col"><img src="assets/bluClassic.png"  *ngIf="appService.getCountry() == 'IN'">
                                       <img style="width: 5rem;" src="assets/uae_varient.png" *ngIf="appService.getCountry() == 'AE'" >
                      </div>
                      <div class="col-4" style="font-size: 12px;padding: 0px;">
                       {{(appService.getCountry() == "IN") ?  "Blu Classic" : "Audi e-Tron"}}<br/>
                        <span style="color:#979797;">{{(appService.getCountry() == "IN") ?  "Sedan" : "Premium SUV"}}</span>
                      </div>
                      <div class="col" style="padding: 0px;">
                       <span style="color:#1DB31D;">{{classicMap.basePrice | currencySymbol}}</span>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="premiumMap" class="car-type-class" style="margin-left: 10px;" [ngClass]="{'car-type-class-active': selectedCategory==='PREMIUM'}" (click)="categoryChangeEvent('PREMIUM')">
                    <div class="row">
                      <div class="col"> <img src="../../../../../assets/bluPremium.png"/> </div>
                      <div class="col-4"  style="font-size: 12px;padding: 0px;">
                        Blu Premium<br/>
                        <span style="color:#979797;">SUV (MG ZS & more)</span>
                      </div>
                      <div class="col" style="padding: 0px;">
                        <span style="color:#1DB31D;">{{premiumMap.basePrice | currencySymbol}}</span>
                      </div>
                    </div>
                  </div>
              </div>
              <br/>
            </div>
  
  
            <div style="width: 100%;display: flex;flex-direction: row;justify-content:space-between;">
              <div style="width: 45%;">
                <!-- <select class="form-control customFormInput" pInputText ngModel name="paymentType" [(ngModel)]="paymentType" ngModel required
                #pt="ngModel">
                <option *ngFor="let element of paymentModeList" [value]="element.value">
                  {{element.label}}
                </option>
              </select> -->
              <p-dropdown class="form-control customFormInputDropdown" [options]="paymentModeList"  name="paymentType" [(ngModel)]="paymentType" ngModel required  placeholder="Payment Mode"
              #pt="ngModel"></p-dropdown>
              </div>
  
             <div  *ngIf = "selectedCountry == 'AE'">
              <div style="width: 45%;" *ngIf="!slotOpenFlag">
                <p-calendar [(ngModel)]="startTime" [minDate]="minDateForNormalRide" [maxDate]="maxDate" [readonlyInput]="true" [showTime]="true" [stepMinute]="stepCount"
                name="startTime" [inputStyle]="{'width': '195px','border': 'none','border-bottom' : '1px solid #E5E7EB','margin-top':'5px'}" appendTo="body" ></p-calendar>
              </div>

              <div style="width: 56%; display: flex;" *ngIf="slotOpenFlag && stepCount == 15" >
                <p-dropdown class="form-control customFormInputDropdown minWidthAuto" [options]="dateList"  name="date" [(ngModel)]="selectedDate" ngModel required (onChange)="selectSlot(selectedDate)" [ngStyle]="{width: '142px'}" placeholder="Date"
                 #pt="ngModel"></p-dropdown>
              <p-dropdown class="form-control customFormInputDropdown minWidthAuto" [options]="slotList"  name="slot" [(ngModel)]="selectedSlot" ngModel required [ngStyle]="{width: '100px'}"  placeholder="Time"
              #pt="ngModel"></p-dropdown>
              </div>
  
              <div style="width: 56%; display: flex;" *ngIf="!slotOpenFlag && stepCount == 1" >
                <p-calendar [(ngModel)]="startTime" [minDate]="minDateForNormalRide" [maxDate]="maxDate" [readonlyInput]="true" [showTime]="true"  [stepMinute]="stepCount"
                name="startTime" [inputStyle]="{'width': '195px','border': 'none','border-bottom' : '1px solid #E5E7EB','margin-top':'5px'}" appendTo="body" ></p-calendar>
              </div>
             </div>

             <div *ngIf = "selectedCountry == 'IN'">
              <div style="width: 45%;" *ngIf="selectedCategory == null || ! (selectedCategory=='PREMIUM')">
                <p-calendar [(ngModel)]="startTime" [minDate]="minDateForNormalRide" [maxDate]="maxDate" [readonlyInput]="true" [showTime]="true" [stepMinute]="stepCount"
                name="startTime" [inputStyle]="{'width': '195px','border': 'none','border-bottom' : '1px solid #E5E7EB','margin-top':'5px'}" appendTo="body" ></p-calendar>
              </div>
              <div style="width: 56%; display: flex;" *ngIf="selectedCategory=='PREMIUM' && stepCount == 15" >
                <p-dropdown class="form-control customFormInputDropdown minWidthAuto" [options]="dateList"  name="date" [(ngModel)]="selectedDate" ngModel required (onChange)="selectSlot(selectedDate)" [ngStyle]="{width: '142px'}" placeholder="Date"
                 #pt="ngModel"></p-dropdown>
              <p-dropdown class="form-control customFormInputDropdown minWidthAuto" [options]="slotList"  name="slot" [(ngModel)]="selectedSlot" ngModel required [ngStyle]="{width: '100px'}"  placeholder="Time"
              #pt="ngModel"></p-dropdown>
              </div>
  
              <div style="width: 56%; display: flex;" *ngIf="selectedCategory=='PREMIUM' && stepCount == 1" >
                <p-calendar [(ngModel)]="startTime" [minDate]="minDateForNormalRide" [maxDate]="maxDate" [readonlyInput]="true" [showTime]="true"  [stepMinute]="stepCount"
                name="startTime" [inputStyle]="{'width': '195px','border': 'none','border-bottom' : '1px solid #E5E7EB','margin-top':'5px'}" appendTo="body" ></p-calendar>
              </div>

             </div>
  
            </div>
            <br/>
           <div class="reasons-container" style="border: 1px solid #D8E3F2; width: 90%; border-radius: 1rem; padding: 1rem;">
            <label for="businessRideReason" style="color: #2C66E3;">Business Ride Reason<sup *ngIf="businessRideReasonDetails && businessRideReasonDetails['isMandatory']">*</sup></label><br>
            <span *ngIf="isDropDownBusinessRideReasonEnabled">
            <p-dropdown [options]="businessRideReasonDetails['businessRideReasonList']" [(ngModel)]="selectedBusinessRideReason"  optionLabel="name" placeholder ="Select Business Ride Reason" class="form-control customFormInputDropdown"  [filter]="true" filterBy="name"
            (onChange)="checkBusinessRideReason()" appendTo="body"></p-dropdown>
            </span>

            <br *ngIf="isTextBusinessRideReasonEnabled">

            <span class="p-label" *ngIf="isTextBusinessRideReasonEnabled">
            <input pInputText  type="text" class="form-control customFormInput" name="businessRideReason" [(ngModel)]="otherBusinessRideReason" placeholder="Enter additional comments">
            <!-- <label for="businessRideReason" style="color: #2C66E3;">Enter additional comments</label> -->
            </span>

            <span style="color: tomato; font-size: 14px;" *ngIf="isBusinessRideReasonEmpty">Business Ride Reason is Mandatory</span>
           </div>
           
           <br/>
           <div class="reasons-container" style="border: 1px solid #D8E3F2; width: 90%; border-radius: 1rem; padding: 1rem;" *ngIf="expenseCodeDetails && expenseCodeDetails['isEnabled']">
            <label for="expenseCode" style="color: #2C66E3;">Expense Code<sup *ngIf="expenseCodeDetails && expenseCodeDetails['isMandatory']">*</sup></label><br>
            <span *ngIf="isDropDownExpenseCodeEnabled">
            <p-dropdown [options]="expenseCodeDetails['expenseCodeList']" [(ngModel)]="selectedExpenseCode" optionLabel="name" class="form-control customFormInputDropdown"  placeholder ="Select Expense Code" [filter]="true" filterBy="name"
            (onChange)="checkExpenseCode()" appendTo="body"></p-dropdown>
            </span>
            <br *ngIf="isTextExpenseCodeEnabled">
            <span class="p-label" *ngIf="isTextExpenseCodeEnabled">
            <input pInputText  type="text" class="form-control customFormInput" name="expenseCode" [(ngModel)]="otherExpenseCode" placeholder="Enter additional comments">
            <!-- <label for="expenseCode" style="color: #2C66E3;">Enter additional comments</label> -->
            </span>

            <span style="color: tomato; font-size: 14px;" *ngIf="isExpenseCodeEmpty">Expense Code is Mandatory</span>

           </div>
  
            <br/>
            <div style="width: 80%;">
              <span class="p-float-label">
                  <textarea pInputTextarea class="form-control customFormInput"
                  [(ngModel)]="pickupNotesMessage" placeholder="" id="pickupNotesMessage" name="pickupNotesMessage" autocorrect="off" autocapitalize="off"
                  spellcheck="off"  ngModel  #pnm="ngModel"></textarea>
                  <label for="from" style="color: #2C66E3;">Pickup Note</label>
                </span>
  
            </div>
  
            <br/>
            <div style="width: 100%;align-items: right;text-align: right;">
              <button type="button" class="btn btn-primary" [disabled]="f2.invalid" (click)="SubmitJourneyDetails()" style="width: 20%;background:#2C66E3;float: right;">Next</button>
               <br/>
            </div>
            <br/>
  
          </form>
        </div>
  
      </div>
      <!-- Ride details form ends  -->
      <br/>
      <!-- Payment Details Starts  -->
      <div class="container userDetails">
  
        <div style="margin: 5px;" (click)="togglePaymentInfoForm()">
          <span>Payment Details</span>
          <span style="margin-left: auto; margin-right: 0; float: right;color: #2C66E3;" >
            <i class="fas fa-chevron-down" *ngIf="!showPaymentInfoForm" ></i>
              <i class="fas fa-chevron-up" *ngIf="showPaymentInfoForm" ></i>
            </span>
        </div>
        <div style="width: 100%;" *ngIf="showPaymentInfoForm">
          <br />
          <form [formGroup]="registerFrom" (ngSubmit)="onSubmit()">
  
            <!-- <div>
             <div >
                <span class="p-float-label">
              <input pInputText type="text" class="form-control customFormInput"  placeholder="" #couponCode
              (keydown.enter)="$event.preventDefault()"  style="width: 80%;" disabled='true' id="coupon"/>
              <label for="coupon" style="color: #2C66E3;">Coupon Code</label>
              </span>
              </div>
              <span style="font-size: 13px;float:right;margin-top:-30px; cursor: pointer; background: #2D66E3; border: 1px solid #2D66E3; border-radius: 5px;color:white;padding: 3px;">Apply Coupon</span>
            </div>
             <br/>
            <div>
              <span style="color: #3E4958;">Price: {{currencySymbol}}{{priceWithoutCoupon.pricingDetails?priceWithoutCoupon.pricingDetails.estimatedTotalAmount:"-"}}</span>
              <span style="color: #3E4958;margin-left: 20%;">Discount: {{currencySymbol}}- </span>
            </div>
              <br/> -->
            <div>
              <span style="color: #3E4958;">Payment Amount :{{(priceWithoutCoupon.pricingDetails?priceWithoutCoupon.pricingDetails.estimatedTotalAmount:"-") | currencySymbol}}</span>
  
              </div>
            <br/>
          </form>
        </div>
  
      </div><br/>
  
      <!-- Payment Details Ends  -->
  
      <!-- TnC -->
      <div *ngIf="rideType == 'SCHEDULED_RENTALS'" class="tncContainer">
  
        <input type="checkbox" name="tnc" id="tncCheck" (change)="toggleTnC()" [(ngModel)]="isTncChecked" *ngIf = 'toggleTnc'>
        <div class="tncSubContainer" [ngStyle]="{display: toggleTnc? 'flex' : 'none'}">
        <div #tncSubContainer ></div>
        <span *ngIf="countryBasedFeatureList['tncExpand']"><a routerLink='/info' target="_blank" routerLinkActive='router-link-active'>click here</a></span>
        </div>
  
      </div>
  
      <!-- Book Ride Button -->
  
      <br/>
      <div style="width: 100%;align-items: center;text-align: center;">
        <button type="button" class="btn btn-primary" (click)="bookRide()" [disabled]="disableBookRide"style="width: 80%; background:#2C66E3">Book Ride</button>
  
      </div>
      <br/>
    </div>
  </div>
  
  <!-- Dialog to display Confirm Message  -->
  <!--
  <p-dialog  [(visible)]="displayConfirmMessage">
    <div style="height: 350px;width: 452px;display: flex;flex-direction: column;align-items: center;text-align: center;padding: 10%;">
      <div>
        <img src="../../../../../assets/confirmMessageIcon.png" alt="">
      </div>
      <br/>
      <div style="color: #5DCF5E;">
        Booking Confirmed
      </div>
      <br/>
      <div>
        <button type="button" class="btn btn-primary" style="width: 200px;" (click)="hideConfirmMessageDialog()">OK</button>
      </div>
    </div>
  </p-dialog> -->
  <div class="shared-ride-main-container" [ngStyle]="{display: triggerSharedRideFlow ? 'flex' : 'none'}">
    <div class="shared-ride-sub-container">
     <div class="shared-ride-header" style="margin-top: 3rem ;">
        <span style="color:#3E4958;font-weight:600;font-size: 20px">
            Create Shared Ride
        </span>
     </div>
  
  <!-- Shared Ride Starts -->
  
     <div class="shared-ride-body">
      <div class="journey-customer-detail">
            <span style="font-weight: 600;font-size:16px;font-family: 'Montserrat';">Journey and Customer Details</span>
            <p style="font-size: 10px;color: #3E4958;font-family: 'Mulish';">You can add upto {{sharedRideMaxAllowedRiders}} unique riders to/from upto {{sharedRideMaxAllowedRiders}} unique locations while creating a shared ride</p>
           
            <form [formGroup]="myForm" style="height: 50px;">
            <div style="display: flex;" *ngIf="!isSingleRideUpdate">
               
                <div class="field-radiobutton label_align" style="display: flex; align-items: center; margin-right: 1rem;">
                  <label class="pick-drop-label" style="margin-right: 0.5rem; margin-top: 0.2rem;">Pick up</label>
                  <p-radioButton name="myRadio" value="pickup" [disabled]="isTypeDisable" (onFocus)="checkValue()"
                   formControlName="myRadio" inputId="opt1"></p-radioButton>
                </div>
  
  
                <div class="field-radiobutton label_align" style="display: flex; align-items: center; ">
                  <label  class="pick-drop-label" style="margin-right: 0.5rem; margin-top: 0.2rem;">Drop off</label>
                  <p-radioButton name="myRadio" value="dropoff" (onFocus)="checkValue()" [disabled]="isTypeDisable" 
                  formControlName="myRadio" inputId="opt2"></p-radioButton>
                </div>
            </div>
            </form>
  
          <div class="pickup-ride-detail" [ngStyle]="{display: myForm.value.myRadio == 'pickup' ? 'flex' : 'none'}">
            <div class="ride-detail">
              <div class="icon-wrapper">
                <!-- <img src="assets/map-icon.png" class="map-icon" alt="map"> -->
                <img class="map-icon" src='{{ pickupLocation0 ? "assets/green-map-icon.png" : "assets/map-icon.png" }}' alt="map">
                <img *ngIf="riderList0.length > 0" src='{{ pickupLocation1 ? "assets/blu-path.png" : "assets/black-path.png" }}' class="path-icon" alt="path">
              </div>
              <div class="location-wrapper">
  
                <!-- <input pInputText type="text" class="form-control  customFormInput" style="padding-left: 15px;margin-left: 15px;" [(ngModel)]="pickupLocation0" [ngModelOptions]="{standalone: true}" placeholder="Pick up Location" >  -->
                <span class="p-float-label">
                  <input pInputText type="text" (input)="formChanged()" class="form-control customFormInput" placeholder="" name="pickupLocationDrop"
                  style="padding-left: 15px;margin-left: 15px;font-size: 12px; width: 24rem;"
                  [(ngModel)]="pickupLocation0"  autocorrect="off" autocapitalize="off" spellcheck="off" required #pickupLocationOne
                  (keydown.enter)="$event.preventDefault()"  (ngModelChange)="resetLatLong('pickupLocation0')" (click) = "resetScreenToDispalyMap()"/>
                  <label for="Pick up Location" style="color: #2C66E3;" style="font-size: 12px; padding-left: 15px;margin-left: 15px;font-family: 'Mulish';">Pick up Location</label>
                </span>
  
                <input pInputText type="text"  class="form-control customFormInput" style="padding-left: 15px;margin-left: 15px;font-size: 12px; width:96.3% !important" *ngIf="!blockStop(0)" [(ngModel)]="pickupNotes0" [ngModelOptions]="{standalone: true}" (input)="formChanged()" placeholder="Add Pick up notes" >
  
              </div>
              <div class="rider-wrapper">
               <div style="display: flex;flex-direction:row;gap: 14px;" *ngIf="!blockStop(0)">
                <input pInputText type="text" id="float-input" (keyup)="getUserInfoForSharedRide(riderPhone0,'one')" class="form-control  customFormInput add-rider-input" style="padding-left: 15px;margin-left: 3px;" [(ngModel)]="riderPhone0" [ngModelOptions]="{standalone: true}" placeholder="Rider Phone No." maxlength="10" minlength="10">
                <input pInputText type="text" class="form-control  customFormInput add-rider-input" style="padding-left: 15px;margin-left: 15px;" [(ngModel)]="riderName0" [ngModelOptions]="{standalone: true}" placeholder="Rider Name" >
                <input pInputText type="text" type="email" class="form-control  customFormInput add-rider-input" style="padding-left: 15px;margin-left: 15px;" [(ngModel)]="riderEmail0" [ngModelOptions]="{standalone: true}"  [ngClass]="{ 'is-invalid-email': isEmailInvalid0 }" (keyup)="validateEmail(riderEmail0,0)" placeholder="Rider Business Email" >
                <span (click)="addRider(0)">
                  <i class="fa fa-plus-circle" *ngIf="(riderList0.length + riderList2.length + riderList1.length + riderList3.length) < sharedRideMaxAllowedRiders" style="margin: auto;cursor: pointer;color:#1DB31D;"  aria-hidden="true"></i>
                </span>
                
               </div>
               <div>
  
                <div style="display: flex;flex-direction:row;" *ngFor="let rider of riderList0">
                  <img src="assets/rider.png" class="rider-icon"  style="position: relative;left: 0px;top: 20px;"alt="rider">
                  <div style="display: flex;flex-direction:row;gap: 11px;">
                    <input pInputText type="text" id="float-input" class="form-control  customFormInput added-rider" style="padding-left: 25px;margin-left: 0px;padding-top: 16px;" [(ngModel)]="rider.phone" [ngModelOptions]="{standalone: true}" placeholder="Rider Phone No." readonly="readonly">
                    <input pInputText type="text" class="form-control  customFormInput added-rider" style="padding-left: 15px;margin-left: 10px;padding-top: 16px;" [(ngModel)]="rider.firstName" [ngModelOptions]="{standalone: true}" placeholder="Rider Name" readonly="readonly">
                    <input pInputText type="text" class="form-control  customFormInput added-rider" style="padding-left: 15px;margin-left: 15px;padding-top: 16px;" [(ngModel)]="rider.email" [ngModelOptions]="{standalone: true}"  placeholder="Rider Business Email" readonly="readonly">
                    <span (click)="removeRider(rider,0)">
                      <i class="fa fa-times-circle" style="margin: auto;margin-left:0.2rem;cursor: pointer;color: #FF5050;" *ngIf="!blockStop(0)"  aria-hidden="true"></i>
                    </span>
                    
                  </div>
  
                 </div>
              </div>
  
              </div>
            </div>
            <div class="ride-detail" [ngStyle]="{display: (riderList0.length < sharedRideMaxAllowedRiders && riderList0.length >= 1) || isModifyStop || isLiveModifyStop || !disableHidingStops ? 'flex' : 'none'}">
              <div class="icon-wrapper">
                <img src='{{ pickupLocation1 ? "assets/blu-map-icon.png" : "assets/map-icon.png" }}' class="map-icon" alt="map">
                <img *ngIf="riderList1.length > 0" src='{{ pickupLocation2 ? "assets/blu-path.png" : "assets/black-path.png" }}' class="path-icon" alt="path">
              </div>
              <div class="location-wrapper">
                <!-- <input pInputText type="text" class="form-control  customFormInput" style="padding-left: 15px;margin-left: 15px;" [(ngModel)]="pickupLocation1" [ngModelOptions]="{standalone: true}" placeholder="Pick up Location" >  -->
                <span class="p-float-label">
                  <input pInputText type="text" [disabled]="blockStop(1)" class="form-control customFormInput" placeholder="" name="pickupLocation1"
                  style="padding-left: 15px;margin-left: 15px;font-size: 12px; width: 24rem;" (input)="formChanged()"
                  [(ngModel)]="pickupLocation1" autocorrect="off" autocapitalize="off" spellcheck="off" required #pickupLocationTwo
                  (keydown.enter)="$event.preventDefault()"  (ngModelChange)="resetLatLong('pickupLocation1')" (click) = "resetScreenToDispalyMap()"/>
                  <label for="Pick up Location" style="color: #2C66E3;" style="font-size: 12px;padding-left: 15px;margin-left: 15px;font-family: 'Mulish';">Next Stop</label>
                </span>

                <input pInputText type="text" class="form-control customFormInput" *ngIf="!blockStop(1)" style="padding-left: 15px;margin-left: 15px;font-size: 12px; width:96.3% !important" [(ngModel)]="pickupNotes1" [ngModelOptions]="{standalone: true}" (input)="formChanged()" placeholder="Add Pick up notes" >
               </div>

              <div class="rider-wrapper">
               <div style="display: flex;flex-direction:row;gap: 11px;" *ngIf="!blockStop(1)">
                <input pInputText type="text" id="float-input" (keyup)="getUserInfoForSharedRide(riderPhone1,'two')" class="form-control  customFormInput add-rider-input" style="padding-left: 15px;margin-left: 10px;" [(ngModel)]="riderPhone1" [ngModelOptions]="{standalone: true}" placeholder="Rider Phone No." maxlength="10" minlength="10" >
                <input pInputText type="text" class="form-control  customFormInput add-rider-input" style="padding-left: 15px;margin-left: 15px;" [(ngModel)]="riderName1" [ngModelOptions]="{standalone: true}" placeholder="Rider Name" >
                <input pInputText type="text" class="form-control  customFormInput add-rider-input" style="padding-left: 15px;margin-left: 15px;" [(ngModel)]="riderEmail1" [ngClass]="{ 'is-invalid-email': isEmailInvalid1 }" (keyup)="validateEmail(riderEmail1,1)" [ngModelOptions]="{standalone: true}" placeholder="Rider Business Email" >
                <span (click)="addRider(1)">
                  <i class="fa fa-plus-circle"  *ngIf="(riderList0.length + riderList2.length + riderList1.length + riderList3.length) < sharedRideMaxAllowedRiders" style="margin: auto;cursor: pointer;color:#1DB31D;"  aria-hidden="true"></i>
                </span>
                
               </div>
  
               <div>
  
                <div style="display: flex;flex-direction:row; justify-content: space-between;" *ngFor="let rider of riderList1">
                  <img src="assets/rider.png" class="rider-icon"   style="position: relative;left: 0px;top: 20px;" alt="rider">
               <div style="display: flex;flex-direction:row;gap: 11px;">
                <input pInputText type="text" id="float-input" class="form-control  customFormInput added-rider" style="padding-left: 25px;margin-left: 0px;padding-top: 16px;" [(ngModel)]="rider.phone" [ngModelOptions]="{standalone: true}" placeholder="Rider Phone No." readonly="readonly">
                <input pInputText type="text" class="form-control  customFormInput added-rider" style="padding-left: 15px;margin-left: 10px;padding-top: 16px;" [(ngModel)]="rider.firstName" [ngModelOptions]="{standalone: true}" placeholder="Rider Name" readonly="readonly">
                <input pInputText type="text" class="form-control  customFormInput added-rider" style="padding-left: 15px;margin-left: 15px;padding-top: 16px;" [(ngModel)]="rider.email" [ngModelOptions]="{standalone: true}" placeholder="Rider Business Email" readonly="readonly">
                <span (click)="removeRider(rider,1)">
                  <i class="fa fa-times-circle" style="margin: auto;cursor: pointer;color: #FF5050;" *ngIf="!blockStop(1)" aria-hidden="true"></i>
                </span>
                
               </div>
  
                 </div>
              </div>
  
              </div>
            </div>
            <div class="ride-detail" [ngStyle]="{display: (riderList0.length < sharedRideMaxAllowedRiders - 1 && riderList1.length < sharedRideMaxAllowedRiders - 1 && riderList1.length >= 1)  || isModifyStop || isLiveModifyStop || !disableHidingStops ? 'flex' : 'none'}">
              <div class="icon-wrapper">
                <img src='{{ pickupLocation2 ? "assets/blu-map-icon.png" : "assets/map-icon.png" }}'  class="map-icon" alt="map">
                <img *ngIf="isShowDrop && !isLiveModifyStop" src='{{ dropLocation ? "assets/blu-path.png" : "assets/black-path.png" }}' class="path-icon" alt="path">
  
              </div>
              <div class="location-wrapper">
                <!-- <input pInputText type="text" class="form-control  customFormInput" style="padding-left: 15px;margin-left: 15px;" [(ngModel)]="pickupLocation2" [ngModelOptions]="{standalone: true}" placeholder="Pick up Location" >  -->
                <span class="p-float-label">
                  <input pInputText type="text" class="form-control customFormInput" placeholder="" name="pickupLocation2"
                  style="padding-left: 15px;margin-left: 15px;font-size: 12px; width: 24rem;" (input)="formChanged()"
                  [(ngModel)]="pickupLocation2" [disabled]="blockStop(2)" autocorrect="off" autocapitalize="off" spellcheck="off" required #pickupLocationThree
                  (keydown.enter)="$event.preventDefault()"  (ngModelChange)="resetLatLong('pickupLocation2')" (click) = "resetScreenToDispalyMap()"/>
                  <label for="Pick up Location" style="color: #2C66E3;" style="font-size: 12px;padding-left: 15px;margin-left: 15px;font-family: 'Mulish';">Next Stop</label>
                </span>
                <input pInputText type="text" class="form-control customFormInput" *ngIf="!blockStop(2)" style="padding-left: 15px;margin-left: 15px;font-size: 12px; width:96.3% !important" [(ngModel)]="pickupNotes2" (input)="formChanged()" [ngModelOptions]="{standalone: true}" placeholder="Add Pick up notes" >
               </div>
              <div class="rider-wrapper">
               <div style="display: flex;flex-direction:row;gap: 11px;" *ngIf="!blockStop(2)">
                <input pInputText type="text" id="float-input" class="form-control  customFormInput add-rider-input" (keyup)="getUserInfoForSharedRide(riderPhone2,'three')" style="padding-left: 15px;margin-left: 10px;" [(ngModel)]="riderPhone2" [ngModelOptions]="{standalone: true}" placeholder="Rider Phone No."  maxlength="10" minlength="10">
                <input pInputText type="text" class="form-control  customFormInput add-rider-input" style="padding-left: 15px;margin-left: 15px;" [(ngModel)]="riderName2" [ngModelOptions]="{standalone: true}" placeholder="Rider Name" >
                <input pInputText type="text" class="form-control  customFormInput add-rider-input" style="padding-left: 15px;margin-left: 15px;" [(ngModel)]="riderEmail2" [ngClass]="{ 'is-invalid-email': isEmailInvalid2 }" (keyup)="validateEmail(riderEmail2,2)" [ngModelOptions]="{standalone: true}" placeholder="Rider Business Email" >
                <span (click)="addRider(2)">
                  <i class="fa fa-plus-circle" *ngIf="(riderList0.length + riderList2.length + riderList1.length + riderList3.length) < sharedRideMaxAllowedRiders" style="margin: auto;cursor: pointer;color:#1DB31D;"  aria-hidden="true"></i>
                </span>
              </div>
  
               <div>
  
                <div style="display: flex;flex-direction:row;" *ngFor="let rider of riderList2">
                  <img src="assets/rider.png" class="rider-icon"   style="position: relative;left: 0px;top: 20px;" alt="rider">
                <div style="display: flex;flex-direction:row;gap: 11px;">
                  <input pInputText type="text" id="float-input" class="form-control  customFormInput added-rider" style="padding-left: 25px;margin-left: 0px;padding-top: 16px;" [(ngModel)]="rider.phone" [ngModelOptions]="{standalone: true}" placeholder="Rider Phone No." readonly="readonly">
                  <input pInputText type="text" class="form-control  customFormInput added-rider" style="padding-left: 15px;margin-left: 10px;padding-top: 16px;" [(ngModel)]="rider.firstName" [ngModelOptions]="{standalone: true}" placeholder="Rider Name" readonly="readonly">
                  <input pInputText type="text" class="form-control  customFormInput added-rider" style="padding-left: 15px;margin-left: 15px;padding-top: 16px;" [(ngModel)]="rider.email" [ngModelOptions]="{standalone: true}" placeholder="Rider Business Email" readonly="readonly">
                  <span (click)="removeRider(rider,2)">
                    <i class="fa fa-times-circle" style="margin: auto;cursor: pointer;color: #FF5050;" *ngIf="!blockStop(2)"  aria-hidden="true"></i>
                  </span>
                </div>
  
                 </div>
              </div>
  
              </div>
            </div>
            <!-- copy starts  -->
            <div class="ride-detail" [ngStyle]="{display: (riderList0.length < sharedRideMaxAllowedRiders - 1 && riderList1.length < sharedRideMaxAllowedRiders - 1 && riderList1.length >= 1 && riderList2.length < sharedRideMaxAllowedRiders - 2 && riderList2.length >= 1) || (riderList3.length >0 && ( isModifyStop || isLiveModifyStop) )   ? 'flex' : 'none'}">
              <div class="icon-wrapper">
                <img src='{{ pickupLocation2 ? "assets/blu-map-icon.png" : "assets/map-icon.png" }}'  class="map-icon" alt="map">
                <img *ngIf="isShowDrop && !isLiveModifyStop" src='{{ dropLocation ? "assets/blu-path.png" : "assets/black-path.png" }}' class="path-icon" alt="path">
        
              </div>
              <div class="location-wrapper">
                <!-- <input pInputText type="text" class="form-control  customFormInput" style="padding-left: 15px;margin-left: 15px;" [(ngModel)]="pickupLocation2" [ngModelOptions]="{standalone: true}" placeholder="Pick up Location" >  -->
                <span class="p-float-label">
                  <input pInputText type="text" class="form-control customFormInput" placeholder="" name="pickupLocation2"
                  style="padding-left: 15px;margin-left: 15px;font-size: 12px; width: 24rem;" (input)="formChanged()"
                  [(ngModel)]="pickupLocation4" [disabled]="blockStop(2)" autocorrect="off" autocapitalize="off" spellcheck="off" required #pickupLocationFour
                  (keydown.enter)="$event.preventDefault()"  (ngModelChange)="resetLatLong('pickupLocation4')" (click) = "resetScreenToDispalyMap()"/>
                  <label for="Pick up Location" style="color: #2C66E3;" style="font-size: 12px;padding-left: 15px;margin-left: 15px;font-family: 'Mulish';">Next Stop</label>
                </span>
                <input pInputText type="text" class="form-control customFormInput" *ngIf="!blockStop(2)" style="padding-left: 15px;margin-left: 15px;font-size: 12px; width:96.3% !important" [(ngModel)]="pickupNotes3" (input)="formChanged()" [ngModelOptions]="{standalone: true}" placeholder="Add Pick up notes" >
               </div>
              <div class="rider-wrapper">
               <div style="display: flex;flex-direction:row;gap: 11px;" *ngIf="!blockStop(2)">
                <input pInputText type="text" id="float-input" class="form-control  customFormInput add-rider-input" (keyup)="getUserInfoForSharedRide(riderPhone3,'four')" style="padding-left: 15px;margin-left: 10px;" [(ngModel)]="riderPhone3" [ngModelOptions]="{standalone: true}" placeholder="Rider Phone No."  maxlength="10" minlength="10">
                <input pInputText type="text" class="form-control  customFormInput add-rider-input" style="padding-left: 15px;margin-left: 15px;" [(ngModel)]="riderName3" [ngModelOptions]="{standalone: true}" placeholder="Rider Name" >
                <input pInputText type="text" class="form-control  customFormInput add-rider-input" style="padding-left: 15px;margin-left: 15px;" [(ngModel)]="riderEmail3" [ngClass]="{ 'is-invalid-email': isEmailInvalid3 }" (keyup)="validateEmail(riderEmail3,3)" [ngModelOptions]="{standalone: true}" placeholder="Rider Business Email" >
                <span (click)="addRider(3)">
                  <i class="fa fa-plus-circle" *ngIf="(riderList0.length + riderList2.length + riderList1.length + riderList3.length) < sharedRideMaxAllowedRiders" style="margin: auto;cursor: pointer;color:#1DB31D;"  aria-hidden="true"></i>
                </span>
               </div>
        
               <div>
        
                <div style="display: flex;flex-direction:row;" *ngFor="let rider of riderList3">
                  <img src="assets/rider.png" class="rider-icon"   style="position: relative;left: 0px;top: 20px;" alt="rider">
                <div style="display: flex;flex-direction:row;gap: 11px;">
                  <input pInputText type="text" id="float-input" class="form-control  customFormInput added-rider" style="padding-left: 25px;margin-left: 0px;padding-top: 16px;" [(ngModel)]="rider.phone" [ngModelOptions]="{standalone: true}" placeholder="Rider Phone No." readonly="readonly">
                  <input pInputText type="text" class="form-control  customFormInput added-rider" style="padding-left: 15px;margin-left: 10px;padding-top: 16px;" [(ngModel)]="rider.firstName" [ngModelOptions]="{standalone: true}" placeholder="Rider Name" readonly="readonly">
                  <input pInputText type="text" class="form-control  customFormInput added-rider" style="padding-left: 15px;margin-left: 15px;padding-top: 16px;" [(ngModel)]="rider.email" [ngModelOptions]="{standalone: true}" placeholder="Rider Business Email" readonly="readonly">
                  <span (click)="removeRider(rider,3)">
                    <i class="fa fa-times-circle" style="margin: auto;cursor: pointer;color: #FF5050;" *ngIf="!blockStop(2)" aria-hidden="true"></i>
                  </span>
                </div>
        
                 </div>
              </div>
        
              </div>
            </div>
            <!-- copy ends  -->
            <div class="ride-detail" [ngStyle]="{display : isShowDrop && !isLiveModifyStop ? 'flex' : 'none'}">
              <div class="icon-wrapper">
                <img src='{{ dropLocation ? "assets/red-map-icon.png" : "assets/map-icon.png" }}' class="map-icon" alt="map">
              </div>
              <div class="location-wrapper">
  
                <span class="p-float-label">
                  <input pInputText type="text" class="form-control customFormInput" placeholder="" name="pickupLocation2"
                  style="padding-left: 15px;margin-left: 15px;font-size: 12px; width: 24rem;"
                  (input)="formChanged()"
                  [(ngModel)]="dropLocation" autocorrect="off" autocapitalize="off" spellcheck="off" required #dropLoc
                  (keydown.enter)="$event.preventDefault()"  (ngModelChange)="resetLatLong('drop')" (click) = "resetScreenToDispalyMap()"/>
                  <label for="Drop Locationn" style="color: #2C66E3;" style="font-size: 12px;padding-left: 15px;margin-left: 15px;font-family: 'Mulish';">Drop Location</label>
                </span>
  
               </div>
  
            </div>
          </div>
  
          <!-- dropoff shared ride case -->
  
          <div class="dropoff-ride-detail" [ngStyle]="{display: myForm.value.myRadio == 'dropoff' ? 'flex' : 'none'}">
            <div class="ride-detail" *ngIf="!isSingleRideUpdate">
              <div class="icon-wrapper">
                <img src='{{ pickupLocation0 ? "assets/blu-map-icon.png" : "assets/map-icon.png" }}' class="map-icon" alt="map">
                <img *ngIf="pickupLocation0" src='{{ pickupLocation1 ? "assets/blu-path.png" : "assets/black-path.png" }}'  class="path-icon" alt="path">
              </div>
              <div class="location-wrapper">
                <div style="display: flex; justify-content: space-between;" >
                <span class="p-float-label">
                  <input pInputText type="text" (input)="formChanged()" class="form-control customFormInput" placeholder="" name="pickupLocationDrop"
                  style="padding-left: 15px;margin-left: 15px;font-size: 12px; width: 34rem;"
                  [(ngModel)]="pickupLocation0"  autocorrect="off" autocapitalize="off" spellcheck="off" required #pickupLocationDropoff
                  (keydown.enter)="$event.preventDefault()"  (ngModelChange)="resetLatLong('pickupLocationDrop')" (click) = "resetScreenToDispalyMap()"/>
                  <label for="Pick up Location" style="color: #2C66E3;" style="font-size: 12px; padding-left: 15px;margin-left: 15px;font-family: 'Mulish';">Pick up Location</label>
                </span>
               
                <span class="p-float-label" *ngIf="showLandmark">
                  <p-dropdown [options]="landmarks" [(ngModel)]="selectedLandmark" placeholder="landmark" optionLabel="name" class="landmarkDropDown"></p-dropdown>
                </span>
  
              </div>
                <input pInputText type="text"  class="form-control customFormInput" style="padding-left: 15px;margin-left: 15px; width:50%; font-size: 12px; width:96.3%; " (input)="formChanged()" [(ngModel)]="pickupNotes0" [ngModelOptions]="{standalone: true}" placeholder="Add Pick up notes" >            
              
              </div>
            </div>

            <div class="ride-detail" [ngStyle]="{display: origin0.lat && origin0.lng ? 'flex' : 'none'}">
              <div class="icon-wrapper">
                <img src='{{ pickupLocation1 ? "assets/blu-map-icon.png" : "assets/map-icon.png" }}' class="map-icon" alt="map">
                <img *ngIf="riderList0.length > 0" src='{{ pickupLocation2 ? "assets/blu-path.png" : "assets/black-path.png" }}'  class="path-icon" alt="path">
              </div>
              <div class="location-wrapper">
                <!-- <input pInputText type="text" class="form-control  customFormInput" style="padding-left: 15px;margin-left: 15px;" [(ngModel)]="pickupLocation1" [ngModelOptions]="{standalone: true}" placeholder="Pick up Location" >  -->
                <span class="p-float-label">
                  <input pInputText type="text" class="form-control customFormInput" placeholder="" name="pickupLocation1"
                  style="padding-left: 15px;margin-left: 15px;font-size: 12px;"
                  [(ngModel)]="pickupLocation1" (input)="formChanged()" autocorrect="off" autocapitalize="off" spellcheck="off" required #dropoffStopOne
                  (keydown.enter)="$event.preventDefault()"  (ngModelChange)="resetLatLong('pickupLocation1')" (click) = "resetScreenToDispalyMap()"/>
                  <label for="Pick up Location" style="color: #2C66E3;" style="font-size: 12px;padding-left: 15px;margin-left: 15px;font-family: 'Mulish';">Next Stop</label>
                </span>
                <input pInputText type="text" class="form-control customFormInput" style="padding-left: 15px;margin-left: 15px;font-size: 12px;width:96.3%; " [(ngModel)]="pickupNotes1" (input)="formChanged()" [ngModelOptions]="{standalone: true}" placeholder="Add Pick up notes" >
               </div>
               <div class="rider-wrapper">
                <div style="display: flex;flex-direction:row;gap: 55px;">
                 <input pInputText type="text" (input)="formChanged()"  (keyup)="getUserInfoForSharedRide(riderPhone0,'one')" id="float-input" class="form-control  customFormInput add-rider-input" style="padding-left: 15px;margin-left: 25px;" [(ngModel)]="riderPhone0" [ngModelOptions]="{standalone: true}" placeholder="Rider Phone No." maxlength="10" minlength="10">
                 <input pInputText type="text" (input)="formChanged()" class="form-control  customFormInput add-rider-input" style="padding-left: 15px;margin-left: 15px;" [(ngModel)]="riderName0" [ngModelOptions]="{standalone: true}" placeholder="Rider Name" >
                 <input pInputText type="text" (input)="formChanged()" class="form-control  customFormInput add-rider-input" style="padding-left: 15px;margin-left: 15px;" [(ngModel)]="riderEmail0" [ngClass]="{ 'is-invalid-email': isEmailInvalid0 }" (keyup)="validateEmail(riderEmail0,0)" [ngModelOptions]="{standalone: true}" placeholder="Rider Business Email" >
                 <span (click)="addRider(0)">
                   <i class="fa fa-plus-circle" *ngIf="(riderList0.length + riderList2.length + riderList1.length + riderList3.length) < sharedRideMaxAllowedRiders-1" style="margin: auto;cursor: pointer;color:#1DB31D;" aria-hidden="true"></i>
                 </span>
                </div>
  
                <div>
  
                  <div style="display: flex;flex-direction:row;" *ngFor="let rider of riderList0">
                    <img src="assets/rider.png" class="rider-icon"  style="position: relative;left: 25px;top: 20px;"alt="rider">
                  <div style="display: flex;flex-direction:row;gap: 55px;">
                    <input pInputText type="text" id="float-input" class="form-control  customFormInput added-rider" style="padding-left: 25px;margin-left: 15px;padding-top: 16px;" [(ngModel)]="rider.phone" [ngModelOptions]="{standalone: true}" placeholder="Rider Phone No." readonly="readonly">
                    <input pInputText type="text" class="form-control  customFormInput added-rider" style="padding-left: 15px;margin-left: 15px;padding-top: 16px;" [(ngModel)]="rider.firstName" [ngModelOptions]="{standalone: true}" placeholder="Rider Name" readonly="readonly">
                    <input pInputText type="text" class="form-control  customFormInput added-rider" style="padding-left: 15px;margin-left: 15px;padding-top: 16px;" [(ngModel)]="rider.email" [ngModelOptions]="{standalone: true}" placeholder="Rider Business Email" readonly="readonly">
                    <span (click)="removeRider(rider,0)">
                      <i class="fa fa-times-circle" style="margin: auto;cursor: pointer;color: #FF5050;"  aria-hidden="true"></i>
                    </span>
                  </div>
  
                   </div>
                </div>
  
               </div>
  
            </div>
            <div class="ride-detail" [ngStyle]="{display: (riderList0.length>=1 && riderList0.length < sharedRideMaxAllowedRiders-1) || isModifyStop || isLiveModifyStop || !disableHidingStops ? 'flex' : 'none'}">
              <div class="icon-wrapper">
                <img src='{{ pickupLocation2 ? "assets/blu-map-icon.png" : "assets/map-icon.png" }}' class="map-icon" alt="map">
                <img *ngIf="riderList1.length > 0" src='{{ dropLocation ? "assets/blu-path.png" : "assets/black-path.png" }}' class="path-icon" alt="path">
  
              </div>
              <div class="location-wrapper">
                <!-- <input pInputText type="text" class="form-control  customFormInput" style="padding-left: 15px;margin-left: 15px;" [(ngModel)]="pickupLocation2" [ngModelOptions]="{standalone: true}" placeholder="Pick up Location" >  -->
                <span class="p-float-label">
                  <input pInputText type="text" class="form-control customFormInput" placeholder="" name="pickupLocation2"
                  style="padding-left: 15px;margin-left: 15px;font-size: 12px;"
                  [(ngModel)]="pickupLocation2" (input)="formChanged()" autocorrect="off" autocapitalize="off" spellcheck="off" required #dropoffStopTwo
                  (keydown.enter)="$event.preventDefault()"  (ngModelChange)="resetLatLong('pickupLocation2')" (click) = "resetScreenToDispalyMap()"/>
                  <label for="Pick up Location" style="color: #2C66E3;" style="font-size: 12px;padding-left: 15px;margin-left: 15px;font-family: 'Mulish';">Next Stop</label>
                </span>
                <input pInputText type="text" class="form-control customFormInput" style="padding-left: 15px;margin-left: 15px;font-size: 12px;width:96.3%; " [(ngModel)]="pickupNotes2" (input)="formChanged()" [ngModelOptions]="{standalone: true}" placeholder="Add Pick up notes" >
              </div>
               <div class="rider-wrapper">
                <div style="display: flex;flex-direction:row;gap: 55px;">
                 <input pInputText type="text" (input)="formChanged()" (keyup)="getUserInfoForSharedRide(riderPhone1,'two')" id="float-input" class="form-control  customFormInput add-rider-input" style="padding-left: 15px;margin-left: 25px;" [(ngModel)]="riderPhone1" [ngModelOptions]="{standalone: true}" placeholder="Rider Phone No." maxlength="10" minlength="10">
                 <input pInputText type="text" (input)="formChanged()" class="form-control  customFormInput add-rider-input" style="padding-left: 15px;margin-left: 15px;" [(ngModel)]="riderName1" [ngModelOptions]="{standalone: true}" placeholder="Rider Name" >
                 <input pInputText type="text" (input)="formChanged()" class="form-control  customFormInput add-rider-input" style="padding-left: 15px;margin-left: 15px;" [(ngModel)]="riderEmail1" [ngModelOptions]="{standalone: true}" [ngClass]="{ 'is-invalid-email': isEmailInvalid1 }" (keyup)="validateEmail(riderEmail1,1)" placeholder="Rider Business Email" >
                 <span (click)="addRider(1)">
                  <i class="fa fa-plus-circle"  *ngIf="(riderList0.length + riderList2.length + riderList1.length + riderList3.length) < sharedRideMaxAllowedRiders-1" style="margin: auto;cursor: pointer;color:#1DB31D;"  aria-hidden="true"></i>
                 </span>
                 
                </div>
  
                <div>
  
                  <div style="display: flex;flex-direction:row;" *ngFor="let rider of riderList1">
                    <img src="assets/rider.png" class="rider-icon"  style="position: relative;left: 25px;top: 20px;" alt="rider">
                    <div style="display: flex;flex-direction:row;gap: 55px;">
                      <input pInputText type="text" id="float-input" class="form-control  customFormInput added-rider" style="padding-left: 25px;margin-left: 15px;padding-top: 16px;" [(ngModel)]="rider.phone" [ngModelOptions]="{standalone: true}" placeholder="Rider Phone No." readonly="readonly">
                      <input pInputText type="text" class="form-control  customFormInput added-rider" style="padding-left: 15px;margin-left: 15px;padding-top: 16px;" [(ngModel)]="rider.firstName" [ngModelOptions]="{standalone: true}" placeholder="Rider Name" readonly="readonly">
                      <input pInputText type="text" class="form-control  customFormInput added-rider" style="padding-left: 15px;margin-left: 15px;padding-top: 16px;" [(ngModel)]="rider.email" [ngModelOptions]="{standalone: true}" placeholder="Rider Business Email" readonly="readonly">
                      <span (click)="removeRider(rider,1)">
                        <i class="fa fa-times-circle" style="margin: auto;cursor: pointer;color: #FF5050;"  aria-hidden="true"></i>
                      </span>
                    </div>
  
  
  
                   </div>
                </div>
  
  
               </div>
  
            </div>
             <!-- copy starts  -->
             <div class="ride-detail" [ngStyle]="{display: (riderList0.length>=1 && riderList1.length>=1 &&riderList0.length + riderList1.length < sharedRideMaxAllowedRiders-1) || (riderList3.length >0 && ( isModifyStop || isLiveModifyStop) ) ? 'flex' : 'none'}">
              <div class="icon-wrapper">
                <img src='{{ pickupLocation2 ? "assets/blu-map-icon.png" : "assets/map-icon.png" }}' class="map-icon" alt="map">
                <img *ngIf="riderList1.length > 0" src='{{ dropLocation ? "assets/blu-path.png" : "assets/black-path.png" }}' class="path-icon" alt="path">
  
              </div>
              <div class="location-wrapper">
                <!-- <input pInputText type="text" class="form-control  customFormInput" style="padding-left: 15px;margin-left: 15px;" [(ngModel)]="pickupLocation2" [ngModelOptions]="{standalone: true}" placeholder="Pick up Location" >  -->
                <span class="p-float-label">
                  <input pInputText type="text" class="form-control customFormInput" placeholder="" name="pickupLocation4"
                  style="padding-left: 15px;margin-left: 15px;font-size: 12px;"
                  [(ngModel)]="pickupLocation4" (input)="formChanged()" autocorrect="off" autocapitalize="off" spellcheck="off" required #dropoffStopThree
                  (keydown.enter)="$event.preventDefault()"  (ngModelChange)="resetLatLong('pickupLocation4')" (click) = "resetScreenToDispalyMap()"/>
                  <label for="Pick up Location" style="color: #2C66E3;" style="font-size: 12px;padding-left: 15px;margin-left: 15px;font-family: 'Mulish';">Next Stop</label>
                </span>
                <input pInputText type="text" class="form-control customFormInput" style="padding-left: 15px;margin-left: 15px;font-size: 12px;width:96.3%; " [(ngModel)]="pickupNotes3" (input)="formChanged()" [ngModelOptions]="{standalone: true}" placeholder="Add Pick up notes" >
              </div>
               <div class="rider-wrapper">
                <div style="display: flex;flex-direction:row;gap: 55px;">
                 <input pInputText type="text" (input)="formChanged()" (keyup)="getUserInfoForSharedRide(riderPhone3,'four')" id="float-input" class="form-control  customFormInput add-rider-input" style="padding-left: 15px;margin-left: 25px;" [(ngModel)]="riderPhone3" [ngModelOptions]="{standalone: true}" placeholder="Rider Phone No." maxlength="10" minlength="10">
                 <input pInputText type="text" (input)="formChanged()" class="form-control  customFormInput add-rider-input" style="padding-left: 15px;margin-left: 15px;" [(ngModel)]="riderName3" [ngModelOptions]="{standalone: true}" placeholder="Rider Name" >
                 <input pInputText type="text" (input)="formChanged()" class="form-control  customFormInput add-rider-input" style="padding-left: 15px;margin-left: 15px;" [(ngModel)]="riderEmail3" [ngModelOptions]="{standalone: true}" [ngClass]="{ 'is-invalid-email': isEmailInvalid1 }" (keyup)="validateEmail(riderEmail3,1)" placeholder="Rider Business Email" >
                 <span (click)="addRider(3)">
                   <i class="fa fa-plus-circle"  *ngIf="(riderList0.length + riderList2.length + riderList1.length + riderList3.length) < sharedRideMaxAllowedRiders-1" style="margin: auto;cursor: pointer;color:#1DB31D;"  aria-hidden="true"></i>
                 </span>
                </div>
  
                <div>
  
                  <div style="display: flex;flex-direction:row;" *ngFor="let rider of riderList3">
                    <img src="assets/rider.png" class="rider-icon"  style="position: relative;left: 25px;top: 20px;" alt="rider">
                    <div style="display: flex;flex-direction:row;gap: 55px;">
                      <input pInputText type="text" id="float-input" class="form-control  customFormInput added-rider" style="padding-left: 25px;margin-left: 15px;padding-top: 16px;" [(ngModel)]="rider.phone" [ngModelOptions]="{standalone: true}" placeholder="Rider Phone No." readonly="readonly">
                      <input pInputText type="text" class="form-control  customFormInput added-rider" style="padding-left: 15px;margin-left: 15px;padding-top: 16px;" [(ngModel)]="rider.firstName" [ngModelOptions]="{standalone: true}" placeholder="Rider Name" readonly="readonly">
                      <input pInputText type="text" class="form-control  customFormInput added-rider" style="padding-left: 15px;margin-left: 15px;padding-top: 16px;" [(ngModel)]="rider.email" [ngModelOptions]="{standalone: true}" placeholder="Rider Business Email" readonly="readonly">
                      <span (click)="removeRider(rider,3)">
                        <i class="fa fa-times-circle" style="margin: auto;cursor: pointer;color: #FF5050;"  aria-hidden="true"></i>
                      </span>
                    </div>
  
  
  
                   </div>
                </div>
  
  
               </div>
  
            </div>
          <!-- copy ends  -->
           
            <div class="ride-detail" [ngStyle]="{display : isShowDrop ? 'flex' : 'none'}">
              <div class="icon-wrapper">
                <img src='{{ dropLocation ? "assets/blu-map-icon.png" : "assets/map-icon.png" }}'  class="map-icon" alt="map">
              </div>
              <div class="location-wrapper">
  
                <span class="p-float-label">
                  <input pInputText type="text" class="form-control customFormInput" placeholder="" name="pickupLocation2"
                  (input)="formChanged()"
                  style="padding-left: 15px;margin-left: 15px;font-size: 12px;"
                  [(ngModel)]="dropLocation" autocorrect="off" autocapitalize="off" spellcheck="off" required #dropLocDropoff
                  (keydown.enter)="$event.preventDefault()"  (ngModelChange)="resetLatLong('drop')" (click) = "resetScreenToDispalyMap()"/>
                  <label for="Drop Location" style="color: #2C66E3;" style="font-size: 12px;padding-left: 15px;margin-left: 15px;font-family: 'Mulish';">Drop Location</label>
                </span>
  
               </div>
               <div class="rider-wrapper">
                <div style="display: flex;flex-direction:row;gap: 55px;">
                 <input pInputText type="text" (input)="formChanged()" (keyup)="getUserInfoForSharedRide(riderPhone2,'three')" id="float-input" class="form-control  customFormInput add-rider-input" style="padding-left: 15px;margin-left: 25px;" [(ngModel)]="riderPhone2" [ngModelOptions]="{standalone: true}" placeholder="Rider Phone No." maxlength="10" minlength="10">
                 <input pInputText type="text" (input)="formChanged()" class="form-control  customFormInput add-rider-input" style="padding-left: 15px;margin-left: 15px;" [(ngModel)]="riderName2" [ngModelOptions]="{standalone: true}" placeholder="Rider Name" >
                 <input pInputText type="text" (input)="formChanged()" class="form-control  customFormInput add-rider-input" style="padding-left: 15px;margin-left: 15px;" [(ngModel)]="riderEmail2" [ngClass]="{ 'is-invalid-email': isEmailInvalid2 }" (keyup)="validateEmail(riderEmail2,2)" [ngModelOptions]="{standalone: true}" placeholder="Rider Business Email" >
                 <span (click)="addRider(2)">
                   <i class="fa fa-plus-circle" *ngIf="(riderList0.length + riderList2.length + riderList1.length + riderList3.length) < sharedRideMaxAllowedRiders" style="margin: auto;cursor: pointer;color:#1DB31D;"  aria-hidden="true"></i>
                 </span>
                </div>
  
                <div>
  
                  <div style="display: flex;flex-direction:row;" *ngFor="let rider of riderList2">
                    <img src="assets/rider.png" class="rider-icon"  style="position: relative;left: 25px;top: 20px;" alt="rider">
                    <div style="display: flex;flex-direction:row;gap: 55px;">
                      <input pInputText type="text" id="float-input" class="form-control  customFormInput added-rider" style="padding-left: 25px;margin-left: 15px;padding-top: 16px;" [(ngModel)]="rider.phone" [ngModelOptions]="{standalone: true}" placeholder="Rider Phone No." readonly="readonly">
                      <input pInputText type="text" class="form-control  customFormInput added-rider" style="padding-left: 15px;margin-left: 15px;padding-top: 16px;" [(ngModel)]="rider.firstName" [ngModelOptions]="{standalone: true}" placeholder="Rider Name" readonly="readonly">
                      <input pInputText type="text" class="form-control  customFormInput added-rider" style="padding-left: 15px;margin-left: 15px;padding-top: 16px;" [(ngModel)]="rider.email" [ngModelOptions]="{standalone: true}" placeholder="Rider Business Email" readonly="readonly">
                      <span (click)="removeRider(rider,2)">
                        <i class="fa fa-times-circle" style="margin: auto;cursor: pointer;color: #FF5050;"  aria-hidden="true"></i>
                      </span>
                    </div>
  
  
                   </div>
                </div>
  
               </div>
  
            </div>
          </div>
  
            <div class="schedule-wrapper" *ngIf="isShowSchedule && !isSingleRideUpdate">
              <div class="icon-wrapper">
                <img src="assets/clock.png" class="clock-icon" alt="clock">
              </div>
              <div class="date-time-wrapper">
                <div style="width: 150px;">
                  <label for="startDateTime" style="color:#2C66E3;font-size:9px;position:relative;top: 15px;font-family: 'Mulish';">Scheduled Start Date & Time</label>
                  <p-calendar [(ngModel)]="startDateTime" (onSelect)="autoFillWeekdays()" (onBlur)="formChanged()" (onClose)="formChanged()" [minDate]="minDateForSharedRide" [maxDate]="maxDate" [readonlyInput]="true" [showTime]="true" [stepMinute]="15"
                  name="startTime" [inputStyle]="{'width': '195px','border': 'none','border-bottom' : '1px solid #E5E7EB','margin-top':'5px'}" appendTo="body" ></p-calendar>
                </div>
                <div style="width:150px;">
                  <label for="endDate" style="color:#2C66E3;font-size:9px;position:relative;top: 15px;font-family: 'Mulish';">Scheduled End Date</label>
                  <p-calendar [(ngModel)]="endDate" (onSelect)="autoFillWeekdays()" (onBlur)="formChanged()" [minDate]="minDateForSharedRide" (onClose)="formChanged()" [maxDate]="maxDate" [readonlyInput]="true" [showTime]="true"
                  name="startTime" [inputStyle]="{'width': '195px','border': 'none','border-bottom' : '1px solid #E5E7EB','margin-top':'5px'}" appendTo="body" ></p-calendar>
                </div>
  
  
              </div>
              <div class="weekly-schedule-wrapper" *ngIf="!isSingleRideUpdate">
                <div> <img src="assets/calendar.png" class="calendar-icon" alt="calendar"></div>
                <div style="position: relative;left: 10px;">
                  <label for="weeks" style="color:#2C66E3;font-size:9px;position:relative;top: 15px;font-family: 'Mulish';">Week Schedule</label>
                  <div class="weeks-selection">
                    <div style="display: flex;margin-top: 10px;gap:30px;margin-bottom: 30px;">
                      <div>
                        <label class="pick-drop-label">Monday</label>
  
                        <input type="checkbox" (change)="formChanged()" class="days-checkbox" *ngIf="!isWeekDisabled" [(ngModel)]="selectedDay1">
                        <input type="checkbox" (change)="formChanged()" class="days-checkbox"  *ngIf="isWeekDisabled"  [(ngModel)]="selectedDay1" disabled>
                      </div>
                      <div>
                        <label  class="pick-drop-label">Tuesday</label>
  
                        <input type="checkbox"  (change)="formChanged()"  class="days-checkbox" *ngIf="!isWeekDisabled" [(ngModel)]="selectedDay2">
                        <input type="checkbox"  (change)="formChanged()"  class="days-checkbox" *ngIf="isWeekDisabled" [(ngModel)]="selectedDay2" disabled>
                      </div>
                      <div>
                        <label class="pick-drop-label">Wednesday</label>
  
                        <input type="checkbox"  (change)="formChanged()"  class="days-checkbox" *ngIf="!isWeekDisabled" [(ngModel)]="selectedDay3" >
                        <input type="checkbox"  (change)="formChanged()"  class="days-checkbox" *ngIf="isWeekDisabled" [(ngModel)]="selectedDay3" disabled>
                      </div>
                      <div>
                        <label  class="pick-drop-label">Thursday</label>
  
                        <input type="checkbox"  (change)="formChanged()"  class="days-checkbox" *ngIf="!isWeekDisabled" [(ngModel)]="selectedDay4">
                        <input type="checkbox"  (change)="formChanged()"  class="days-checkbox" *ngIf="isWeekDisabled" [(ngModel)]="selectedDay4" disabled>
                      </div>
                      <div>
                        <label class="pick-drop-label">Friday</label>
  
                        <input type="checkbox"  (change)="formChanged()"  class="days-checkbox"  *ngIf="!isWeekDisabled" [(ngModel)]="selectedDay5" >
                        <input type="checkbox"  (change)="formChanged()"  class="days-checkbox"  *ngIf="isWeekDisabled" [(ngModel)]="selectedDay5" disabled>
                      </div>
                      <div>
                        <label  class="pick-drop-label">Saturday</label>
  
                        <input type="checkbox"  (change)="formChanged()"  class="days-checkbox" *ngIf="!isWeekDisabled" [(ngModel)]="selectedDay6">
                        <input type="checkbox"  (change)="formChanged()"  class="days-checkbox" *ngIf="isWeekDisabled" [(ngModel)]="selectedDay6" disabled>
                      </div>
  
                      <div>
                        <label  class="pick-drop-label">Sunday</label>
  
                        <input type="checkbox"  (change)="formChanged()"  class="days-checkbox" *ngIf="!isWeekDisabled" [(ngModel)]="selectedDay7"/>
                        <input type="checkbox"  (change)="formChanged()"  class="days-checkbox" *ngIf="isWeekDisabled" [(ngModel)]="selectedDay7" disabled/>
                      </div>                  </div>
                  </div>
                </div>
  
  
              </div>
            </div>
            <div style="width: 100%;align-items: right;text-align: right;" *ngIf="isSingleRideUpdate">
              <br>
              <button type="button" class="btn btn-primary"  (click)="createSharedRide()" [disabled]="(riderList0.length + riderList2.length + riderList1.length + riderList3.length) < 2" style="width: 100px;background: #2C66E3;float: right;padding: 5px;">Next</button>
               <br/>
            </div>
            <div class="payment-wrapper" *ngIf="isShowSchedule && !isSingleRideUpdate">
              <div style="margin-top: 1.7rem;"><img src="assets/currency.png" class="currency-icon" alt="currency"></div>
              <div style="position: relative;left: 10px;width: 330px;margin-right: 45px;">
                <label for="weeks" style="color:#2C66E3;font-size:9px;position:relative;top: 7px;font-family: 'Mulish';">Payment Mode</label>
                <p-dropdown class="form-control customFormInputDropdown" (onChange)="formChanged()" [options]="paymentModeList"  name="paymentType" [(ngModel)]="paymentTypeShared" ngModel required  placeholder="Payment Mode"
              #pt="ngModel"></p-dropdown>
              </div>
              <div class="weeks-selection">
                <div> <img src="assets/edit_note.png" class="note-icon" alt="currency"></div>
                <div style="display: flex;gap:20px;position: relative;left: 10px;">
                  <span class="p-float-label">
                    <label for="from" style="color:#2C66E3;font-size:9px;position:relative;top: 15px;font-family: 'Mulish';">Business Ride Reason</label>
                    <textarea pInputTextarea class="form-control customFormInput"
                    style="width:300px;overflow-y: hidden;"
                    [(ngModel)]="sharedRideReason" placeholder="" id="sharedRideReason" name="sharedRideReason" autocorrect="off" autocapitalize="off"
                    spellcheck="off" ngModel  #br="ngModel" ></textarea>
  
                  </span>
                 </div>
              </div>
              <div style="width: 100%;align-items: right;text-align: right;">
                <br>
                <button type="button" class="btn btn-primary"  (click)="createSharedRide()" [disabled]="isNextDisable" style="width: 100px;background: #2C66E3;float: right;padding: 5px;">Next</button>
                 <br/>
              </div>
  
  
            </div>
      </div>
      <div class="journey-customer-payment">
          <div style="margin: 5px;" (click)="togglePaymentInfoFormShared()">
          <span>Payment Details</span>
          <span style="margin-left: auto; margin-right: 0; float: right;color: #2C66E3;" >
            <i class="fas fa-chevron-down" *ngIf="!showPaymentInfoFormShared" ></i>
              <i class="fas fa-chevron-up" *ngIf="showPaymentInfoFormShared" ></i>
            </span>
            <div *ngIf="showPaymentInfoFormShared && sharedRideEstimatedAmount">
              <span style="color: #3E4958;">Payment Amount : {{(sharedRideEstimatedAmount?sharedRideEstimatedAmount:"-") | currencySymbol}}</span>
              </div>
        </div>
  
      </div>
      <div style="width: 100%;align-items: center;text-align: center;margin-bottom: 20px;">
        <button type="button" class="btn btn-primary" (click)="bookSharedRide()" [disabled]="!sharedRideEstimatedAmount"style="width: 250px;height:40px; background:#2C66E3">Book Ride</button>
  
     </div>
     </div>
    </div>
  </div>
  