import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Dropdown } from 'primeng/dropdown';
import { Subscription } from 'rxjs';
import { JourneyDetailsApiService } from 'src/app/app-v2/api-services/create-ride/journey-details-api.service';
import { JourneyDetailsContainerService } from 'src/app/app-v2/getter-setter/container/create-ride/journey-details-container.service';
import { CommonSubscriptionService } from 'src/app/app-v2/subscriptions/common/common-subscription.service';
import { CreateRideSubscriptionService } from 'src/app/app-v2/subscriptions/create-ride/create-ride-subscription.service';
import { CustomDropDownType, CustomInputType, DropdownDetails, TextAreaDetails } from 'src/app/app-v2/types/components.type';
import { Theme } from 'src/app/app-v2/types/enums';

@Component({
  selector: 'additional-details-container',
  templateUrl: './additional-details-container.component.html',
  styleUrls: ['./additional-details-container.component.scss']
})
export class AdditionalDetailsContainerComponent implements OnInit, OnDestroy{
  additionalDetailsValidationSubscription: Subscription;

  businessRideReasonsFlags = {
    dropdown:false, textBox: true, enabled: true, mandatory: false, otherRequired: false
  }

  expenseCodeFlags ={
    dropdown: false, textBox: true, enabled: false, mandatory: false, otherRequired: false
  }


  pickupNotesDetails: TextAreaDetails = {
    placeholder : "Additional Comments",
    inputValue: '',
    label: "Pickup Notes",
    rows: '6',
    name: 'pickupNotes',
    validation: null,
    isDisabled: false,
    required: false,
    enableTooltip: true,
    tooltipContent: 'Add instructions for driver for faster pickup'
    
  }

  businessRideReasonInputDetails: CustomInputType = {
    placeholder : 'For eg. Late night cab, administrative commute etc',
    type: 'text',
    inputValue: null,
    label: 'Manually add reason',
    name: 'businessRideReason',
    validation: {
      isError: false,
      isSuccess: false,
      message: ''
    },
    isDisabled: false,
    required: false,
    enableTooltip: true,
    tooltipContent: 'Add purpose of your ride as specified by your organisation'
  }

  expenseCodeInputDetails: CustomInputType = {
    placeholder : 'For eg. Late night cab, administrative commute etc',
    type: 'text',
    inputValue: null,
    label: 'Manually add Expense Code',
    name: 'expenseCode',
    validation: {
      isError: false,
      isSuccess: false,
      message: ''
    },
    isDisabled: false,
    required: false,
    enableTooltip: true,
    tooltipContent: 'Please add the code given by your organization'
  }

  businessRideReasonDropDownDetails : CustomDropDownType = {
    selectedValue: {label: '', value: ''},
    validation: {
      isError: false,
      isSuccess: false,
      message: ''
    },
    options: [],
    label: 'Business Ride Reason',
    name: 'businessRideReason',
    placeholder : 'Select Reason',
    isDisabled: false,
    required: false,
    enableTooltip: true,
    tooltipContent: 'Add purpose of your ride as specified by your organisation'
  }

  expenseCodeDropDownDetails : CustomDropDownType = {
    selectedValue: {label: '', value: ''},
    validation: {
      isError: false,
      isSuccess: false,
      message: ''
    },
    options: [],
    label: 'Expense Code',
    name: 'expenseCode',
    placeholder : 'Select Code',
    isDisabled: false,
    required: false,
    enableTooltip: true,
    tooltipContent: 'Please add the code given by your organization'
  }


  constructor(private _journey: JourneyDetailsContainerService, 
              private _journeyApi: JourneyDetailsApiService,
              private _subscription: CreateRideSubscriptionService,
              private _commonSubscription: CommonSubscriptionService,
              private _loader: NgxSpinnerService){
    
  }

  ngOnInit(): void {
   this.fetchAdditionalDetailsInputDataFromApi().then(()=>{
    this.fetchPrevData(); 
   });
   this.startAdditionalDetailsValidationSubscription();
  }

  fetchAdditionalDetailsInputDataFromApi(){
    this._loader.show();
    let x = new Promise((resolve, reject)=>{
      this._journeyApi.fetchReasonAndCode().subscribe((res)=>{
        this._loader.hide();
        if(res['statusCode'] == 200){
          this.renderData(res['response'])
          resolve(true);
        }
  
        else{
          this._commonSubscription.emitAlert({
            theme: Theme.ERROR,
            title: 'Technical Error!',
            message: res['message'],
            ctaLabel: 'OK!'
          })
          reject(true);
        }
      })
    })
    return x;
  }

  renderData(data){
    if(data){

    if(data['businessRideReasonDetails']){
        this.businessRideReasonsFlags['enabled']= true;
        this.businessRideReasonsFlags['mandatory'] = data['businessRideReasonDetails']['mandatory'];
        this.businessRideReasonDropDownDetails.required = this.businessRideReasonsFlags['mandatory'] || false;
        this.businessRideReasonInputDetails.required = this.businessRideReasonsFlags['mandatory'] || false;
        this.businessRideReasonsFlags['otherRequired'] = data['businessRideReasonDetails']['otherRequired'];
        this.businessRideReasonsFlags['dropdown'] = this.checkReasonAndCodeType(data['businessRideReasonDetails']['type'], 'LIST', true);
        this.businessRideReasonsFlags['textBox'] = this.checkReasonAndCodeType(data['businessRideReasonDetails']['type'], 'TEXT', true);
        this.businessRideReasonDropDownDetails.options = data['businessRideReasonDetails']['itemList'] ? data['businessRideReasonDetails']['itemList'].map(listItem=>{
        return ({label: listItem, value: listItem})
        }) : null;


        this.businessRideReasonsFlags.otherRequired ? this.businessRideReasonDropDownDetails.options.push({label: 'Other', value: 'OTHER'}) : null ;
        this.businessRideReasonInputDetails.label = !this.businessRideReasonsFlags.dropdown? 'Business Ride Reasons' : 'Manually add Reason'



    }

    if(data['expenseCodeDetails']){
      
        this.expenseCodeFlags['enabled']=data['expenseCodeDetails']['enabled'];
        this.expenseCodeFlags['mandatory']=data['expenseCodeDetails']['mandatory'];
        this.expenseCodeDropDownDetails.required = this.expenseCodeFlags['mandatory'] || false;
        this.expenseCodeInputDetails.required = this.expenseCodeFlags['mandatory'] || false;
        this.expenseCodeFlags['otherRequired']= data['expenseCodeDetails']['otherRequired'];
        this.expenseCodeFlags['dropdown']=this.checkReasonAndCodeType(data['expenseCodeDetails']['type'], 'LIST', data['expenseCodeDetails']['enabled']);
        this.expenseCodeFlags['textBox'] = this.checkReasonAndCodeType(data['expenseCodeDetails']['type'], 'TEXT', data['expenseCodeDetails']['enabled']);
        this.expenseCodeDropDownDetails.options = data['expenseCodeDetails']['itemList'].map(listItem=>{
          return ({label: listItem, value: listItem})
        })

        this.expenseCodeFlags.otherRequired ? this.expenseCodeDropDownDetails.options.push({label: 'Other', value: 'OTHER'}) : null ;
        this.expenseCodeInputDetails.label = !this.expenseCodeFlags.dropdown? 'Expense Code' : 'Manually add Expense Code'

    }
  }
  }

  checkReasonAndCodeType(type, field, isEnabled){
    if(isEnabled){
      return type == field ? true : false;
    }
    else{
      return false
    }
  }


  onDropdownValueChange(type){
    switch(type){
      case 'REASON':
        this.businessRideReasonsFlags.textBox = this.businessRideReasonDropDownDetails.selectedValue.value == 'OTHER' ? true : false
        this.businessRideReasonInputDetails.inputValue = "" ;
        return 
    
      case 'CODE':
        this.expenseCodeFlags.textBox = this.expenseCodeDropDownDetails.selectedValue.value == 'OTHER' ? true : false
        this.expenseCodeInputDetails.inputValue = "";
        return
    } 
  }


  sendAdditionalDetailsInputsData(){
    let additionalDetails = {
      otherFlags :{
        businessRideReason: this.businessRideReasonsFlags.textBox ? this.businessRideReasonInputDetails.inputValue : this.businessRideReasonDropDownDetails.selectedValue.value,
        expenseCode: this.expenseCodeFlags.textBox ? this.expenseCodeInputDetails.inputValue : this.expenseCodeDropDownDetails.selectedValue.value
      },
      pickupNotes:this.pickupNotesDetails.inputValue  == '' ? null : this.pickupNotesDetails.inputValue
    }
    this._journey.setAddtionalDetails(additionalDetails.otherFlags, additionalDetails.pickupNotes);
  }

  
  startAdditionalDetailsValidationSubscription(){
    this.additionalDetailsValidationSubscription = this._subscription.fetchAdditionalDetailsValidationObserver().subscribe(async (res)=>{
      
      this.sendAdditionalDetailsInputsData();

      let brrPromise = new Promise(async (resolve, reject)=>{
        
        if(this.businessRideReasonsFlags.mandatory){
          let res = await this._journey.checkValidation('businessRideReason');
          if(this.businessRideReasonsFlags.dropdown){
            this.businessRideReasonDropDownDetails.validation = res;
            resolve(res);
          }
          else{
            this.businessRideReasonInputDetails.validation = res;
            resolve(res);
          }
        }
        else{
          resolve(true);
        }
        
      })


      let expenseCodePromise = new Promise(async (resolve, reject)=>{
        
        if(this.expenseCodeFlags.mandatory){
          let res = await this._journey.checkValidation('expenseCode');
          if(this.expenseCodeFlags.dropdown){
            this.expenseCodeDropDownDetails.validation = res;
            resolve(res);
          }
          else{
            this.expenseCodeInputDetails.validation = res;
            resolve(res);
          }
        }
        else{
          resolve(true);
        }
        
      })

      await Promise.all([brrPromise, expenseCodePromise]);

      let brrValidationStatus = this.businessRideReasonDropDownDetails.validation['isError'] || this.businessRideReasonInputDetails.validation['isError'] ? true : false;
      let expenseCodeValidationStatus = this.expenseCodeDropDownDetails.validation['isError'] || this.expenseCodeInputDetails.validation['isError'] ? true : false;
 
      this._journey.setAdditionalDetailsValidation(!(brrValidationStatus || expenseCodeValidationStatus) ? true : false);
      this._subscription.emitAddtionalDetailsValidationStatusObserver(!(brrValidationStatus || expenseCodeValidationStatus) ? true : false)
    })
  }

  
  fetchPrevData(){
    let prevBrr = this._journey.getBusinessRideReason();
    let prevExpenseCode = this._journey.getExpenseCode();
    let prevPickupNotes = this._journey.getPickupNotes();

    this.pickupNotesDetails.inputValue = prevPickupNotes;
    if(prevBrr != null && prevBrr != ''){
      if(this.businessRideReasonsFlags.enabled){
        if(this.businessRideReasonsFlags.dropdown){
          this.businessRideReasonDropDownDetails.options.forEach(brr=>{
            if(brr.value == prevBrr){
              this.businessRideReasonDropDownDetails.selectedValue = {label: prevBrr, value: prevBrr};
            }
            else{
              return ;
            }
          });
          
            if(this.businessRideReasonDropDownDetails.selectedValue.value == '' || this.businessRideReasonDropDownDetails.selectedValue.value == null){
              if(this.businessRideReasonsFlags.otherRequired){
                this.businessRideReasonDropDownDetails.selectedValue = {label: 'Other', value: 'OTHER'};
                this.businessRideReasonsFlags.textBox = true;
                this.businessRideReasonInputDetails.inputValue = prevBrr;
              }
              else{
                this.businessRideReasonsFlags.textBox = false;
                this.businessRideReasonDropDownDetails.validation = {
                  isSuccess:false,
                  isError: false,
                  message: 'Selected Business Ride Reason has been removed by Admin, Please add any other Busisness Ride Reason'
                }
              }
          }
        } 
        else{
          this.businessRideReasonsFlags.textBox = true;
          this.businessRideReasonInputDetails.inputValue = prevBrr;
        }
  }}

      
      if(prevExpenseCode != null && prevExpenseCode != ''){
        if(this.expenseCodeFlags.enabled){
          if(this.expenseCodeFlags.dropdown){
            this.expenseCodeDropDownDetails.options.forEach(expenseCode=>{
              if(expenseCode.value == prevExpenseCode){
                this.expenseCodeDropDownDetails.selectedValue = {label: prevExpenseCode, value: prevExpenseCode};
              }
              else{
                return ;
              }
            });
            
            
              if(this.expenseCodeDropDownDetails.selectedValue.value == '' || this.expenseCodeDropDownDetails.selectedValue.value == null){
        
                if(this.expenseCodeFlags.otherRequired){
                  this.expenseCodeDropDownDetails.selectedValue = {label: 'Other', value: 'OTHER'};
                  this.expenseCodeFlags.textBox = true;
                  this.expenseCodeInputDetails.inputValue = prevExpenseCode;
                }
                else{
                  this.expenseCodeFlags.textBox = false;
                  this.expenseCodeDropDownDetails.validation = {
                    isSuccess:false,
                    isError: false,
                    message: 'Selected Expense Code has been removed by Admin, Please add any other Expense Code.'
                  }
                }
            }
          } 
  
          else{
            this.expenseCodeFlags.textBox = true;
            this.expenseCodeInputDetails.inputValue = prevExpenseCode;
          }
        }}      

    
  }

  ngOnDestroy(): void {
    this.additionalDetailsValidationSubscription.unsubscribe();
  }

}
