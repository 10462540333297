import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RadioDetails } from '../../types/components.type';

@Component({
  selector: 'radio-btn',
  templateUrl: './radio-btn.component.html',
  styleUrls: ['./radio-btn.component.scss']
})
export class RadioBtnComponent {

@Input() radioDetails: RadioDetails;
@Output() onChangeEmitter = new EventEmitter();

  sendSelectedValue(){
    this.onChangeEmitter.emit(this.radioDetails.value);
  }
}
