<div class="main-container">
    <div class="location-container">
        <img [src]="locationType == 'PICK' ? 'assets/pick.svg' : 'assets/drop.svg'" alt="">
        <div class="location-input">
            <custom-input inputType="PLACES" [inputBoxDetails]="locationDetails" (onSendLocationData)="addLocation($event)"
                (onSendInputData)="checkIsInputClear($event)"></custom-input>
        </div>
    </div>

    <div class="rider-list-container">
        <div class="rider-caption-container">
            <img src="assets/rider.svg" alt="">
            <span>Rider :</span>
        </div>

        <div class="rider-details-container">
            <div class="rider-card-container" *ngFor="let rider of ridersList; index as i">

                <div class="left-container">
                    <div class="badge-container">
                        <badge [badgeType]="'ROUND'" [badgeColor]="rider.badgeColor" [badgeText]="rider.initial"></badge>
                    </div>
    
                    <div class="rider-details-container">
                        <span class="rider-name">{{rider.firstName}} {{rider.lastName}}</span>
                        <span class="rider-number">{{rider.callingCode}}-{{rider.phone}}</span>
                    </div>
                </div>
                

                <div class="right-container" *ngIf="showRiderActionCta">
                    <img src="assets/edit.svg" alt="" (click)="editCustomerDetails(i)">
                    <img src="assets/delete-icon.svg" alt="" (click)="deleteCustomerDetails(i)">
                </div>
                
            </div>
        </div>
        
    </div>

    <div class="add-rider-cta-container" *ngIf="showAddRiderCta">
        <div class="add-rider-cta">
            <custom-button [buttonDetails]="addRiderCta" (onCustomButtonClick) = toggleAddRiderDialog() [weight]="600"></custom-button>
        </div>
    </div>
</div>


<p-sidebar [(visible)]="toggleAddRiderSidebar" position="right">
    <shared-ride-customer-details-container *ngIf="toggleAddRiderSidebar" (closeDialogEvent)= "closeAddRiderDialog($event)" 
    [riderLocationIndex]="locationIndex" [TriggerType]="customerDetailsTriggerType" [CustomerRiderIndex]="selectedRiderIndex"></shared-ride-customer-details-container>
</p-sidebar>