import { Injectable } from '@angular/core';
import { customerDetails, journeyDetails, noError } from './create-ride-validation';

@Injectable({
  providedIn: 'root'
})
export class CreateRideValidationService {

  constructor() { }

  async validatePhoneNumber(value, callingCodeValue) {
    let limit = callingCodeValue.limit;
    let regex = /^\d+$/

    if (value == null || value == '') {
      return await customerDetails.phone.nullMessage
    }

    if (value.length != limit) {
      return await customerDetails.phone.checkLimit
    }

    if (callingCodeValue.value == null || callingCodeValue.value == '') {
      return await customerDetails.phone.callingCodeCheck
    }

    if (!regex.test(value)) {
      return await customerDetails.phone.numericText
    }

    else {
      return await noError;
    }

  }

  async validateName(value) {
    if (value == '' || value == null) {
      return await customerDetails.firstName.nullMessage
    }
    else {
      return await noError
    }
  }

  async validateEmail(value) {
    let regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (value == '' || value == null) {
      return await customerDetails.email.nullMessage
    }
    if (!regex.test(value)) {
      return await customerDetails.email.validEmail
    }
    else {
      return await noError
    }
  }

  async validatePickupLocation(value, lat, lng) {
    if (value == '' || value == null) {
      return await journeyDetails.pickup.nullMessage;
    }

    else if (lat == null || lat == 0 || lng == null || lng == 0) {
      return await journeyDetails.stop.useErrorMessage
    }

    else {
      return await noError
    }
  }

  async validateDropLocation(value, lat, lng) {
    if (value == '' || value == null) {
      return await journeyDetails.drop.nullMessage;
    }

    else if (lat == null || lat == 0 || lng == null || lng == 0) {
      return await journeyDetails.stop.useErrorMessage
    }

    else {
      return await noError
    }
  }

  async validateStopLocation(value, lat, lng) {

    if (value == '' || value == null) {
      return await journeyDetails.stop.nullMessage;
    }

    else if (lat == null || lat == 0 || lng == null || lng == 0) {
      return await journeyDetails.stop.useErrorMessage
    }

    else {
      return await noError
    }
  }

  async validateBusinessRideReason(value) {
    if (value == '' || value == null) {
      return await journeyDetails.businessRideReason.nullMessage;
    }
    else {
      return await noError
    }
  }

  async validateExpenseCode(value) {
    if (value == '' || value == null) {
      return await journeyDetails.ExpenseCode.nullMessage;
    }
    else {
      return await noError
    }
  }
  async validateLocation(zoneId, isAirport, rideType, isSameCity, locationDetails) {
    if (isSameCity && rideType?.value != 'SCHEDULED_RENTALS') {
      return await journeyDetails.LocationValidation.sameCity
    }

    if (locationDetails?.stops.length == 0 && rideType?.value == 'SCHEDULED_RIDE' && locationDetails?.pickupLat == locationDetails?.dropLat && locationDetails?.pickupLong == locationDetails?.dropLong) {
      return await journeyDetails.LocationValidation.sameLocation
    }

    if (zoneId == 4 && !isAirport && rideType?.value != 'SCHEDULED_RENTALS') {
      return await journeyDetails.LocationValidation.onlyAirport
    }

    else {
      return noError;
    }
  }
}
