<div class="main-container">
    <br/>
    <p class="heading">Please Select the Business Account you wish to login</p>
    <br/>

    <p-dropdown [options]="businessAccList" [(ngModel)]="selectedBusinessAccount" optionLabel="name" class="dropdownStyle" [filter]="true" filterBy="name" [showClear]="true" placeholder="Select Business Account"  (onChange)="handleChangeEvent()" appendTo="body" >
        <ng-template pTemplate="selectedItem">
            <div class="align-items-center gap-2" *ngIf="selectedBusinessAccount">
                <div>{{ selectedBusinessAccount.name }}</div>
            </div>
        </ng-template>
        <ng-template let-businessAccount pTemplate="item">
            <div class="align-items-center gap-2">
                <div>{{ businessAccount.name }}</div>
            </div>
        </ng-template>
    </p-dropdown>
    <br/>
    
    <button type="submit" name="button" class="btn login_btn" style="background: #2C66E3;height: 50px;box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);border-radius:4px ; width: 40%;" [disabled]="disableLoginButton" (click)="handleSubmitEvent()">Continue</button>
    
</div>