import { Component, Inject, Input, OnInit, SimpleChanges } from '@angular/core';
import { ModalComponentComponent } from 'src/app/app-v2/components/modals/modal-component/modal-component.component';


@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.css']
})
export class LoginModalComponent implements OnInit{

  @Input() businessAccountInput;

  businessAccList = [];
  selectedBusinessAccount: string | undefined;
  disableLoginButton=true;

  constructor(@Inject(ModalComponentComponent) private modal: ModalComponentComponent){
    
  }
  ngOnInit(): void {
    this.fomratListForDropDown(this.businessAccountInput)
  }

  ngOnChanges(changes: SimpleChanges):void {
   this.fomratListForDropDown(this.businessAccountInput)
  }

  handleChangeEvent(){
    console.log("value changed")
    if(this.selectedBusinessAccount == undefined || this.selectedBusinessAccount== null)
      this.disableLoginButton = true;
    else
      this.disableLoginButton = false;
  }

  handleSubmitEvent(){
   this.modal.closeModal(this.selectedBusinessAccount);
  }

  fomratListForDropDown(inputLst){
    let formattedResult = Object.entries(inputLst).map(([key, value]) => {
      if (!key) return null; // Null or undefined key check
      return { name: value, value: key };
    }).filter(item => item !== null); 

    this.businessAccList = formattedResult;
  }

  

  


}
