import { Component, ComponentRef, EventEmitter, OnDestroy, Output, Type, ViewChild } from '@angular/core';
import { DynamicContentDirective } from './dynamic-content-directive';

@Component({
  selector: 'app-modal-component',
  templateUrl: './modal-component.component.html',
  styleUrls: ['./modal-component.component.css']
})
export class ModalComponentComponent implements OnDestroy {

  @ViewChild(DynamicContentDirective, { static: false })
  dynamicContent!: DynamicContentDirective;

  @Output() modalData = new EventEmitter<any>(); 

  private componentRef?: ComponentRef<any>;
  visible= false;

  close() {
    this.destroyDynamicComponent();
    // Additional logic to hide the modal
  }

  loadContent<T>(component: Type<T>, data?: any) {
    this.visible = true;
    const viewContainerRef = this.dynamicContent.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(component);
    this.componentRef = componentRef;

    if (data) {
      Object.assign(componentRef.instance, data);
    }
  }

  private destroyDynamicComponent() {
    this.visible= false;
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = undefined;
    }
  }

  ngOnDestroy() {
    this.destroyDynamicComponent();
  }

  closeModal(data?: any) {
    this.modalData.emit(data); // Emit data when modal closes
    this.close();
  }

}


