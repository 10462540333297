import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/app-v2/getter-setter/common.service';
import { SharedRideContainerService } from 'src/app/app-v2/getter-setter/container/shared-ride/shared-ride-container.service';
import { SharedRideCustomerDetailsService } from 'src/app/app-v2/getter-setter/container/shared-ride/shared-ride-customer-details.service';
import { SharedRideJourneyDetailsService } from 'src/app/app-v2/getter-setter/container/shared-ride/shared-ride-journey-details.service';
import { CommonSubscriptionService } from 'src/app/app-v2/subscriptions/common/common-subscription.service';
import { CreateSharedRideSubscriptionService } from 'src/app/app-v2/subscriptions/create-ride/create-shared-ride-subscription.service';
import { CustomButton, AccordianDetails, TabDetails, CustomInputType, CustomDropDownType } from 'src/app/app-v2/types/components.type';
import { SharedRideLocationDetails } from 'src/app/app-v2/types/container.type';
import { ButtonTypes, SharedRideTypes, Theme } from 'src/app/app-v2/types/enums';

@Component({
  selector: 'shared-drop-container',
  templateUrl: './shared-drop-container.component.html',
  styleUrls: ['./shared-drop-container.component.scss']
})
export class SharedDropContainerComponent implements OnInit, OnChanges, OnDestroy{

  @Output() changeStep = new EventEmitter<boolean>();
  @Input() businessRideReasons = {};
  @Input() expenseCode = {};
  
  showAddLocationCtaSubscription : Subscription;
  closeDialogSubscription : Subscription;

  sharedRideType:string = SharedRideTypes.SHARED_DROP;
  showOtherInputBox = false;

  additionalDetailsAcc: AccordianDetails = {
    label: 'Additional Details',
    isActive: true,
    isComplete: false,
    isVisible: false,
    isDisabled: false,
    showDeleteIcon :  false
  }

  submitLocationCtaDetails: CustomButton ={
    label: 'Next',
    left: false,
    right: false,
    leftUrl: '',
    rightUrl: '',
    disabled: false,
    type: ButtonTypes.FILLED
  }

  addLocationCtaDetails: CustomButton ={
    label: ' + Add Location',
    left: false,
    right: false,
    leftUrl: '',
    rightUrl: '',
    disabled: true,
    type: ButtonTypes.OUTLINED
  }


  locationTabs:TabDetails[] = [{
    label: 'Pickup Location',
    value: "PICKUP",
    showIcon: false,
    showStep: true,
    url: "assets/complete-badge.svg",
    isSelected: true,
    isDisabled: false,
    
},

{
  label: 'Drop Location',
  value: "DROP",
  showIcon: false,
  showStep: true,
  url: "assets/complete-badge.svg",
  isSelected: false,
  isDisabled: false,
  
},
{
  label: 'Additional Details',
  value: "ADDITIONAL",
  showIcon: false,
  showStep: true,
  url: "assets/complete-badge.svg",
  isSelected: false,
  isDisabled: false,
  
}];

pickupLocationAccDetails: AccordianDetails = {
  label: 'Pickup Location',
  isActive: true,
  isComplete: false,
  isVisible: true,
  isDisabled: false,
  showDeleteIcon: false
} ;

businessRideReasonInputDetails: CustomInputType = {
  placeholder : 'For eg. Late night cab, administrative commute etc',
  type: 'text',
  inputValue: null,
  label: 'Manually add reason',
  name: 'businessRideReason',
  validation: {
    isError: false,
    isSuccess: false,
    message: ''
  },
  isDisabled: false,
  required: false,
  enableTooltip: true,
  tooltipContent: 'Add purpose of your ride as specified by your organisation'
}

expenseCodeInputDetails: CustomInputType = {
  placeholder : 'For eg. Late night cab, administrative commute etc',
  type: 'text',
  inputValue: null,
  label: 'Manually add Expense Code',
  name: 'expenseCode',
  validation: {
    isError: false,
    isSuccess: false,
    message: ''
  },
  isDisabled: false,
  required: false,
  enableTooltip: true,
  tooltipContent: 'Please add the code given by your organization'
}

businessRideReasonDropDownDetails : CustomDropDownType = {
  selectedValue: {label: '', value: ''},
  validation: {
    isError: false,
    isSuccess: false,
    message: ''
  },
  options: [],
  label: 'Business Ride Reason',
  name: 'businessRideReason',
  placeholder : 'Select Reason',
  isDisabled: false,
  required: false,
  enableTooltip: true,
  tooltipContent: 'Add purpose of your ride as specified by your organisation'
}

expenseCodeDropDownDetails : CustomDropDownType = {
  selectedValue: {label: '', value: ''},
  validation: {
    isError: false,
    isSuccess: false,
    message: ''
  },
  options: [],
  label: 'Expense Code',
  name: 'expenseCode',
  placeholder : 'Select Code',
  isDisabled: false,
  required: false,
  enableTooltip: true,
  tooltipContent: 'Please add the code given by your organization'
}


  riderMaxCapacity : number = 0;
  selectedTab: string = 'PICKUP'
  pickupLocationMaxCapacity: number = 0;
  dropLocationMaxCapacity: number = 0;
  showAddLocationCta:boolean = false;
  locationAccCountArr: string[] = [];
  locationAccDetails: AccordianDetails[] = [];
  locationDetailsArr = [];
  riderDetailsArr = [];
  locationDto:SharedRideLocationDetails;
  
  deleteIndex = 0;
  

  constructor(private _common: CommonService,
              private _sharedRide: SharedRideContainerService,
              private _sharedRideJourney: SharedRideJourneyDetailsService,
              private _sharedRideCustomer: SharedRideCustomerDetailsService,
              private _commonSubscription: CommonSubscriptionService,
              private _sharedRideSubscriptionService: CreateSharedRideSubscriptionService){}

  ngOnChanges(changes: SimpleChanges): void {
   this.setAdditionalDetails();
  }

  ngOnInit(): void {
    this.startCloseDialogSubscription();
    this._sharedRideCustomer.createRiderBadgeHashMap()
    this.startShowAddLocationSubscription();
    this.pickupLocationMaxCapacity = this._sharedRideJourney.getSelectedSharedRideTypeDetails(this.sharedRideType).maxPickLocation;
    this.dropLocationMaxCapacity = this._sharedRideJourney.getSelectedSharedRideTypeDetails(this.sharedRideType).maxDropLocation;
    this.riderMaxCapacity = this._common.getMaximumSharedRideSeats();
    this.fetchPrevDetails();
  }


  setAdditionalDetails(){
    let prevBrr = this._sharedRideJourney.getBusinessRideReason();

    if(this.businessRideReasons != undefined){
      this.showOtherInputBox = this.businessRideReasons['type'] == 'TEXT';
      this.businessRideReasonInputDetails.label = this.businessRideReasons['type'] == 'TEXT' ? 'Business Ride Reason' : 'Manually add Reason';
      this.businessRideReasonInputDetails.required=this.businessRideReasons['mandatory'];
      this.businessRideReasonDropDownDetails.required=this.businessRideReasons['mandatory']; 
      let dropDownOptions = this.businessRideReasons['itemList'].map(item=>{return {label:item, value:item}});
      this.businessRideReasonDropDownDetails.options = dropDownOptions;
      if(this.businessRideReasons['otherRequired']){
        this.businessRideReasonDropDownDetails.options.push({label: 'Other', value: 'Other'})
      }
    }

    if(this.expenseCode != undefined){
      this.expenseCodeInputDetails.label = this.expenseCode['type'] == 'TEXT' ? 'Expense Code' : 'Manually add Expense Code';
      this.expenseCodeDropDownDetails.required = this.expenseCode['mandatory'];
      this.expenseCodeInputDetails.required = this.expenseCode['mandatory'];
      this.expenseCodeDropDownDetails.options = this.expenseCode && this.expenseCode['type'] == 'LIST' ? this.expenseCode['itemList'].map(item=>{return {label:item, value:item}}) : [];
    }

    if(prevBrr){
      if(this.businessRideReasons && this.businessRideReasons['type'] == 'TEXT'){
        this.businessRideReasonInputDetails.inputValue = prevBrr;
      }

      else{
        if(this.businessRideReasons && this.businessRideReasons['itemList'].includes(prevBrr)){
          this.businessRideReasonDropDownDetails.selectedValue = {label : prevBrr, value: prevBrr};
        }
        else{
          this.businessRideReasonDropDownDetails.selectedValue = {label : 'Other', value: 'Other'};
          this.businessRideReasonInputDetails.inputValue = prevBrr;
        }
      }
    }
  }

  onDropdownValueChange(type){
    this.showOtherInputBox = this.businessRideReasonDropDownDetails.selectedValue.value == 'Other';
    this._sharedRideJourney.setBusinessRideReason(this.businessRideReasonDropDownDetails.selectedValue.value == 'Other' ? '' : this.businessRideReasonDropDownDetails.selectedValue.value);
  }

  onBRRInput(){
    this._sharedRideJourney.setBusinessRideReason(this.businessRideReasonInputDetails.inputValue);
  }


  deleteRider(riderIndex, locationIndex){
    this.riderDetailsArr[locationIndex].splice(riderIndex, 1);
    this.validateData();
  }



  validateData(){
    
    let validationArr = [];

    let isValidLocation = (location)=> location && location.lat !== 0 && location.lng !== 0 && location.locationName != '';
    let isValidRiders = (ridersList)=>ridersList && ridersList != null && ridersList.length > 0 ;

    if(this.selectedTab == 'DROP'){
      for(let i = 0; i < this.locationAccCountArr.length ; i++){
        validationArr[i] = null;
        validationArr[i] = isValidLocation(this.locationDetailsArr[i]) ? isValidRiders(this.riderDetailsArr[i] ? this.riderDetailsArr[i] : null) : false;
      }

      this.addLocationCtaDetails.disabled = validationArr.includes(false) || validationArr.includes(null) ? true : false;
      if(validationArr.includes(false) || validationArr.includes(null)){
        this.submitLocationCtaDetails.disabled = true
        this.locationTabs.forEach((tab,index)=>{
          tab.isDisabled = index == 0 ? false : true;
        })
      }
      else{
        this.checkForMinimumRequirementsToCreateRide();
      } 
    }

    

    else{
      this.isLocationCtaVisible();
      validationArr.push(isValidLocation(this.locationDto));
      
      if(validationArr.includes(false) || validationArr.includes(null)){
        this.submitLocationCtaDetails.disabled = true
        this.locationTabs.forEach((tab,index)=>{
          tab.isDisabled = index == 0 ? false : true;
        })
      }
      else{
        this.submitLocationCtaDetails.disabled = false
        this.locationTabs.forEach(tab=>{
          tab.isDisabled = false;
        })
        this.checkForMinimumRequirementsToCreateRide();
      } 
    }

    return !validationArr.includes(false);
  }

  checkForMinimumRequirementsToCreateRide(){

    let locationValidation = (location)=>location && location.locationName != '' && location.lat != 0 && location.lng != 0;
    if(this.selectedTab == 'DROP'){
      if(this._sharedRideCustomer.getRiderListArrLength() < this._common.getMinimumSharedRideSeats()){
        this.submitLocationCtaDetails.disabled = true;
        return false;
      }
  
      else{
        this.submitLocationCtaDetails.disabled = false;
        return true;
      }
    }
    else{
      this.submitLocationCtaDetails.disabled = !locationValidation(this.locationDto);
      this.locationTabs[1].isDisabled = !locationValidation(this.locationDto);
      this.locationTabs[2].isDisabled = !locationValidation(this.locationDto);

      return locationValidation(this.locationDto);
    }
    
  }

  setActiveAcc(event, index){
    this.locationAccDetails.forEach(acc=>acc.isActive = false);
    this.locationAccDetails[index].isActive  = !this.locationAccDetails[index].isActive;
  }


  addLocation(){
    if(this.locationAccCountArr.length < this.dropLocationMaxCapacity){
      this.locationAccDetails.forEach(acc=>acc.isActive = false);
      this.locationAccCountArr.push('location');
      this.locationAccDetails.push({
        label: `Drop Location ${this.locationAccDetails.length+1}`,
        isActive: true,
        isComplete: false,
        isVisible: true,
        isDisabled: false,
        showDeleteIcon: true
      })
    }

    else{
      this.isLocationCtaVisible();
      return ;
    }
    this.showDeleteIcon();
    this.validateData();

  }

  setSelectedTab(tabIndex){
      this.locationTabs.forEach((tab,index)=>{
        if(index < tabIndex){
          tab.showStep = false;
          tab.showIcon = true;
        }
        else{
          tab.showStep = true;
          tab.showIcon = false;
        }
      })
      this.selectedTab = this.locationTabs[tabIndex].value;
      this.isLocationCtaVisible();
  }

  isLocationCtaVisible(){
    if(this._sharedRideCustomer.isRiderListFilled() || this.selectedTab == 'PICKUP'){
      this.showAddLocationCta = false;
    }
    else{
      this.showAddLocationCta = true;
    }
  }

  changeActiveStep(){
    this._sharedRide.setStopsList();
    let minRiderCapactiy = this._common.getMinimumSharedRideSeats();
    
    
      if(this.selectedTab == 'PICKUP'){
        this.setSelectedTab(1);
        this.validateData();
        this._sharedRideJourney.setSharedRideLocationDto(this.locationDto)
        this._common.scrollTop(30);
      }
  
      else if(this.selectedTab == 'DROP'){
        this.setSelectedTab(2);
        this.validateData();
          this._sharedRideJourney.setSharedRideLocationDetails(this.locationDetailsArr);
          this._common.scrollTop(30);
      }
    
      else if(this.selectedTab == 'ADDITIONAL'){
        if(this.businessRideReasons['mandatory'] && this._sharedRideJourney.getBusinessRideReason() == ''){
          this._commonSubscription.emitAlert({
            theme: Theme.WARNING,
            title: 'Something Missing !',
            message: 'Please Add Business Ride Reason',
            ctaLabel: 'Ok'
          })
    
          return;
        }
        else{
          this.validateData();
          this.changeStep.emit(true);
          this._common.scrollTop(30);
        }
      }
      
      
  }

  startShowAddLocationSubscription(){
    this.showAddLocationCtaSubscription = this._sharedRideSubscriptionService.fetchShowAddLocationCtaObserver().subscribe(res=>{
      this.isLocationCtaVisible();
    })
  }

  startCloseDialogSubscription(){
    this.closeDialogSubscription = this._commonSubscription.fetchCloseAlert().subscribe(res=>{
      
      if(res[1] == Theme.CUSTOM){

        if(this.locationAccCountArr.length > 1){
          this.locationDetailsArr.splice(this.deleteIndex, 1);
          this.locationAccCountArr.splice(this.deleteIndex, 1);
          this.locationAccDetails.splice(this.deleteIndex, 1);

          console.log(this.locationAccCountArr, this.locationDetailsArr, this.locationAccDetails);
          this._sharedRideJourney.removeSharedRideLocation(this.deleteIndex);
    
          this.locationAccDetails.forEach((accDetails,index)=>{
            accDetails.label = `Drop Location ${index + 1}`
          })
          this.isLocationCtaVisible();
        }
        else{
          return;
        }
        this.showDeleteIcon();
        this.validateData()
      }
        
      else{
        return;
      }
      
    })
  }

  showDeleteIcon(){
    let locationLength = this.locationAccDetails.length;
    if(locationLength == 1){
      this.locationAccDetails.forEach(location=>location.showDeleteIcon = false);
    }

    else{
      this.locationAccDetails.forEach(location=>location.showDeleteIcon = true);
    }

  }


  throwAlert(index){
      this._commonSubscription.emitAlert({
        theme: Theme.CUSTOM,
        title: '',
        message: '',
        ctaLabel: 'Yes',
        cancelCta: true,
        cancelCtaLabel : 'No',
        template: `
        <div>
          <div style='margin-bottom:24px'>
          <img src='assets/delete-icon-popup.svg'>
          </div>

          <div style ='padding-left: 6px'>
          <p style='margin: 0; color: #555555; font-weight: 600; font-size: 18px;'>Are you sure that you want to delete this pickup ?</p>
          <p style='color: #959595; font-size: 16px; font-weight: 400; width:95%'>Deleting this pickup will lead to unassigned riders mapped with this pickup location</p>
          </div>

          <div style='background-color: #F1F6FA; padding: 8px; border-radius: 12px; font-weight: 500; display:${this.locationDetailsArr[index] && (this.locationDetailsArr[index]['locationName'] != null || this.locationDetailsArr[index]['locationName'] != '') ? 'flex' : 'none'}; gap:8px; align-items:flex-start;'>
            <img src='assets/pick.svg' style='margin-top: 4px;'>
            <span>${this.locationDetailsArr[index] ? this.locationDetailsArr[index]['locationName'] : ''}</span>
          </div>
        </div>
        `
      })
  }

  deleteLocation(event, index){
    console.log(index);
    this.deleteIndex = index;
    this.throwAlert(index);
  }


  fetchPrevDetails(){
    this.locationAccCountArr = [];
    this.locationAccDetails = [];
    this.riderDetailsArr = [];
    
    let stopsList = this._sharedRide.getStopsList();
    if( stopsList && Object.keys(stopsList).length > 0){
      this.fetchPrevStopsList(stopsList).then(res=>{
        this.validateData();
        this.isLocationCtaVisible();
      })
    }

    else{
      this.addLocation();
    }

    if(this._sharedRide.getRequestSharedRideDto().locationDto){
      this.locationDto = this._sharedRide.getRequestSharedRideDto().locationDto;
    }
  }


  fetchPrevStopsList(stopsList){
    console.log(stopsList);
    let x  = new Promise((res, rej)=>{
      for(let i = 0; i < Object.keys(stopsList).length; i ++ ){
        this.riderDetailsArr[i] = [...this.riderDetailsArr, ...stopsList[i]['ridersList']];
        this.locationDetailsArr.push(stopsList[i]['locationDetails'])
        this.locationAccDetails ? this.locationAccDetails.forEach(acc=>acc.isActive = false) :  null;
        this.locationAccCountArr.push('location');
        this.locationAccDetails.push({
          label: `Drop Location ${this.locationAccDetails.length+1}`,
          isActive: true,
          isComplete: false,
          isVisible: true,
          isDisabled: false,
          showDeleteIcon: true
        });
        this.isLocationCtaVisible();
      }
       res(this.locationAccCountArr);
    })

    return x;
  }



  getLocation(locationData, locationIndex){
    if(locationIndex == null){
      this.locationDto = locationData;   
      this.validateData();
     }
    else{
      this.locationDetailsArr[locationIndex] = locationData;   
      this.validateData();   
    }
  }


  getRiderDetails(riders, locationIndex){
    if(locationIndex != null){
      this.riderDetailsArr[locationIndex] = riders;
      this.validateData();
    }
}



  ngOnDestroy(): void {
    this.showAddLocationCtaSubscription.unsubscribe();
    this.closeDialogSubscription.unsubscribe();
  }
}
