<div class="main-container">

    <div class="tab-container">
        <tab [tabDetails]="locationTabs" align="HORIZONTAL" (onTabSelected)="setSelectedTab($event)" [selectedTab]="selectedTab"></tab>
    </div>

    <div class="location-acc-container">

        <!-- PICKUP LOCATIONS -->
        <div *ngIf="selectedTab == 'PICKUP'" class="location-acc-container" (click)="isLocationCtaVisible()">
            <div class="acc-container" *ngFor="let location of locationAccCountArr; index as i">
                
                <accordian [accordianDetails]="locationAccDetails[i]" [accordianType]="'EDITABLE'" (deleteAcc)="deleteLocation($event, i)" (isActiveEvent)="setActiveAcc($event, i)" [highlight]="true">
                    <div class="add-location-container" editAccBody *ngIf="locationAccDetails[i]?.isActive">
                        <shared-ride-add-location-container [enableAddRiderCta]="true" [locationIndex]="i" (locationData)="getLocation($event, i)"
                        locationType="PICK" [showRiderActionCta]="true" (riderDetails)="getRiderDetails($event, i)" (deleteRider)="deleteRider($event, i)"></shared-ride-add-location-container>
                    </div>
                </accordian>
            </div>
        </div>

        <!-- DROP LOCATIONS -->
        <div *ngIf="selectedTab == 'DROP'" class="location-acc-container" (click)="isLocationCtaVisible()">
            <div class="acc-container">
                <accordian [accordianDetails]="dropLocationAccDetails" [accordianType]="'EDITABLE'" [highlight]="true">
                    <div class="add-location-container" editAccBody>
                        <shared-ride-add-location-container [enableAddRiderCta]="false" [locationIndex]="null" (locationData)="getLocation($event, null)"
                        locationType="DROP" [showRiderActionCta]="false" ></shared-ride-add-location-container>
                    </div>
                </accordian>
            </div>
        </div>


         <!-- Additional Details  -->
         <div *ngIf="selectedTab == 'ADDITIONAL'" class="location-acc-container" (click)="isLocationCtaVisible()">
         <accordian [accordianType]="'EDITABLE'" [accordianDetails]="additionalDetailsAcc" [highlight]="false">
            <div class="additional-details-container" editAccBody>
    
                    
                    <!-- Business Ride Reason -->
                    <div class="business-ride-reason-container">
                        <div class="image-container" *ngIf="businessRideReasons?.['enabled']">
                            <img src="assets/brrAndExpense.svg" alt="">
                        </div>
                
                        <div class="input-container">
                    <dropdown [dropdownDetails]="businessRideReasonDropDownDetails" *ngIf="businessRideReasons?.['type'] == 'LIST'" (changehandler)="onDropdownValueChange('REASON')"></dropdown>
                    <custom-input inputType="BASIC" [inputBoxDetails]="businessRideReasonInputDetails" *ngIf="showOtherInputBox" (onSendInputData)="onBRRInput()"></custom-input>
                        </div>
                    </div>
            
            
                    <!-- Expense Code -->
                    <!-- <div class="expense-code-container" *ngIf="expenseCode?.['enabled']">
            
                        <div class="image-container" *ngIf="expenseCode?.enabled">
                            <img src="assets/expense_code.svg" alt="">
                        </div>
                
                        <div class="input-container">
                    <dropdown [dropdownDetails]="expenseCodeDropDownDetails" *ngIf="expenseCode?.type == 'LIST'" (changehandler)="onDropdownValueChange('CODE')"></dropdown>
                    <custom-input inputType="BASIC" [inputBoxDetails]="expenseCodeInputDetails" *ngIf="expenseCode?.type != 'LIST'"></custom-input>
                    </div>
                    </div> -->
                
            </div>
        </accordian>
         </div>
    


            <!-- Submit Cta -->
        <div class="location-cta-container">
            <div class="location-cta">
                <custom-button [buttonDetails]="submitLocationCtaDetails" (onCustomButtonClick)="changeActiveStep()"></custom-button>
            </div>
            
            <div class="location-cta" *ngIf="showAddLocationCta">
                <custom-button [buttonDetails]="addLocationCtaDetails" (onCustomButtonClick)="addLocation()"></custom-button>
            </div>
            
        </div>

    </div>
</div>