import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_URL_CONFIRM_SHARED_LIVE_RIDE, API_URL_CREATE_LIVE_RIDE, API_URL_CREATE_SHARED_LIVE_RIDE, API_URL_GET_COUNTRY_CODES, API_URL_RIDE_METADATA, API_URL_RIDER_DETAILS } from 'src/app/config/apiRouteConfig';
import { RequestRideApiResponse, SharedRideRequestApiDto } from '../../types/apis.type';


@Injectable({
  providedIn: 'root'
})
export class CreateRideApiService {

  constructor(private _http: HttpClient) { }

  getCallingCodeListFromApi(): Observable<any>{
    return this._http.get<any>(API_URL_GET_COUNTRY_CODES);
  };

  requestRide(rideRequestDto): Observable<RequestRideApiResponse>{
    return this._http.post<RequestRideApiResponse>(API_URL_CREATE_LIVE_RIDE, rideRequestDto);
  }

  requestSharedRide(sharedRideRequestDto) : Observable<SharedRideRequestApiDto>{
    return this._http.post<any>(API_URL_CREATE_SHARED_LIVE_RIDE, sharedRideRequestDto);
  }

  sharedRideAction(sharedRideId, action){
    return this._http.post(`${API_URL_CONFIRM_SHARED_LIVE_RIDE}?id=${sharedRideId}`, action);
   }

   fetchRideMetadata(rideMetadataRequestDto){
    return this._http.post(`${API_URL_RIDE_METADATA}`,rideMetadataRequestDto)
   }
}
