import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/app-v2/getter-setter/common.service';
import { JourneyDetailsContainerService } from 'src/app/app-v2/getter-setter/container/create-ride/journey-details-container.service';
import { SelectRideTypeContainerService } from 'src/app/app-v2/getter-setter/select-ride-type-container.service';
import { CommonSubscriptionService } from 'src/app/app-v2/subscriptions/common/common-subscription.service';
import { CreateRideSubscriptionService } from 'src/app/app-v2/subscriptions/create-ride/create-ride-subscription.service';
import { CustomButton } from 'src/app/app-v2/types/components.type';
import { ButtonTypes, Theme } from 'src/app/app-v2/types/enums';
import { CreateRideValidationService } from 'src/app/app-v2/validations/createRide/create-ride-validation.service';


@Component({
  selector: 'location-container',
  templateUrl: './location-container.component.html',
  styleUrls: ['./location-container.component.scss']
})
export class LocationContainerComponent implements OnInit, OnDestroy {
  mapCoordinatesSubscription: Subscription;
  addLocationValidationSubscription: Subscription;
  additionalDetailsValidationSubscription: Subscription;

  constructor(private _rideType: SelectRideTypeContainerService,
    private _subscription: CreateRideSubscriptionService,
    private _common: CommonService,
    private _commonSubscription: CommonSubscriptionService,
    private _createRideValidation: CreateRideValidationService,
    private _journey: JourneyDetailsContainerService) { }

  ngOnInit(): void {
    this.startMapCoordinatesSubscription();
    this.startAddLocationValidationStatusObserver();
    this.startAdditionalDetailsValidationStatusObserver();
    this._journey.setSelectedCarVariantCategory('');
  }

  isDropRequired = this._rideType.getSelectedRideTypeDetails().isDropRequired;
  locationContainerCta: CustomButton = {
    label: 'Next',
    left: false,
    right: false,
    leftUrl: "",
    rightUrl: "",
    disabled: false,
    type: ButtonTypes.FILLED
  }

  isShowMapMarkers = {
    pick: false,
    drop: false,
    stops: [],
  }

  agmCoordinates = {
    pick: this._common.getDefaultAgmCoordinates(),
    stops: [],
    destination: this._common.getDefaultAgmCoordinates(),
  }



  validationStatus = { addLocation: false, additionalDetails: false };


  submitLocationDetails() {
    const isAirportRide = this._rideType.getIsAirportRide();
    const pickUpZoneId = this._journey.getPickupLocationZoneId();
    const dropZoneId = this._journey.getDropLocationZoneId();
    const selectedRideType = this._rideType.getSelectedRideTypeDetails()
    let validation = this._createRideValidation.validateLocation(pickUpZoneId, isAirportRide, selectedRideType, !this._journey.compareZoneId(pickUpZoneId, dropZoneId, this._journey.addLocationExtraFields.STOPS), this._journey.journeyDetails)
    validation.then(res => {
      if (res['isError']) {
        this._commonSubscription.emitAlert({
          theme: Theme.WARNING,
          title: 'Invalid Location Selection',
          message: res['message'],
          ctaLabel: 'OK'
        });
      } else {
        this._subscription.emitAddLocationValidationObserver();
        this._subscription.emitAdditionalDetailsValidationObserver();
      }
    })
  }

  startMapCoordinatesSubscription() {
    this.mapCoordinatesSubscription = this._subscription.fetchMapCoordinatesObserver().subscribe(res => {
      this.getAgmCoordinates(res);
    })
  }


  getAgmCoordinates(event) {


    switch (event.type) {
      case 'PICKUP':
        this.agmCoordinates = {
          pick: {
            lat: event.coordinates.lat == null ? this.agmCoordinates.pick.lat : event.coordinates.lat,
            lng: event.coordinates.lng == null ? this.agmCoordinates.pick.lng : event.coordinates.lng
          },
          destination: this.agmCoordinates.destination,
          stops: this.agmCoordinates.stops
        }


        this.isShowMapMarkers = {
          pick: event.coordinates.lat == null || event.coordinates.lat == 0 ? false : true,
          drop: this.isShowMapMarkers.drop,
          stops: this.isShowMapMarkers.stops,
        }

        return

      case 'DROP':
        this.agmCoordinates = {
          pick: this.agmCoordinates.pick,
          destination: {
            lat: event.coordinates.lat == null ? this.agmCoordinates.destination.lat : event.coordinates.lat,
            lng: event.coordinates.lng == null ? this.agmCoordinates.destination.lng : event.coordinates.lng
          },
          stops: this.agmCoordinates.stops
        }

        this.isShowMapMarkers = {
          pick: this.isShowMapMarkers.pick,
          drop: event.coordinates.lat == null || event.coordinates.lat == 0 ? false : true,
          stops: this.isShowMapMarkers.stops,
        }
        return

      case 'STOPS':
        this.isShowMapMarkers.stops = [];
        this.agmCoordinates = {
          pick: this.agmCoordinates.pick,
          destination: this.agmCoordinates.destination,
          stops: event.coordinates.map((coordinate, index) => {

            this.isShowMapMarkers = {
              pick: this.isShowMapMarkers.pick,
              drop: this.isShowMapMarkers.drop,
              stops: coordinate.inputValue.lat == null || coordinate.inputValue.lat == 0 ? [...this.isShowMapMarkers.stops, false] : [...this.isShowMapMarkers.stops, true],
            }

            return ({
              location: {
                lat: coordinate.inputValue.lat == null ? this.agmCoordinates.stops[index].lat : coordinate.inputValue.lat,
                lng: coordinate.inputValue.lng == null ? this.agmCoordinates.stops[index].lng : coordinate.inputValue.lng
              },
              stopover: false
            })
          }
          )
        }
        return
    }
  }

  startAddLocationValidationStatusObserver() {
    this.addLocationValidationSubscription = this._subscription.fetchAddLocationValidationStatusObserver().subscribe(res => {
      this.validationStatus.addLocation = false;
      this.validationStatus.addLocation = res;
      this.triggerLocationCta();
    })
  }

  startAdditionalDetailsValidationStatusObserver() {
    this.additionalDetailsValidationSubscription = this._subscription.fetchAdditionalDetailsValidationStatusObserver().subscribe(res => {
      this.validationStatus.additionalDetails = false;
      this.validationStatus.additionalDetails = res;
      this.triggerLocationCta();
    })
  }

  triggerLocationCta() {
    let status = Object.keys(this.validationStatus).map((field) => {
      return this.validationStatus[field]
    });

    if (status.includes(false)) {
      return;
    }
    else {
      this._subscription.emitTriggerLocationCtaObserver(true);
    }
  }

  ngOnDestroy(): void {
    this.addLocationValidationSubscription.unsubscribe();
    this.additionalDetailsValidationSubscription.unsubscribe();
    this.mapCoordinatesSubscription.unsubscribe();
  }

}
