import { AppService } from './../../services/app.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { COOKIE_URL,COOKIE_SUFFIX } from 'src/app/config/apiRouteConfig';
import { BusinessDetailsService } from 'src/app/services/business-details/business-details.service';
import { AESServiceService } from 'src/app/services/AES/aesservice.service';
import { OctaService } from 'src/app/services/octa/octa.service';
import { environment } from 'src/environments/environment';
import { LoginModalComponent } from '../container/login-modal/login-modal/login-modal.component';
import { ModalComponentComponent } from 'src/app/app-v2/components/modals/modal-component/modal-component.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  

  constructor(private _cookieService: CookieService,private _appService: AppService,private router:Router, private businessDetailsService:BusinessDetailsService, private aesService:AESServiceService, private octaService:OctaService) {
  }
  @ViewChild('modal') modal!: ModalComponentComponent;
  dropdownOptions={}
  loginError:string = null;
  keys = [];
  newWindow:Window;
  localStorageDto = {
    accessToken: '',
    businessAccountId: null,
  }
  disableLoginButton= false;
  
  ngOnInit() {
    const token =localStorage.getItem('accessToken');
    if(token){
       this.router.navigateByUrl('/driver');
    }else{
      this._appService.isValid=false;
    }
   }


  login(data:NgForm){

    let reqData={};
    if(data.value['password']){
        reqData['username'] = data.value['username'];
        reqData['password'] =  this.aesService.encrypt(data.value['password']);
    }
    this._appService.setCredentials(reqData)
    this.disableLoginButton = true;
    this._appService.loginV2(reqData).subscribe(responseServer=>{
      this.disableLoginButton=false;
        
         if(responseServer.statusCode !== 200){
          this.loginError = responseServer.message;
          this._appService.setLoginAttempts(this.loginError)
          responseServer.message == "Unsuccessful attempts reached, please reset your password"? this.router.navigateByUrl('/resetpwd?accntLocked=true') : this.router.navigate(['/login']) 
         }

         else{
          const { response: cookieData } = responseServer;
          if(cookieData['businessAccountMap']){
            this.keys = Object.keys(cookieData.businessAccountMap);
          }
         else{
           this.keys = [];
         }
          this.dropdownOptions = cookieData.businessAccountMap;
          this._appService.getCountry()== 'AE' ? localStorage.setItem('zoneIds', [3].toString()) : localStorage.setItem('zoneIds', [1,2,4].toString())
          this.localStorageDto.accessToken = cookieData.access_token;
          
          // if multiple business accounts are mapped
          if(this.keys.length>1 && !cookieData.isMfaEnabled){
            // Swal.fire({
            //   icon: 'info',
            //   title: '',
            //   text: "Please Select the Business Account you wish to login",
            //   input: 'select',
            //   inputOptions: this.dropdownOptions,
            //   confirmButtonColor: "#2C66E3",
            //   cancelButtonColor: "#fff",
            //   confirmButtonText: "Confirm",
            //   cancelButtonText: "No"
            // }).then(result=>{
            //     this.localStorageDto.businessAccountId = result.value;                
            //     this._appService.setValuesInLocalStorage(this.localStorageDto)
            // })
            this.modal.loadContent(LoginModalComponent,{ businessAccountInput: this.dropdownOptions });
          
          }
           // if only single business account mapped 
          else {
              if(cookieData.isMfaEnabled){
                 this.router.navigateByUrl('/mfa')
                 this._appService.setAccountDetails(cookieData['userContactDetails'])
                 }
              else{
                 this.localStorageDto.businessAccountId = cookieData['businessAccountIds'][0];                
                 this._appService.setValuesInLocalStorage(this.localStorageDto)
                 }}}},
      (err)=>{
        //console.log(err);
        this.disableLoginButton=false;
      }
    )
  }

  loginSso(){
    this.router.navigate(["/sso"])
  }

  changePassword(){
    this.router.navigateByUrl('/resetpwd?accntLocked=false')
  }

  handleModalData(data: any) {
    this.localStorageDto.businessAccountId = data.value;                
    this._appService.setValuesInLocalStorage(this.localStorageDto)
    // Handle the data in the parent component
  }
}
